/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import {
  Badge,
  Button,
  Col,
  Form,
  Space,
  Row,
  Spin,
  Tabs,
  Tooltip,
  notification
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  //EditOutlined,
  PictureOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// import Dashboard from '../../assets/icons/dashboard';
// import Info from '../../assets/icons/info';
// import Heading from '../../components/common/Heading';
//import Spacer from '../../components/common/Spacer';
import { CardTabs, HoverBgButton } from '../../components/common/styledComponents';
// import ConfirmationModal from '../../components/ConfirmationModal';
//import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import Loading from '../../components/common/Loading';
// import LoadingIcon from '../../components/common/LoadingIcon';
import ScreenMask from '../../components/common/ScreenMask';
// import message from '../../components/common/message';
import {
  LOADING_ICON_SIZE1,
  TAB_ALIAS,
  TAB_BASIC_INFO,
  TAB_COPYWRITING,
  TAB_IMAGES,
  TAB_ATTRIBUTES,
  TAB_INVENTORY,
  TAB_CHANNEL_INV,
  TAB_OPERATION,
  TAB_VARIATION,
  TAB_BUNDLED,
  TAB_QTY_OVERWRITE,
  TAB_DROPSHIP,
  DEFAULT_ERR_MSG_DISPLAY_DURATION
} from '../../constants/config';
// import { Permissions } from '../../constants/enums/permissions';
import Products, { fetchProductWithChildInventoryAts } from '../../services/products';
// eslint-disable-next-line
import { getProfileSettingValue, isDevEnv, randomString } from '../../util';
import {
  Actions,
  Dispatcher,
  initialState,
  ProductDetailScreenContext,
  ProductDetailState,
  reducer,
  saveChannelInv,
  saveProduct,
} from './context';
import {
  DetailContainer,
  // TopHeaderButtonsWrapper,
  // TitleWrapper,
  // TitlePlaceholderWrapper,
  // TitleInput,
} from './styledComponents';
import Attributes from './Tabs/Attributes';
import Dropship from './Tabs/Dropship';
//import Attributes from './Tabs/Attributes2';
//import FormButtons from '../../components/common/FormButtons';
import notificationActions from '../../redux/actions/notifications';
// import Extended from './Tabs/Extended';
import StyleMasterScreen from './Tabs/StyleMaster';
import Alias from './Tabs/Alias';
import BasicInfo from './Tabs/BasicInfo';
import Copywriting from './Tabs/Copywriting';
import Operation from './Tabs/Operation';
import ProductBundles from './Tabs/Bundle';
import ProductImages from './Tabs/Images';
import Inventory from './Tabs/Inventory';
// import FormButtons from './FormButtons';
import ChannelInv from './Tabs/ChannelInv';
//import ActionDropdownMenu from './ActionDropdownMenu';
import ImagePanel from './ImagePanel';
import PreviewImages from './PreviewImages';
import OverwriteOptionList from './OverwriteOptionList';
//import OverwriteSwitch from './OverwriteSwitch';
import QtyOverwrite from './Tabs/QtyOverwrite/index';
import { getProfilesState } from '../../redux/utils';
import { fetchProfileSettings } from '../../services/channels';


let profileSettings: StringKAnyVPair[] = [];

const { TabPane } = Tabs;

const formTabsStructure: any = {
  'Basic': [
    'ASIN',
    'BoxHeight',
    'BoxLength',
    'BoxWidth',
    'Brand',
    'ClassificationNum',
    'Condition',
    'DimensionUnit',
    'EAN',
    'FNSku',
    'GrossWeight',
    'HarmonizedCode',
    'ISBN',
    'LongDescription',
    'MPN',
    'Manufacturer',
    'MultipackQuantity',
    'NetWeight',
    'ProductHeight',
    'ProductLength',
    'ProductTitle',
    'ProductType',
    'ProductWidth',
    'SKU',
    'ShortDescription',
    'Subtitle',
    'TaxProductCode',
    'UPC',
    'Warranty',
    'WeightUnit',
  ],
  'Pricing': [
    'Price',
    'Cost',
    'MSRP',
    'AvgCost',
    'MAPPrice',
  ],
};

const setPage = (dispatch: Dispatcher, productType: number, bundleType: number, productId?: string) => {
  const handler = async () => {
    dispatch({ type: Actions.SET_LOADING, params: true });
    let newProductType = productType;
    let newBundleType = bundleType;
    // comment temporary
    // const classifications = await Products.getClassifications();
    // comment temporary
    // const channels = await Products.getChannelControlFlags();
    // comment temporary
    // const attributesChannels = await Products.getAccountEnabled();

    // some channels don't have this Channel Inventory enabled
    // comment temporary
    /* (await Products.fetchProductAssignedChannelInv(productId || '0')
      .then((channelsInv) => {
        dispatch({ type: Actions.SET_CHANNELSINV_DCS, params: channelsInv.distributionCenterList });
        const channelListRaw = channelsInv.channelList.map((c: any) => ({
          ...c,
          display: true,
          channelAccountList: c.channelAccountList.map((cal: any) => ({
            ...cal,
            display: true,
            distributionList: channelsInv.distributionCenterList.map((dl: any) => ({ ...dl, display: true })),
          })),
        }));

        dispatch({
          type: Actions.SET_CHANNELSINV, params: {
            channelListRaw,
            channelListProduct: channelsInv.channelList,
          },
        });
      })
      .catch(() => {
      })); */


    /* const labels = await Products.getLabels({
      $count: true,
      $top: 0,
    });
    const dc = await Products.getDistributionCenters();
    dispatch({ type: Actions.SET_DC, params: dc });


    const inventoryTable = dc.map((distC: any) => ({
      DistributionCenterName: distC.DistributionCenterName,
      DistributionCenterId: distC.DistributionCenterID,
      DistributionCenterCode: distC.DistributionCenterCode,
      DistributionCenterType: distC.DistributionCenterType,
      AvailableQuantity: 0,
    }));

    const attributes = await Products.getAttributes();
    let groups1 = attributes.map((a: any) => a.Group1);
    let groups2 = attributes.map((a: any) => a.Group2);
    groups1 = groups1.filter((data: any, index: any) => groups1.indexOf(data) === index && data !== '');
    groups2 = groups2.filter((data: any, index: any) => groups2.indexOf(data) === index && data !== '');

    const visibilityMap = attributes.reduce((p: any, c: Entities.ProductAttribute) => {
      const r = { ...p };
      r[c?.AttributeId || 0] = true;
      return r;
    }, {});

    dispatch({ type: Actions.INIT_ATTRIBUTE_FILTER_BOOLS, params: visibilityMap });
    dispatch({
      type: Actions.SET_ATTRIBUTES,
      params: { attributes: attributes.filter((a: any) => a.AttributeType !== 3), groups1, groups2 },
    });
    dispatch({ type: Actions.SET_INVENTORY, params: inventoryTable });
    dispatch({ type: Actions.SET_ATTRIBUTESCHANNELS, params: attributesChannels });
    dispatch({ type: Actions.SET_INVENTORYFORM }); */
    if (productId && productId !== '0') {
      const productData = await Products.fetchSingleProductById(productId);
      console.log('pd', productType, ':', bundleType, productData);
      if ([2, 3].indexOf(productData.ProductBasic.ProductType) > -1 && productData.ProductBasic.BundleType === 0) {
        newProductType = productData.ProductBasic.ProductType;
        newBundleType = productData.ProductBasic.BundleType;
        /*try {
          const styleMasterProduct = await Products.getStyleMaster(productId);
          dispatch({ type: Actions.SET_PRODUCT_STYLEMASTER, params: styleMasterProduct });
        } catch(e) {
          message.error(`Fetch style master error: ${e}`);
          console.log('fetch style master error:', e);
        }*/
        console.log('t t', newProductType, newBundleType);
      }

      if (productData.VaryByList) {
        const productsByAttribute = await Products.getProductsByAttributes({
          attributeIdArray: productData?.VaryByList.join(';') || [],
        });
        dispatch({ type: Actions.SET_PRODUCTS, params: productsByAttribute.ProductByAttributeList });
      }

      dispatch({ type: Actions.SET_PRODUCT_DATA, params: { ...productData, productId, dispatch } });
      dispatch({ type: Actions.SET_PRODUCTTYPE, params: productData.ProductBasic.ProductType });
      dispatch({ type: Actions.SET_BUNDLETYPE, params: productData.ProductBasic.BundleType });

      // Uncomment this when productExt tab is going to be enabled again
      // if this try section can not be executed, the code must continue with a default value inside catch
      // try {
      //   const productsExt = await Products.getProductExts(productData.ProductBasic.SKU);
      //   const { data: { inventory } } = productsExt;
      //   const isProductExist = inventory !== undefined;
      //   dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: productsExt || {}, isProductExist } });
      // } catch {
      //   dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: {}, isProductExist: false } });
      // }

      // Delete this line when productExt tab is going to be enabled again
      dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: {}, isProductExist: false } });

    } else {
      dispatch({ type: Actions.SET_PRODUCTEXT, params: { data: null, isProductExist: false } });
      dispatch({ type: Actions.SET_EDITMODE, params: true });
      dispatch({ type: Actions.SET_PRODUCTTYPE, params: productType });
    }

    console.log('bt', productType, newProductType, newBundleType);
    /*if (newProductType === 2 && newBundleType === 0) {
      const styleVariations = await Products.fetchStyleVariations();

      console.log('sv', styleVariations);
      const stylesFormatterItemsOrder = [];
      if (styleVariations.data.styleVariation.useColorPatternCode) {
        stylesFormatterItemsOrder.push('colorCode', 'colorCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useLengthCode) {
        stylesFormatterItemsOrder.push('lengthCode', 'lengthCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useWidthCode) {
        stylesFormatterItemsOrder.push('widthCode', 'widthCodeSeparator');
      }
      if (styleVariations.data.styleVariation.useSizeCode) {
        stylesFormatterItemsOrder.push('sizeCode', 'sizeCodeSeparator');
      }
      stylesFormatterItemsOrder.push('STD');
      dispatch({ type: Actions.SET_STYLE_FORMATTER_ITEMS_ORDER, params: stylesFormatterItemsOrder });
      styleVariations.data.styleVariation.colorCode = styleVariations.data.styleVariation.colorPatternCode;
      delete styleVariations.data.styleVariation.colorPatternCode;
      dispatch({ type: Actions.SET_STYLEVARIATIONS, params: styleVariations.data.styleVariation });
    }*/


    // comment temporary
    // dispatch({ type: Actions.SET_LABELS, params: labels });
    // comment temporary
    // dispatch({ type: Actions.SET_CLASSIFICATIONS, params: classifications });
    // comment temporary
    //dispatch({ type: Actions.SET_CHANNELS, params: channels });
    dispatch({ type: Actions.SET_LOADING, params: false });
    dispatch({ type: Actions.SET_EDITING, params: false });
    return Promise.resolve();
  };

  handler()
    .catch((e) => {
      console.log('catch error', e);
      dispatch({ type: Actions.SET_LOADING, params: false });
      dispatch({ type: Actions.SET_EDITING, params: false });
    });
};

const useProductDetailPageLoad = (dispatch: Dispatcher, productType: number, bundleType: number, productId?: string) => {
  useEffect(() => {
    dispatch({ type: Actions.SET_EDITING, params: true });
    dispatch({ type: Actions.SET_LOADING, params: true });

    try {
      setPage(dispatch, productType, bundleType, productId);
    } catch (e) {
      console.log('Fetch page data error:', e);
      dispatch({ type: Actions.SET_LOADING, params: false });
      dispatch({ type: Actions.SET_EDITING, params: false });
    }
  }, [bundleType, dispatch, productId, productType]);
};

const onSave = async (state: ProductDetailState, dispatch: any, reduxDispatch: any, history: any) => {
  saveProductDetail(state, dispatch, reduxDispatch, history);
};

// const onSaveProduct = async (state: ProductDetailState, dispatch: any) => {
//   dispatch({ type: Actions.SET_EDITING, params: true });
//   setTimeout(() => {
//     //console.log('-- =>', state.basicInfoForm.submit, state);
//     state.basicInfoForm.submit();
//   }, 100);
// };

const onFinishFailedForm = (values: any, dispatch: any, notifications: any, state: ProductDetailState) => {
  const tabNames = values.errorFields.map((ef: any) => Object.keys(formTabsStructure).find(key => formTabsStructure[key].includes(ef.name[0])));
  const errorTabs = tabNames.filter((data: any, index: any) => tabNames.indexOf(data) === index);
  const newTabsMap = new Map();
  errorTabs.forEach((item: string) => newTabsMap.set(item, true));
  if (!_.isEqual(state.tabErrorDots, newTabsMap)) {
    dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs, show: true } });
    notifications.setNotification('error', 'Please fill all mandatory fields');
  }
};

const saveProductDetail = async (
  state: ProductDetailState,
  dispatch: any,
  reduxDispatch: any,
  history: any,
) => {
  const actions = bindActionCreators(notificationActions, reduxDispatch);

  dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS, params: state.basicInfoForm.getFieldValue('ClassificationNum') });
  dispatch({ type: Actions.SET_LOADING, params: true });
  saveProduct(dispatch, state)
    .then(async (response: any) => {
      // Uncomment this when productExt is going to be enable again
      // saveProductExt(dispatch, state)
      //   .then()
      //   .catch(() => actions.setNotification('error', 'Extended Product Info was not updated'));
      saveChannelInv(dispatch, state, response.productId)
        .then(() => {
          setPage(dispatch, state.productType, state.bundleType, state.productId);
          actions.setNotification('success', 'Product saved');
        })
        .catch((e) => {
          console.log(`save channel inv error: ${e}`);
          // message.error(`save channel inv error: ${e}`);
          dispatch({ type: Actions.SET_EDITMODE, params: true });
          dispatch({ type: Actions.SET_FORMUPDATED, params: true });
          // actions.setNotification('error', e);
        });

      // in order to keep the context, not reset the form
      //state.basicInfoForm.resetFields();
      state.basicInfoForm?.setFieldsValue({
        ...state.basicInfoForm.getFieldsValue(),
        styleMaster: {
          ...state.basicInfoForm.getFieldValue('styleMaster'),
          sizeCode: null,
          colorCode: null,
          lengthCode: null,
          widthCode: null,
          editReviewValues: [],
        },
      });
      dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: false });
      dispatch({ type: Actions.SET_STYLEMASTER_CURRENT_VIEW, params: 0 });
      /*dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS });
      dispatch({ type: Actions.RESET_PRODUCT });*/
      dispatch({ type: Actions.SET_EDITMODE, params: false });
      dispatch({ type: Actions.SET_FORMUPDATED, params: false });
      dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs: ['Basic', 'Pricing'], show: false } });
      dispatch({ type: Actions.SET_LOADING, params: false });
      dispatch({ type: Actions.SET_EDITING, params: false });

      if (state.isCreating) {
        // maybe should be reload after channel inv was saved
        history.push(`/product-detail/${response.productId}`);
      } else {
        //setPage(dispatch, state.productType, state.bundleType, state.productId);
        //actions.setNotification('success', 'Product saved');
      }
    })
    .catch(e => {
      const errInfo = `${e}`.replace(/Error:/, '');

      console.log('err info', errInfo);
      dispatch({ type: Actions.SET_LOADING, params: false });
      dispatch({ type: Actions.SET_EDITING, params: false });
      dispatch({ type: Actions.SET_FORMUPDATED, params: true });
      actions.setNotification('error', `There was an error: ${errInfo}`);
      //history.push('/products-list');
    });
};

// eslint-disable-next-line
// const Controls: React.FC<{ isCreating: boolean }> = ({ isCreating }) => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);
//   const history = useHistory();
//   const [showConfirm, setShowConfirm] = useState(false);

//   if (!state || !dispatch) {
//     return null;
//   }

//   const resetProduct = () => {
//     state.basicInfoForm.resetFields();
//     dispatch({ type: Actions.SET_SHOWSTYLEMASTERSTEPS, params: false });
//     dispatch({ type: Actions.RESET_ATTRIBUTEFILTERS });
//     dispatch({ type: Actions.RESET_PRODUCT });
//     dispatch({ type: Actions.SET_EDITMODE, params: false });
//     dispatch({ type: Actions.SET_FORMUPDATED, params: false });
//     dispatch({ type: Actions.SET_TABERRORDOTS, params: { errorTabs: ['Basic', 'Pricing'], show: false } });
//   };

//   return (
//     <>
//       <ConfirmationModal
//         handleCancel={() => setShowConfirm(false)}
//         handleConfirm={resetProduct}
//         visible={showConfirm}
//         title="Are you sure you want to cancel?"
//         confirmText="Yes"
//         cancelText="No"
//       />
//       {!state.loading && (
//       <FormButtons
//         editingMode={state.editMode}
//         permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
//         onEdit={() => {
//           dispatch({ type: Actions.SET_EDITMODE, params: true });
//         }}
//         onSave={() => onSaveProduct(state, dispatch)}
//         onCancel={() => {
//           if (isCreating) {
//             history.push('/products-list');
//           } else if (state.isFormUpdated) {
//             setShowConfirm(true);
//           } else {
//             resetProduct();
//           }
//         }}
//         disableSave={state.editMode && !state.isFormUpdated}
//       />
//       )}
//     </>
//   );
// };

// eslint-disable-next-line
// const PageHeading: React.FC = () => {
//   const [state, dispatch] = useContext(ProductDetailScreenContext);

//   if (!state || !dispatch) {
//     return null;
//   }

//   const pageTitle = (
//     <TitleWrapper>
//       <TitlePlaceholderWrapper>Product -</TitlePlaceholderWrapper>
//       <Form.Item
//         name={state.product?.ProductBasic.ProductTitle === '' || state.product?.ProductBasic.ProductTitle === undefined ? 'SKU' : 'ProductTitle'}
//         noStyle
//       >
//         <TitleInput tabIndex={-1} id="headerTitle" type="text" readOnly bordered={false} placeholder="New Product" />
//       </Form.Item>
//     </TitleWrapper>
//   );

//   return (
//     <Heading
//       title={pageTitle}
//       actions={(
//         <TopHeaderButtonsWrapper>
//           <HeaderButton
//             $hasPermission
//             type="primary"
//           >
//             Details
//             <Info width={22} height={22} pathColor="#fff" />
//           </HeaderButton>
//           <HeaderButton
//             $hasPermission
//           >
//             Dashboard
//             <Dashboard width={22} height={22} />
//           </HeaderButton>
//         </TopHeaderButtonsWrapper>
//       )}
//     />
//   );
// };

type Props = {
  channel?: StringKAnyVPair;
  isCreating: boolean;
  isFullscreen?: boolean;
  productId: string;
  refreshDialog?: Function;
  setTitleType?: Function;
};

const ProductDetailScreen = (props: Props) => {
  //const { productId, productType, bundleType } = useParams<{ productId?: string, productType?: string, bundleType?: string }>();
  const {
    isCreating = false,
    isFullscreen = false,
    productId,
  } = props;
  const { productType, bundleType } = useParams<{ productType?: string, bundleType?: string }>();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState({
    basicInfoForm: Form.useForm()[0],
    attributeFilterForm: Form.useForm()[0],
    attributesForm: Form.useForm()[0],
    channelInvForm: Form.useForm()[0],
    productExtForm: Form.useForm()[0],
    productType: Number(productType || 100),
    bundleType: Number(bundleType || 100),
    isCreating,
    showSteps: isCreating,
  }));
  const [activeTab, setAcitiveTab] = useState(TAB_BASIC_INFO);
  const [basicInfoChanged, setBasicInfoChanged] = useState(false);
  const [basicInfoEditCount, setBasicInfoEditCount] = useState(0);
  const [basicInfoTabKey, setBasicInfoTabKey] = useState(randomString());
  const [editOnlyBasic, setEditOnlyBasic] = useState(false);
  const [editOnlyOperation, setEditOnlyOperation] = useState(false);
  const [inited, setInited] = useState(false);
  const [imagePanelExpanded, setImagePanelExpanded] = useState(false);
  const [imagePanelRight, setImagePanelRight] = useState<number>();
  const [operationChanged, setOperationChanged] = useState(false);
  const [operationEditCount, setOperationEditCount] = useState(0);
  const [operationTabKey, setOperationTabKey] = useState(randomString());
  const [previewImageVersion, setPreviewImageVersion] = useState(0);
  const [previewedImages, setPreviewedImages] = useState<StringKAnyVPair[]>([]);
  const [dispalyDropship, setDisplayDropship] = useState(false);
  const [ats, setAts] = useState<StringKAnyVPair>({})

  //const [styleTabTitle, setStyleTabTitle] = useState('Style Master');

  const detailCtnRef = React.useRef<any>(null);

  const panelHeight = React.useMemo(() => {
    let hd = props.isFullscreen ? 104 : 298;

    if (typeof props.isFullscreen === 'undefined') hd = 240;

    return window.innerHeight - hd;
  }, [props]);

  const notifications = bindActionCreators(notificationActions, useDispatch());

  useProductDetailPageLoad(dispatch, Number(productType), Number(bundleType), productId);

  const getClassification = useCallback(() => {
    // console.log('->', state.basicInfoForm.getFieldValue('ClassificationNum'));
    return state.basicInfoForm.getFieldValue('ClassificationNum');
  }, [state.basicInfoForm]);

  const getDetailTabsClassName = () => {
    const cls = ['detail-tabs'];

    if (imagePanelExpanded) cls.push('shrink');

    return cls.join(' ');
  };

  const getImagePanelClassName = () => {
    const cls = ['image-ctn'];

    if (imagePanelExpanded) cls.push('expand');

    return cls.join(' ');
  };

    const fetchProductAts = async (id: string) => {
      try {
        //console.log('ids', ids);
        const res = await fetchProductWithChildInventoryAts(id);
  
        if (Array.isArray(res)) {
          const pDict: StringKAnyVPair = {};
          //console.log('res', res);
          res.forEach(e => {
            pDict[e.productId] = e.atsQuantity;
          });
         setAts(pDict)
        }
      } catch(e) {
      } finally {
      }
    };

  const loadAttributes = useCallback(async () => {
    console.log('ats', state.attributes);
    let readyData = false;
    const attrs = state.attributes;

    if (Array.isArray(attrs) && attrs.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      //const attributes = await Products.getAttributes();
      const attributes = await Products.getAllAttributes();
      let groups1 = attributes.map((a: any) => a.Group1);
      let groups2 = attributes.map((a: any) => a.Group2);
      groups1 = groups1.filter((data: any, index: any) => groups1.indexOf(data) === index && data !== '');
      groups2 = groups2.filter((data: any, index: any) => groups2.indexOf(data) === index && data !== '');

      const visibilityMap = attributes.reduce((p: any, c: Entities.ProductAttribute) => {
        const r = { ...p };
        r[c?.AttributeId || 0] = true;
        return r;
      }, {});

      dispatch({ type: Actions.INIT_ATTRIBUTE_FILTER_BOOLS, params: visibilityMap });
      dispatch({
        type: Actions.SET_ATTRIBUTES,
        params: { attributes: attributes.filter((a: any) => a.AttributeType !== 3), groups1, groups2 },
      });
    }
  }, [state.attributes]);

  const loadAttributesChannels = useCallback(async () => {
    console.log('atcs', state.attributesChannels);
    let readyData = false;
    let ret = state.attributesChannels;

    if (ret && Array.isArray(ret) && ret.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      const attributesChannels = await Products.getAccountEnabled();

      dispatch({ type: Actions.SET_ATTRIBUTESCHANNELS, params: attributesChannels });
      ret = attributesChannels;
    }

    return ret;
  }, [state.attributesChannels]);

  const loadChannelControlFlags = useCallback(async () => {
    console.log('ccfs -->', state.channels);
    let readyData = false;
    let ret = state.channels;

    if (ret && Array.isArray(ret) && ret.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      const channels = await Products.getChannelControlFlags();

      dispatch({ type: Actions.SET_CHANNELS, params: channels });
      ret = channels;
    }

    return ret;
  }, [state.channels]);

  const loadChannelInv = useCallback(async () => {
    let readyData = false;
    const dcs = state.channelsInvDCs;

    if (Array.isArray(dcs) && dcs.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      const channelsInv = await Products.fetchProductAssignedChannelInv(productId || '0');
      dispatch({ type: Actions.SET_CHANNELSINV_DCS, params: channelsInv.distributionCenterList || [] });
      const channelListRaw = (channelsInv.channelList || []).map((c: any) => ({
        ...c,
        display: true,
        channelAccountList: c.channelAccountList.map((cal: any) => ({
          ...cal,
          display: true,
          distributionList: channelsInv.distributionCenterList.map((dl: any) => ({ ...dl, display: true })),
        })),
      }));

      dispatch({
        type: Actions.SET_CHANNELSINV, params: {
          channelListRaw,
          channelListProduct: (channelsInv.channelList || []),
        },
      });
    }
  }, [productId, state.channelsInvDCs]);

  const loadClassifications = useCallback(async () => {
    console.log('clfs -->', state.classifications);
    let readyData = false;
    let ret = state.classifications;

    if (ret && Array.isArray(ret) && ret.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      const classifications = await Products.getClassifications();

      dispatch({ type: Actions.SET_CLASSIFICATIONS, params: classifications });
      ret = classifications;
    }

    return ret;
  }, [state.classifications]);

  // eslint-disable-next-line
  const loadDistributionCenters = async () => {
    console.log('dc -->', state.distributionCenters, 'inv -->', state.inventory);
    let readyData = false;
    let dc = state.distributionCenters;
    const inv = state.inventory;

    if (Array.isArray(dc) && Array.isArray(inv) && dc.length > 0 && inv.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      dc = await Products.getDistributionCenters();
      const inventoryTable = dc.map((distC: any) => ({
        DistributionCenterName: distC.DistributionCenterName,
        DistributionCenterId: distC.DistributionCenterID,
        DistributionCenterCode: distC.DistributionCenterCode,
        DistributionCenterType: distC.DistributionCenterType,
        AvailableQuantity: 0,
      }));

      dispatch({ type: Actions.SET_DC, params: dc });
      dispatch({ type: Actions.SET_INVENTORY, params: inventoryTable });
      dispatch({ type: Actions.SET_INVENTORYFORM });
    }

    return [dc, inv];
  };

  const loadLabels = useCallback(async () => {
    console.log('lbls -->', state.labels);
    let readyData = false;
    let ret = state.labels;

    if (ret && Array.isArray(ret) && ret.length > 0) {
      readyData = true;
    }

    if (!readyData) {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (res && typeof res === 'object' && Array.isArray(res.LabelList)) {
        dispatch({ type: Actions.SET_LABELS, params: res });
        ret = res.LabelList;
      }
    }

    return ret;
  }, [state.labels]);

  const onBasicInfoChanged = useCallback((changed: boolean) => {
    setBasicInfoChanged(changed);
  }, []);

  const updatePreviewedImageVersion = useCallback(() => {
    setPreviewImageVersion(previewImageVersion + 1);
  }, [previewImageVersion]);

  const onClosePreviewedImage = useCallback((img: StringKAnyVPair) => {
    for (let i = previewedImages.length; i > 0; i--) {
      const cur = previewedImages[i - 1];

      if (cur.id === img.id) {
        previewedImages.splice(i - 1, 1);
      }
    }

    //console.log('c-->', previewedImages);
    setPreviewedImages([...previewedImages]);
    updatePreviewedImageVersion();
  }, [previewedImages, updatePreviewedImageVersion]);

  /*const isDevEnv = () => {
    const devEnvs = ['development', 'local'];

    return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
  };*/

  const onOperationChanged = useCallback((changed: boolean) => {
    setOperationChanged(changed);
  }, []);

  const toggleEditBasicInfoMode = useCallback(() => {
    if (editOnlyBasic) {
      setBasicInfoTabKey(randomString());
    }

    setEditOnlyBasic(!editOnlyBasic);
  }, [editOnlyBasic]);

  const onSavedBasicInfo = useCallback((saved: boolean) => {
    if (saved) {
      toggleEditBasicInfoMode();
    }
  }, [toggleEditBasicInfoMode]);

  const toggleEditOperationMode = useCallback(() => {
    if (editOnlyOperation) {
      setOperationTabKey(randomString());
    }

    setEditOnlyOperation(!editOnlyOperation);
  }, [editOnlyOperation]);

  const onSavedOperation = useCallback((saved: boolean) => {
    if (saved) {
      toggleEditOperationMode();
    }
  }, [toggleEditOperationMode]);

  const onSelectProductTab = (activeKey: string) => {
    // console.log('----->', activeKey, state);
    setAcitiveTab(activeKey);
  };

  const previewImage = useCallback((img: StringKAnyVPair) => {
    setPreviewedImages([...previewedImages, img]);
    updatePreviewedImageVersion();
  }, [previewedImages, updatePreviewedImageVersion]);

  const saveBasicInfo = () => {
    setBasicInfoEditCount(basicInfoEditCount + 1);
  };

  const saveOperation = () => {
    setOperationEditCount(operationEditCount + 1);
  };

  // eslint-disable-next-line
  const setImagePanelPosition = () => {
    const ctn = detailCtnRef?.current;
    const setPosition = () => {
      if (!ctn) return;
      //if (imagePanelExpanded) {
      const rect = ctn.getBoundingClientRect();
      const right = rect.left + rect.width;

      //console.log('rect ->', right, rect);
      if (right > 0) setImagePanelRight(window.innerWidth - right);
      /*} else {
        setImagePanelRight(undefined);
      }*/
    };

    if (ctn) setPosition();
    else setTimeout(setPosition, 500);
  };

  const tabExtraContext = () => {
    switch (activeTab) {
      case TAB_BASIC_INFO: {
        if (editOnlyBasic) {
          return (
            <Space>
              {/*<ActionDropdownMenu productId={productId} refreshDialog={props.refreshDialog} />*/}
              {state.productType !== 1 && <OverwriteOptionList
                dispatch={dispatch}
                state={state}
              />}
              <HoverBgButton hovertype="info" onClick={toggleEditBasicInfoMode}>
                Cancel
              </HoverBgButton>
              <Button
                disabled={!basicInfoChanged}
                onClick={saveBasicInfo}
                type="primary"
              >
                <SaveOutlined />
                Save Basic Info
              </Button>
            </Space>
          );
        }
        return (
          <Space>
            {/*<ActionDropdownMenu productId={productId} refreshDialog={props.refreshDialog} />*/}
            {state.productType !== 1 && <OverwriteOptionList
              dispatch={dispatch}
              state={state}
            />}
            {/*<Button onClick={toggleEditBasicInfoMode} type="primary">
              <EditOutlined />
              Edit Basic Info
            </Button>*/}
          </Space>
        );
      }

      case TAB_OPERATION: {
        if (editOnlyOperation) {
          return (
            <Space>
              {state.productType !== 1 && <OverwriteOptionList
                dispatch={dispatch}
                state={state}
              />}
              {/*<ActionDropdownMenu productId={productId} refreshDialog={props.refreshDialog} />*/}
              <HoverBgButton hovertype="info" onClick={toggleEditOperationMode}>
                Cancel
              </HoverBgButton>
              <Button
                disabled={!operationChanged}
                onClick={saveOperation}
                type="primary"
              >
                <SaveOutlined />
                Save Operation
              </Button>
            </Space>
          );
        }

        return (
          <Space>
            {state.productType !== 1 && <OverwriteOptionList
              dispatch={dispatch}
              state={state}
            />}
            {/*<ActionDropdownMenu productId={productId} refreshDialog={props.refreshDialog} />*/}
            {/*<Button onClick={toggleEditOperationMode} type="primary">
              <EditOutlined />
              Edit Operation
            </Button>*/}
          </Space>
        );
      }
      default:
        break;
    }

    //return null;
    return (
      <Space>
        {state.productType !== 1 && <OverwriteOptionList
          dispatch={dispatch}
          state={state}
        />}
        {/*<ActionDropdownMenu productId={productId} refreshDialog={props.refreshDialog} />*/}
      </Space>
    );
  };

  const toggleImagePanel = () => {
    setImagePanelExpanded(!imagePanelExpanded);
  };

  //#region move dispatch functionality out of tab components


  const setFilter = useCallback(() => {
    if (!dispatch) {
      return;
    }

    const filterValues = state.channelInvForm?.getFieldsValue();
    let newChannelsInv = state.basicInfoForm?.getFieldValue('channelsInv');

    if (!Array.isArray(newChannelsInv)) return;

    if (filterValues?.Channel !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        display: channel.platFormNum === 0 ? channel.channelNum === filterValues?.Channel : channel.platFormNum === filterValues?.Channel,
      }));
    } else {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        display: true,
      }));
    }

    if (filterValues?.DistributionCenter !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: dl.distributionCenterNum === filterValues?.DistributionCenter,
          })),
        })),
      }));
    }

    if (filterValues?.Filter !== '' && filterValues?.Filter !== undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: dl.distributionCenterCode ? dl.distributionCenterCode.toString().toLowerCase().includes(filterValues?.Filter?.toString()?.toLowerCase()) : false,
          })),
        })),
      }));
    }

    if ((filterValues?.Filter === '' || filterValues?.Filter === undefined) && filterValues?.DistributionCenter === undefined) {
      newChannelsInv = newChannelsInv?.map((channel: any) => ({
        ...channel,
        channelAccountList: channel.channelAccountList.map((channelAccountListElement: any) => ({
          ...channelAccountListElement,
          distributionList: channelAccountListElement.distributionList.map((dl: any) => ({
            ...dl,
            display: true,
          })),
        })),
      }));
    }

    dispatch({ type: Actions.FILTER_CHANNELSINV, params: newChannelsInv });
  }, [state.basicInfoForm, state.channelInvForm]);



  const enterEditModeDispatch = useCallback(() => {
    if (typeof dispatch === 'function') {
      dispatch({ type: Actions.SET_EDITMODE, params: true });
    }
  }, []);

  const saveChannelInvDispatch = useCallback(async () => {
    if (state) {
      await saveChannelInv(dispatch, state, state.productId);
      dispatch({ type: Actions.SET_FORMUPDATED, params: false });
    }
  }, [state]);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
    let ps2: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1 || ps2) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'EnableAsDropShipper')[0];
          ps2 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'EnableAsDropShippingSupplier')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } 
    }

    if (ps1 && ps2) {
      const ps1Value = parseInt(ps1.SettingValue) || 0;
      const ps2Value = parseInt(ps2.SettingValue) || 0;
      setDisplayDropship(!!ps1Value || !!ps2Value);
    }
  },[]);


  //#endregion

  React.useEffect(() => {
    if (!inited) {
      loadDistributionCenters();
      loadProfileSetting()
      setInited(true);
    }

    if (state) {
      if (typeof props.setTitleType === 'function') {
        props.setTitleType(state.productType);
      }
    }

    setImagePanelPosition();
  }, [
    inited,
    loadDistributionCenters,
    props,
    setImagePanelPosition,
    loadProfileSetting,
    state,
  ]);

  useEffect(()=>{
    if(state.productId){
      fetchProductAts(state.productId)
    }
  },[state.productId])

  return (
    <>
      <ProductDetailScreenContext.Provider value={[state, dispatch]}>
        <Prompt
          when={state.isFormUpdated}
          message="You have unsaved changes, are you sure you want to exit?"
        />
        <Spin spinning={false}>
          <Form.Provider onFormChange={() => {
            if (!state.isFormUpdated) {
              dispatch({ type: Actions.SET_FORMUPDATED, params: true });
            }
          }}
          >
            <Form
              form={state.basicInfoForm}
              labelCol={{
                sm: { span: 12 },
                lg: { span: 7 },
                xl: { span: 7 },
              }}
              wrapperCol={{
                sm: { span: 13 },
                lg: { span: 12 },
                xl: { span: 17 },
              }}
              initialValues={{ size: 'middle' }}
              onFinish={() => onSave(state, dispatch, reduxDispatch, history)}
              onFinishFailed={(values: any) => onFinishFailedForm(values, dispatch, notifications, state)}
            >
              {/* {!state.editing && state.loading && <LoadingIcon />} */}
              <SiteContent style={{ padding: 0 }}>
                <DetailContainer ref={detailCtnRef}>
                  <CardTabs
                    className={getDetailTabsClassName()}
                    defaultActiveKey={state.activeTab}
                    onChange={onSelectProductTab}
                    tabBarExtraContent={tabExtraContext()}
                    type="card"
                  >
                    <TabPane
                      tab={(
                        <Badge dot={state.tabErrorDots.get('Basic')} offset={[5, 5]}>
                          Basic Info
                        </Badge>
                      )}
                      key={TAB_BASIC_INFO}
                      forceRender
                    >
                      <BasicInfo
                        dispatch={dispatch}
                        editOnly={editOnlyBasic}
                        editSave={basicInfoEditCount}
                        isCreating={isCreating}
                        key={basicInfoTabKey}
                        loadChannelControlFlags={loadChannelControlFlags}
                        loadClassifications={loadClassifications}
                        loadLabels={loadLabels}
                        onContentChanged={onBasicInfoChanged}
                        onSaved={onSavedBasicInfo}
                        productId={state.productId}
                        state={state}
                        ats={ats}
                      />
                    </TabPane>
                    <TabPane
                      tab={(
                        <Badge dot={state.tabErrorDots.get('Basic')} offset={[5, 5]}>
                          Operation
                        </Badge>
                      )}
                      key={TAB_OPERATION}
                      forceRender
                    >
                      <Operation
                        dispatch={dispatch}
                        editOnly={editOnlyOperation}
                        editSave={operationEditCount}
                        focused={activeTab === TAB_OPERATION}
                        isCreating={isCreating}
                        loadChannelControlFlags={loadChannelControlFlags}
                        loadClassifications={loadClassifications}
                        loadLabels={loadLabels}
                        key={operationTabKey}
                        onContentChanged={onOperationChanged}
                        onSaved={onSavedOperation}
                        productId={state.productId}
                        state={state}
                      />
                    </TabPane>
                    {
                      state.productType === 0 && state.bundleType === 1 && (
                        <TabPane
                          tab="Bundled"
                          key={TAB_BUNDLED}
                          forceRender
                        >
                          <ProductBundles />
                        </TabPane>
                      )
                    }
                    <TabPane
                      tab={(
                        <Badge dot={false} offset={[5, 5]}>
                          Images
                        </Badge>
                      )}
                      key={TAB_IMAGES}
                      forceRender
                    >
                      <ProductImages
                        state={state}
                        dispatch={dispatch}
                        focused={activeTab === TAB_IMAGES}
                        loadAttributes={loadAttributes}
                        loadAttributesChannels={loadAttributesChannels}
                        productId={state.productId}
                      />
                    </TabPane>
                    {
                      [2, 3].indexOf(state.productType) > -1 && state.bundleType === 0 && (
                        <TabPane
                          tab={(
                            <Badge dot={state.tabErrorDots.get('Variation')} offset={[5, 5]}>
                              {/*state.productType === 2 ? 'Style Master' : 'Sub-Style'*/}
                              Children
                            </Badge>
                          )}
                          key={TAB_VARIATION}
                          forceRender
                        >
                          <StyleMasterScreen
                            focused={activeTab === TAB_VARIATION}
                            isCreating={isCreating}
                            loadAttributes={loadAttributes}
                            loadAttributesChannels={loadAttributesChannels}
                            productId={state.productId}
                            productType={state.productType}
                            ats={ats}
                          />
                        </TabPane>
                      )
                    }
                    <TabPane
                      tab={(
                        <Badge dot={false} offset={[5, 5]}>
                          Content Resources
                        </Badge>
                      )}
                      key={TAB_COPYWRITING}
                      forceRender
                    >
                      <Copywriting
                        dispatch={dispatch}
                        focused={activeTab === TAB_COPYWRITING}
                        isFullscreen={isFullscreen}
                        productId={state.productId || ''}
                        productType={state.productType}
                        state={state}
                      />
                    </TabPane>
                    <TabPane
                      tab={(
                        <Badge dot={false} offset={[5, 5]}>
                          Channel Attributes
                        </Badge>
                      )}
                      key={TAB_ATTRIBUTES}
                      forceRender
                    >
                      <Attributes
                        channel={props.channel}
                        dispatch={dispatch}
                        focused={activeTab === TAB_ATTRIBUTES}
                        getClassification={getClassification}
                        isFullscreen={isFullscreen}
                        isInDialog
                        loadAttributes={loadAttributes}
                        loadAttributesChannels={loadAttributesChannels}
                        previewImage={previewImage}
                        productId={state.productId}
                        state={state}
                      />
                    </TabPane>
                    {dispalyDropship && <TabPane
                      tab={(
                        <Badge dot={false} offset={[5, 5]}>
                          Dropship
                        </Badge>
                      )}
                      key={TAB_DROPSHIP}
                      forceRender
                    >
                      <Dropship
                        dispatch={dispatch}
                        focused={activeTab === TAB_DROPSHIP}
                        getClassification={getClassification}
                        isFullscreen={isFullscreen}
                        isInDialog
                        loadAttributes={loadAttributes}
                        loadAttributesChannels={loadAttributesChannels}
                        previewImage={previewImage}
                        productId={state.productId}
                        state={state}
                      />
                    </TabPane>}
                    {
                      isDevEnv() && (
                        <>
                          <TabPane
                            tab={(
                              <Badge dot={false} offset={[5, 5]}>
                                Inventory
                              </Badge>
                            )}
                            key={TAB_INVENTORY}
                            forceRender
                          >
                            <Inventory
                              state={state}
                              dispatch={dispatch}
                              focused={activeTab === TAB_INVENTORY}
                            />
                          </TabPane>
                          <TabPane
                            tab={(
                              <Badge dot={false} offset={[5, 5]}>
                                Channel Inv
                              </Badge>
                            )}
                            key={TAB_CHANNEL_INV}
                            forceRender
                          >
                            <ChannelInv
                              focused={activeTab === TAB_CHANNEL_INV}
                              loadChannelInv={loadChannelInv}
                              productId={state.productId || '0'}
                              state={state}
                              dispatch={dispatch}
                              setFilter={setFilter}
                              enterEditModeDispatch={enterEditModeDispatch}
                              saveChannelInvDispatch={saveChannelInvDispatch}
                            />
                          </TabPane>
                        </>
                      )
                    }
                    {
                      state.productType === 1 && (
                        <TabPane
                          tab={(
                            <Badge dot={false} offset={[5, 5]}>
                              Alias
                            </Badge>
                          )}
                          key={TAB_ALIAS}
                          forceRender
                        >
                          <Alias
                            focused={activeTab === TAB_ALIAS}
                            loadChannelInv={loadChannelInv}
                            productId={state.productId || '0'}
                          />
                        </TabPane>
                      )
                    }
                    <TabPane
                      tab={(
                        <Badge dot={false} offset={[5, 5]}>
                          Qty Overwrite
                        </Badge>
                      )}
                      key={TAB_QTY_OVERWRITE}
                      forceRender
                    >
                      <QtyOverwrite 
                        focused={activeTab === TAB_QTY_OVERWRITE}
                        productId={state.productId || '0'}
                        centralProductNum ={state.product?.ProductBasic.CentralProductNum || 0}
                        state={state}
                        isOverwrite={state.overwriteChildren}
                        productType={state.productType}
                        sku={state.product?.ProductBasic.SKU || ''}
                      />
                    </TabPane>
                  </CardTabs>
                  <Col
                    className={getImagePanelClassName()}
                    style={{ height: panelHeight, right: imagePanelRight }}
                  >
                    <Row className="image-ctn-row">
                      {props.isFullscreen && <Col
                        className="image-ctn-resizer"
                        style={{
                          display: imagePanelExpanded ? '' : 'none',
                        }}
                      />}
                      <Col style={{ width: 'calc(100% - 2px)' }}>
                        <Row
                          className="image-panel-header"
                          justify={imagePanelExpanded ? 'start' : 'end'}
                        >
                          <Tooltip
                            title={imagePanelExpanded ? 'Hide images' : 'Show Images'}
                            trigger={['hover', 'click']}
                          >
                            <span
                              aria-hidden
                              role="button"
                              className="left-expand-icon"
                              onClick={toggleImagePanel}
                              onKeyDown={toggleImagePanel}
                              style={{
                                //marginRight: imagePanelExpanded ? undefined : -6,
                                //marginRight: -6,
                              }}
                            >
                              {imagePanelExpanded ?
                                <>
                                  <PictureOutlined className="picture-left" />
                                  <CaretRightOutlined className="right-caret" />
                                </> :
                                <>
                                  <CaretLeftOutlined className="left-caret" />
                                  <PictureOutlined className="picture-right" />
                                </>}
                            </span>
                          </Tooltip>
                        </Row>
                        <div
                          className="image-panel-ctn"
                          style={{ display: imagePanelExpanded ? '' : 'none' }}
                        >
                          <ImagePanel
                            focused={imagePanelExpanded}
                            productId={state.productId}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </DetailContainer>
              </SiteContent>
            </Form>
          </Form.Provider>
        </Spin>
        {state.editing && (
          <ScreenMask>
            <Loading size={LOADING_ICON_SIZE1} />
          </ScreenMask>
        )}
      </ProductDetailScreenContext.Provider>
      <PreviewImages
        onClosePreviewedImage={onClosePreviewedImage}
        images={previewedImages}
        version={previewImageVersion}
      />
    </>
  );
};

export default ProductDetailScreen;

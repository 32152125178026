import React from "react";
import { UploadOutlined, ContainerOutlined } from "@ant-design/icons";

const pageMenuConfig:any = {
    contentResouce : {
        title: 'Content Resource',
        ccUrl:'/sell/settings-content-resources',
        pimUrl:'/pim/content-source',
        leftList: [
          {
            title: "List",
            key: "1",
            icon: <ContainerOutlined />,
            children: [
              {
                title: "By Customer View",
                key: "2",
                description: "KKKKKKKK",
              },
              {
                title: "By Group",
                key: "3",
                description: "DSDSDSDSDSDSDSDSDSD",
              },
            ],
            description: "dddddddd",
          },
          {
            title: "Import",
            key: "4",
            icon: <UploadOutlined />,
            description:
              "You can download a template and import content resource fields.",
          },
          {
            title: "Manage",
            key: "5",
            icon: <UploadOutlined />,
            description: "You can add or edit content resource defination",
          },
        ],
        rightList: [
          {
            title: "Bulk Push",
            key: "6",
            description: "Copy the Content from content",
            icon: <UploadOutlined />,
          },
        ]
      }
}

export default pageMenuConfig
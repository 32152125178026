import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchOutlined, CloudDownloadOutlined, LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import {
  Button,
  Col,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
  Typography,
  Spin,
  Card,
  Tag,
  notification,
  Modal,
} from 'antd';
import styled from 'styled-components';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
import SiteContent from '../../components/SiteContent';
import Spacer from '../../components/common/Spacer';
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from '../../components/common/datagrid/Filter';
import Products, {
  fetchAvailableAttributesPlus,
  fetchSimpleProductGroup,
  fetchSimpleProductList,
  fetchSimpleProductPartialData,
  fetchOperationSet,
} from '../../services/products';
//import ClassificationSelector from '../ListProducts/ClassificationSelector';
import GroupDetail from '../ListProducts/GroupDetail';
import ExportMdal from './ExportModal';
import axios from 'axios';
import { fetchProductAttributeSets } from '../../services/products';
import { SelectValue } from 'antd/lib/select';
import ProductDetailDialog from '../ListProducts/ProductDetailDialog';
import {
  getOperationStatusOptions,
  getOperationStatusDesc,
} from '../DetailProduct/BasicAttrEditor';
import Editer from '../../screens/DetailProduct/BasicAttrEditor/TextEditor';
import SetValueCell from '../Channel/setValueCell';
import EditImageDialog from "../DetailProduct/EditImageDialog";
import SimpleSelectorModal from "../DetailProduct/BasicAttrEditor/SimpleSelectorModal";
import { getProfileSettingValue, checkStringType } from '../../util';
import { isCodeListType } from '../ListProducts';
import { FormLabel, StyleInputWrapper } from '../../components/common/styledComponents';
import SearchFilterPanel from '../ListProducts/SearchFilterPanel2';
import {
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  CODE_OPTION_COLOR_CONTAINS,
  CODE_OPTION_COLOR_EQUALS,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_CONTAINS,
  CODE_OPTION_SKU_EQUALS,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_CONTAINS,
  CODE_OPTION_STYLE_EQUALS,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_EQUALS,
  CODE_OPTION_UPC_LIST,
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  OPERATION_CONTAIN,
  OPERATION_EQUALS,
  OPERATION_LIST,
  SEARCH_PRODUCT_PARAMS
} from '../../constants/config';
import SearchTips from '../../components/common/SearchTips';
import ERPLinkTableCell from  '../../components/common/ERPLinkTableCell'
import TableFilter from '../../components/common/TableFilter'
import { getProfilesState } from '../../redux/utils';
import { fetchProfileSettings } from '../../services/channels';

const { Text } = Typography;

const CancelToken = axios.CancelToken;
let cancel: any = undefined;

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

const SKULink = (props: SKULinkProps) => {
  const { product, target = '_self' } = props;
  const pids = product.ProductId.split('/');
  const productId = pids[pids.length - 1] || '-';

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const SectionTitle = styled.h2`
//   font-weight: bold;
//   margin-bottom: 0;
// `;

const SearchCol = styled(Col)`
  width: 100%;

  & .ant-input:read-only {
    background-color: #dcdcdc;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #dcdcdc;
  }

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #d9d9d9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ": ";
  }

  & .loading-wrapper {
    display: "inline-block";
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    /*width: 768px;*/
    width: 100%;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 430px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      /*width: 820px;*/
      width: 100%;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      /*width: 868px;*/
      width: 100%;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      /*width: 928px;*/
      width: 100%;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      /*width: 1028px;*/
      width: 100%;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      /*width: 1150px;*/
      width: 100%;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

export const columns = [
  {
    name: 'mediaURL',
    header: 'Image',
    defaultFlex: 1,
    defaultLocked: true,
    locked: 'start',
    minWidth: 100,
    maxWidth: 100,
    render({ value, data }: { value: string; data: Entities.ProductProfile }) {
      const src = value || 'https://via.placeholder.com/300';
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: 'VariationParentSKU',
    header: 'Style',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // return data.styleCode || data.VariationParentSKU;
        return <ERPLinkTableCell value={data.styleCode || data.VariationParentSKU} type={1} />
      }

      return '';
    },
  },
  {
    //name: 'ProductId',
    name: 'ProductId',
    header: 'Substyle',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 180,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // return data.subStyleCode || '';
        return <ERPLinkTableCell type={2} value={data.subStyleCode || ''} />
      }

      return '';
    },
  },
  {
    name: 'SKU',
    header: 'SKU',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 200,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  {
    name: 'ProductStatus',
    header: 'Status',
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
  // { name: 'ProductTitle', header: 'Title', defaultFlex: 2, minWidth: 200 },
  // {
  //   name: 'Brand',
  //   header: 'Brand',
  //   defaultFlex: 1,
  //   minWidth: 150,
  // },
  // { name: 'Type', header: 'Type', defaultFlex: 1, minWidth: 100 },
  // {
  //   name: 'Classifications',
  //   header: 'Classification',
  //   defaultFlex: 1,
  //   minWidth: 140,
  //   render({ data }: { data: any }) {
  //     if (data && typeof data === 'object') {
  //       return data.classificationName || data.Classifications || '';
  //     }

  //     return '';
  //   },
  // },
  // {
  //   name: 'ChannelControlFlags',
  //   header: 'Sales Channel',
  //   defaultFlex: 1,
  //   minWidth: 190,
  //   render({ data }: { data: any }) {
  //     if (data && typeof data === 'object') {
  //       // note can not use Array.isArray to identify the type of channelControlFlags here
  //       if (data.channelContorlFlags && typeof data.channelContorlFlags === 'object' && data.channelContorlFlags.length > 0) {
  //         //return Array.isArray(data.channelControlFlags) ? data.channelControlFlags.join(', ') : (data.ChannelControlFlags || '');
  //         return data.channelContorlFlags.join(', ');
  //       }
  //     }

  //     return '';
  //   },
  // },
  // {
  //   name: 'Labels',
  //   header: 'Labels',
  //   defaultFlex: 1,
  //   minWidth: 130,
  //   render({ data }: { data: any }) {
  //     if (data && typeof data === 'object') {
  //       return Array.isArray(data.labels) ? data.labels.join(', ') : data.Labels || '';
  //     }

  //     return '';
  //   },
  // },
  // { name: 'UPC', header: 'UPC', defaultFlex: 1, minWidth: 150 },
  // {
  //   name: 'QtyTotal',
  //   header: 'Quantity',
  //   defaultFlex: 1,
  //   filterEditor: NumberFilter,
  //   minWidth: 110,
  // },
];

/*const initialProductListing = {
  ProductTotalCount: 0,
  ProductList: [],
};*/
let profileSettings: StringKAnyVPair[] = [];


const PageContent: React.FC = () => {
  const { Option } = Select;
  const DEFAULT_GRID_LIMIT = 20;
  const {defaultSearchCodeType, defaultSearchGroup} = localStorage.getItem(SEARCH_PRODUCT_PARAMS) ? JSON.parse(localStorage.getItem(SEARCH_PRODUCT_PARAMS) ||'') : {
    defaultSearchCodeType: CODE_OPTION_SKU_CONTAINS,
    defaultSearchGroup: GROUP_OPTION_PRODUCT,
  }

  // const [extLoading, setExtLoading] = useState<boolean>(false);
  const [filteredTempData, setFilteredTempData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [stateColumns, setStateColumns] = useState<any>(columns);
  const [detailVisible, setDetailVisible] = useState(false);
  // const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [editSelectorDialogVisible, setEditSelectorDialogVisible] = useState(false);
  const [eleAttrs, setEleAttrs] = useState<StringKAnyVPair[]>([]);
  //const [initDataReady, setInitDataReady] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, setSearchCFs] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [searchCodeType, setSearchCodeType] = useState(defaultSearchCodeType);
  const [searchMultiCode, setSearchMultiCode] = useState('');
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(defaultSearchGroup);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchBrand, setSearchBrand] = useState<string>('');
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchSorter, setSearchSorter] = useState<string>('SKU ASC');
  const [searchStatus, setSearchStatus] = useState<number[]>([0]);
  const [searchTitle, setSearchTitle] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [templateLoading, setTemplateLoading] = useState<boolean>(true);
  const [selected, setSelected] = React.useState<any>({});
  const [operationSelectList, setOperationSelectList] = useState<any[]>([]);
  const [operationSet, setOperationSet] = useState<SelectValue>();
  const [currentValue, setCurrentValue] = useState<any>();
  const [valueModalVisible, setValueModalVisible] = useState(false);
  // eslint-disable-next-line
  const [templateInfo, setTemplateInfo] = React.useState<any>();

  // todo1
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>({});
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [labelVersion, setLabelVersion] = useState(0);
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const filterState = React.useRef<any>(null);
  const filterHiddenTimer = React.useRef<any>(0);

  const codeInputRef = React.useRef<any>(null);

  const [productListStyle, setProductListStyle] = useState<string>();
  const [overwriteChild, setOverwriteChild] = useState(false);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } 
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwriteChild(!!allowOverWrite);
    }
  },[]);

  useEffect(() => {
    loadProfileSetting();
  }, [loadProfileSetting]);

  useEffect(()=>{
    localStorage.setItem(SEARCH_PRODUCT_PARAMS, JSON.stringify({
      defaultSearchCodeType: searchCodeType,
      defaultSearchGroup: searchGroup,
    }))
  },[searchCodeType, searchGroup])

  const getProductListStyle = useCallback(async () => {
    const value = await getProfileSettingValue('ProductListStyle', '0');
    setProductListStyle(value);
  }, []);

  const searchCodeTypeSelector = () => (
    <Select
      className="select-after"
      defaultValue={searchCodeType}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230 }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style List</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Substyle Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Substyle Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Substyle List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );
  const filterValue = [
    { name: 'SKU', operator: 'contains', type: GRID_FILTER13, value: '' },
    {
      name: 'ProductTitle',
      operator: 'contains',
      type: GRID_FILTER11,
      value: '',
    },
    { name: 'UPC', operator: 'eq', type: GRID_FILTER12, value: '' },
    { name: 'QtyTotal', operator: 'gte', type: GRID_FILTER21, value: null },
  ];
  const gridFilterTypes = Object.assign(
    {
      styleCodeFilter: {
        type: 'string',
        emptyValue: '',
        operators: [
          { name: 'SKU Contains', fn: () => true },
          { name: 'SKU Equals', fn: () => true },
        ],
      },
    },
    filterTypes,
  );

  const saveBasic = async (newValue: string, data: any, isOverwrite = false) => {
    if (!data || !data.productId || !data.previewID || !data.attributeNum) {
      message.warning(`Data error`);
      return;
    }
    if (newValue === data.previewValue) {
      setCurrentValue(undefined);
      setValueModalVisible(false);
      return;
    }
    const overwrite = isOverwrite ? 1 : 0;
    try {
      const productBasic: StringKAnyVPair = {};
      productBasic[data.previewID] = newValue;
      await Products.saveProductAttributes(data.productId, [
        {
          attributeNum: data.attributeNum,
          productAttributeValue: newValue,
        },
      ],
      { params: { isApplyChild: overwrite } }
      );
      setCurrentValue(undefined);
      setValueModalVisible(false);
      message.success(`Saved ${data.previewDisplayName} successfully`);
      const temp = [...filteredData];
      temp[data.rowIndex][data.name] = newValue; // eslint-disable-line no-use-before-define
      setFilteredData(temp);
    } catch (error) { }
  };

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredTempData,
      count: totalCount,
    };
  };

  const closeImageEditor = () => {
    setEditImageDialogVisible(false);
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  const closeSimpleSelectorEditor = () => {
    setEditSelectorDialogVisible(false);
  };

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    const { skip, top } = options;
    setSelected({});

    setIsFetching(true);

    try {
      console.log('options ->', getSearchOptions());
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList(
        typeof skip === 'number' && skip >= 0 ? skip : pageSkip,
        typeof top === 'number' && top >= 0 ? top : pageTop,
        getSearchOptions(),
      );

      if (Array.isArray(ProductList)) {
        if (searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR) {
          ProductList.forEach((e) => {
            if (['Product', 'Bundle'].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        // setSearchTreeMode(isSearchGroupMode(searchCodeType));
        setSearchTreeMode(searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR)


        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      message.error(`Fetch products error: ${e}`);
      console.log('Fetch products error:', e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProductExtData = async (products: StringKAnyVPair[]) => {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      // setExtLoading(true);
      const data = await fetchOperationSet(
        ids,
        new CancelToken(function executor(c) {
          cancel = c;
        }),
        operationSet || 0,
      );
      if (data.length > 0) {
        setStateColumns([
          ...columns,
          ...data[0].mappedPreviewers.map((i: any, index: number) => {
            return {
              name: `ext${index}`,
              header: i.previewDisplayName,
              defaultFlex: 1,
              minWidth: 150,
              render: (value: any) => {
                const { data, rowIndex } = value;
                return (
                  <SetValueCell
                    value={data[`ext${index}`]}
                    valueType={checkStringType(data[`ext${index}`])}
                    editable={data[`editable_ext${index}`]}
                    onButtonClick={() => {
                      const attr = getDataAttrById(i.attributeNum);
                      const opts = getAttrOptions(attr);

                      setCurrentValue({
                        data: data[`ext${index}`],
                        options: opts,
                        previewValue: data[`ext${index}`],
                        previewDisplayName: i.previewDisplayName,
                        previewID: i.previewID,
                        productId: data.ProductId,
                        name: `ext${index}`,
                        attributeNum: i.attributeNum,
                        rowIndex: rowIndex,
                        editable: data[`editable_ext${index}`],
                        sku: data.SKU,
                        productType: data.Type,  
                      });
                      //console.log('attr', attr);
                      if (attr) {
                        if (opts.length > 0) {
                          setEditSelectorDialogVisible(true);
                        } else {
                          switch (attr.attributeDataType) {
                            case ATTRIBUTE_DATA_TYPE_IMAGEURL:
                              setEditImageDialogVisible(true);
                              break;

                            default:
                              setValueModalVisible(true);
                          }
                        }
                      } else {
                        setValueModalVisible(true);
                      }
                    }}
                  />
                );
              },
            };
          }),
        ]);
        data.map((item: any) => {
          if (pDict[item.productId]) {
            item.mappedPreviewers.map((i: any, index: number) => {
              pDict[item.productId][`ext${index}`] = i.previewValue;
              pDict[item.productId][`editable_ext${index}`] = i.editable;
              return true;
            });
          }
          return true;
        });
        // setFilteredData(Object.values(pDict));
      }
      // setExtLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('request canceled..');
      }
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        // console.log('d->', data, products);
        data.forEach((e) => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      message.error(`Fetch partial error: ${e}`);
      console.error('Fetch partial error:', e);
    } finally {
      // setIsFetching(false);
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode
          .split('\n')
          .map((e) => e.trim())
          .filter((e) => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: 'ProductStatus',
      collectionFilterValues: searchStatus,
      op: 2,
    });

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        return 'ColorPatternCode';

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return 'SKU';

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return 'StyleCode';

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return 'UPC';
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  const getSearchGrouper = (/*filters: any[]*/) => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = '';

    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = 'ColorPatternCode';
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = 'Product';
        break;

      case GROUP_OPTION_STYLE:
        groupName = 'StyleCode';
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
  };

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];

    if (
      searchCodeType !== CODE_OPTION_SKU_LIST &&
      searchCodeType !== CODE_OPTION_COLOR_LIST &&
      searchCodeType !== CODE_OPTION_STYLE_LIST &&
      searchCodeType !== CODE_OPTION_UPC_LIST
    ) {
      filters.push({
        filterName: getFilterNameBySearchCodeType(),
        filterValue: searchCode,
        op: getOperateCodeBySearchCodeType(),
      });
    }

    if (searchTitle) {
      filters.push({
        filterName: 'ProductTitle',
        filterValue: searchTitle,
        op: OPERATION_CONTAIN,
      });
    }

    if (searchCCFs.length > 0) {
      filters.push({
        filterName: 'ChannelControlFlag',
        filterValue: searchCCFs.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if (searchCFs.length > 0) {
      filters.push({
        filterName: 'Classification',
        filterValue: searchCFs.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        filterName: 'Labels',
        filterValue: searchLabels.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      filters.push({
        filterName: 'Brand',
        filterValue: searchBrand,
        op: OPERATION_CONTAIN,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(' ');

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const handleSearchProducts = () => {
    if (!operationSet) {
      Modal.error({
        title: 'Operation Set is required!',
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk: () => {
          onSearchbarFocus();
        }
      });
      return;
    };
    setInited(true);
    fetchProductList();
  };

  /*useEffect(() => {
    console.log('filterPanelVisible = ', filterPanelVisible);
  }, [filterPanelVisible])*/

  // const isColorCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_COLOR_CONTAINS, CODE_OPTION_COLOR_EQUALS, CODE_OPTION_COLOR_LIST].indexOf(code) >
  //     -1
  //   );
  // };

  // const isCommonGroupOption = (code: number) => {
  //   return isColorCode(code) || isStyleCode(code);
  // };

  // const isSearchGroupMode = (code: number) => {
  //   return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  // };

  // const isSKUCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_SKU_CONTAINS, CODE_OPTION_SKU_EQUALS, CODE_OPTION_SKU_LIST].indexOf(code) > -1
  //   );
  // };

  // const isStyleCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_STYLE_CONTAINS, CODE_OPTION_STYLE_EQUALS, CODE_OPTION_STYLE_LIST].indexOf(code) >
  //     -1
  //   );
  // };

  const openProductDetailDialog = (productId: string, product: StringKAnyVPair) => {
    setDetailVisible(true);
    console.log('pid->', productId);
    setCurrentProduct(product);
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef = [...stateColumns];
    const tempColumns: any[] = [];

    for (let i = 0; i < colDef.length; i++) {
      if (productListStyle === '2') {
        if (['Style', 'Substyle'].includes(colDef[i].header)) {
          continue;
        }
      }
      if (colDef[i].header === 'Image') {
        colDef[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || 'https://via.placeholder.com/300';
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
              <Image width={28} height={28} src={src} />
            </ImageContainer>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      } else if (colDef[i].header === 'SKU') {
        colDef[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split('/');
          const productId = pids[pids.length - 1] || '-';

          return (
            <ERPLinkTableCell value={data.SKU} type={3} onClick={()=> openProductDetailDialog(`${productId}`, data)} />
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      }
      tempColumns.push(colDef[i]);
    }

    return tempColumns;
  };

  const fetchChildrenPartialData = async (
    node: StringKAnyVPair,
    ProductList: Entities.ProductProfile[],
  ) => {
    await fetchProductParticalData(ProductList);
    await fetchProductExtData(ProductList);
    node.nodes = ProductList;
    //console.log('fd', filteredData);
    updateGridRow(node as Entities.ProductProfile);
  };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (Array.isArray(attr.options)) {
        attr.options
          //.split("|")
          //.map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const getDataAttrById = (id: number) => {
    const fs = eleAttrs.filter((e) => e.attributeNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const loadAttributesByOperationSetNum = async (osn: number) => {
    try {
      const als = await fetchAvailableAttributesPlus(osn);

      if (als && Array.isArray(als)) {
        setEleAttrs(als);
      }
    } catch (e) {
      notification.error({
        message: `Load attribute data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const loadNextLevelProducts = async (data: any) => {
    //console.log('--->', data);
    const { node } = data;
    let ret: any = null;

    //setIsFetching(true);
    try {
      const { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        // if (ProductList.length > 0) {
        //   await fetchProductParticalData(ProductList);
        //   await fetchProductExtData(ProductList);

        // }
        if (ProductList.length > 0) {
          await fetchChildrenPartialData(node, ProductList);
        }

        ret = ProductList;
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const onFilterValueChange = (val: any) => {
    // console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    // console.log('limit -->', limit);
    fetchProductList({ top: limit });
    setPageTop(limit);
  };

  const onOperationSetChange = (osn: SelectValue) => {
    setOperationSet(osn);
    if (osn) {
      loadAttributesByOperationSetNum(osn as number);
    }
  };

  const onSearchCodeChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (
      value === CODE_OPTION_SKU_LIST ||
      value === CODE_OPTION_COLOR_LIST ||
      value === CODE_OPTION_STYLE_LIST ||
      value === CODE_OPTION_UPC_LIST
    ) {
      setSearchMultiCode('');
    }
    codeInputRef?.current?.select();
    codeInputRef?.current?.focus();
  };

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected(selected);
    if (selected === true && !unselected) {
      setSelectedRows(data as any);
    } else {
      if (!unselected) {
        setSelectedRows(Object.values(selected as any));
      } else {
        setSelectedRows((prev) => {
          return prev.filter((item) => item.ProductId !== (data as any).id);
        });
      }
    }
  }, []);

  const onSkipChange = (skip: number) => {
    // console.log('skip -->', skip);
    fetchProductList({ skip });
    setPageSkip(skip);
  };

  const onSortChange = async (info: any) => {
    // console.log('-->', info);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    //console.log('p ->', data, param);
    return <GroupDetail row={data} />;
  };

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  const loadTemplates = useCallback(async () => {
    try {
      const operationSets: any[] = (await fetchProductAttributeSets({})) || [];
      const operationSelect = operationSets
        .filter((item) => item.attributeSetName)
        .map((item: any) => ({ label: item.attributeSetName, value: item.rowNum }));
      setOperationSelectList(operationSelect);
      setTemplateLoading(false);
    } catch (error) { }
  }, []);

  // todo

  // eslint-disable-next-line
  const onSelectClassifications = (values: any[]) => {
    setSearchCFs(values);
  };

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (res && typeof res === 'object' && Array.isArray(res.LabelList) && res.LabelList.length > 0) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: (new Date()).getTime(),
    };
    setFilterPanelVisible(true);
  };

  const onSearchbarFocus = () => {
    const ctn = getCodeInputWrapperDom();

    if (ctn) {
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      setFilterPanelOutline({ x: rect.x, y: rect.y + rect.height, width: rect.width });

      //if (!filterPanelVisible) {
      showSearchFilterPanel();
      //}
    }
  };

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  const clearSearchBrand = () => {
    setSearchBrand('');
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle('');
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const showSearchConditionTags = () => {
    const ccfLabels: string[] = ccfList
      .filter(c => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map(c => c.CHNLCtrlFlag);
    const labels: string[] = labelList.filter(l => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map(l => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter(e => searchStatus.indexOf(e.code) > -1)
      .map(e => e.description);

    return (
      <Space>
        <TableFilter columns={gridColumns(searchTreeMode)} dataSource={filteredData} setFilteredData={setFilteredTempData}   />       
        {searchTitle.trim() && (
          <Tag
            closable
            onClose={clearSearchTitle}
          >
            <span className="field-label">Product Name</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag
            closable
            onClose={() => setSearchStatus([])}
          >
            <span className="field-label">Status</span>
            {statusLabels.join(', ')}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(', ')}
          </Tag>
        )}
        {searchBrand.trim() && (
          <Tag
            closable
            onClose={clearSearchBrand}
          >
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Tags</span>
            {labels.join(', ')}
          </Tag>
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
        <Tag>
          <span className="field-label">Operation Set</span>
          {operationSelectList.find(os => os.value === operationSet).label || ''}
        </Tag>
      </Space>
    );
  };

  const OperationSetSelector = () => {
    return (
      <>
        <FormLabel>Operation Set <i style={{ color: 'red' }}>(required)</i></FormLabel>
        <Select
          style={{ minWidth: '150px ' }}
          options={operationSelectList}
          placeholder="Operation Set"
          onChange={onOperationSetChange}
          value={operationSet}
        />
      </>
    )
  }

  const operationFilters = () => {
    return [
      '-',
      <OperationSetSelector />,
    ];
  };

  useEffect(() => {
    getProductListStyle();
    loadTemplates();
    fetchChannelControlFlags();
    fetchLabels();
    // eslint-disable-next-line
  }, [])

  return templateLoading ? (
    <ContentLayout>
      <Heading title="Operation Sets" />
      <Spacer />
      <SiteContent flexGrow>
        <Spin />
      </SiteContent>
    </ContentLayout>
  ) : operationSelectList.length ? (<>
    <ContentLayout>
      <Heading
        title="Operation Sets"
        actions={
          <Link to="/settings-products-attribute-sets">
            <Button>Manage</Button>
          </Link>
        }
      />
      <Spacer />
      <SiteContent noPadding flexGrow transparent>
        <Row className="content-section" justify="space-between">
          <SearchCol>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row className="search-element-area">
                <Row>
                  {productListStyle !== "2" && (
                    <Row align="middle" className="display-category-field">
                      <span className="field-label" style={{ paddingRight: 0 }}>Display</span>
                      <Radio.Group
                        onChange={onGroupModeChange}
                        //style={{ width: 430 }}
                        value={searchGroup}
                      >
                        <Radio
                          // disabled={!isStyleCode(searchCodeType)}
                          value={GROUP_OPTION_STYLE}
                        >
                          Style
                        </Radio>
                        {
                          productListStyle !== '1' && (
                            <Radio
                              // disabled={!isCommonGroupOption(searchCodeType)}
                              value={GROUP_OPTION_COLOR}
                            >
                              Substyle
                            </Radio>
                          )
                        }
                        <Radio
                          // disabled={!isCommonGroupOption(searchCodeType)}
                          value={GROUP_OPTION_PRODUCT}
                        >
                          Product
                        </Radio>
                        <Radio
                          // disabled={!isCommonGroupOption(searchCodeType)}
                          value={GROUP_OPTION_NO_GROUP}
                        >
                          All
                        </Radio>
                      </Radio.Group>
                    </Row>
                  )
                  }
                  {isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //disabled
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        readOnly
                        ref={codeInputRef}
                        value={searchMultiCode.replace(/\n/g, ', ')}
                      />
                    </StyleInputWrapper>
                  )}
                  {!isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        ref={codeInputRef}
                      />
                    </StyleInputWrapper>
                  )}
                </Row>
                <div className="action-btn-wrap">
                  <Space>
                    {totalCount > 0 && (
                      <Button disabled={isFetching} onClick={() => setExportModalVisible(true)}>
                        <CloudDownloadOutlined />
                        Export
                      </Button>
                    )}
                  </Space>
                </div>
              </Row>
              <Row align="middle" className="search-btn-row" justify="space-between" style={{ marginTop: inited ? 6 : 0 }}>
                {searchBegun && showSearchConditionTags()}
              </Row>
            </Space>
          </SearchCol>
        </Row>
        {inited ? <Spacer height={14} /> : SearchTips(680, 300, 'Input your search criteria', 130)}
        {
          inited && (
            <Card size="small" style={{ flexGrow: 1 }} bodyStyle={{ height: '100%' }}>
              <DataGrid
                style={{ height: '100%' }}
                idProperty="ProductId"
                rowHeight={35}
                columns={gridColumns(searchTreeMode)}
                dataSource={dataSource}
                defaultFilterValue={filterValue}
                defaultLimit={DEFAULT_GRID_LIMIT}
                enableFiltering={false}
                filterTypes={gridFilterTypes}
                limit={pageTop}
                loadNode={loadNextLevelProducts}
                onNodeExpand={(arg:any) => {
                  const { index } = arg
                  const temp = [...filteredTempData]
                  temp[index] = {...temp[index], nodes: null}
                  setFilteredTempData([...temp])
                }}
                loading={isFetching}
                onFilterValueChange={onFilterValueChange}
                onLimitChange={onLimitChange}
                onSelectionChange={onSelectionChange}
                onSkipChange={onSkipChange}
                onSortInfoChange={onSortChange}
                pageSizes={[20, 30, 50, 100, 200]}
                pagination
                checkboxOnlyRowSelect
                renderRowDetails={renderRowDetails}
                rowExpandColumn={searchGroupMode}
                rowExpandHeight={300}
                scrollProps={{ autoHide: false, }}
                // selected={selectedRows}
                skip={pageSkip}
                sortable={false}
                treeColumn={searchTreeMode ? 'mediaURL' : undefined}
                checkboxColumn
                selected={selected}
              />
            </Card>
          )
        }
      </SiteContent >
      <SearchFilterPanel
        channelFlagVersion={channelFlagVersion}
        clearChannelFlags={clearChannelFlags}
        clearLabels={clearLabels}
        clearSearchBrand={clearSearchBrand}
        clearSearchTitle={clearSearchTitle}
        handleSearch={handleSearchProducts}
        hidePanel={hideSearchFilterPanel}
        labelVersion={labelVersion}
        onMouseEnter={onMouseEnterFilter}
        onMouseLeave={onMouseLeaveFilter}
        searchBrandVersion={searchBrandVersion}
        searchCodeType={searchCodeType}
        searchMultiCode={searchMultiCode}
        searchTitleVersion={searchTitleVersion}
        setBrand={setSearchBrand}
        setLabels={setSearchLabels}
        setMultiCode={setSearchMultiCode}
        setSalesChannel={setSearchCCFs}
        setSortType={setSearchSorter}
        setStatus={setSearchStatus}
        setTitle={setSearchTitle}
        showPanel={showSearchFilterPanel}
        status={searchStatus}
        statusOptions={getOperationStatusOptions().map(e => ({ label: e.description, value: e.code }))}
        x={filterPanelOutline.x || 0}
        y={filterPanelOutline.y || 0}
        visible={filterPanelVisible}
        width={filterPanelOutline.width || 0}
        ExtraFilter={operationFilters()}
      />
      {
        detailVisible && (
          <ProductDetailDialog
            onClose={closeProductDetailDialog}
            product={currentProduct}
            visible={detailVisible}
          />
        )
      }

      {
        exportModalVisible && (
          <ExportMdal
            templateInfo={templateInfo}
            fileName="OperationSets"
            filterArr={
              searchGroup === 2
                ? ['Image', 'Substyle', 'SKU', 'Status']
                : searchGroup === 1
                  ? ['Image', 'SKU', 'Status']
                  : searchGroup === 4
                    ? ['Image', 'Style', 'Substyle', 'Status']
                    : ['Image', 'Status']
            }
            getSearchOptions={getSearchOptions}
            onClose={() => setExportModalVisible(false)}
            data={filteredData.filter((i) => i.ProductId.indexOf('/') < 0)}
            selectedRows={selectedRows.filter((i) => i.ProductId.indexOf('/') < 0)}
            columns={gridColumns(searchTreeMode)}
            operationSet={Number(operationSet)}
          />
        )
      }

      {
        valueModalVisible && currentValue && (
          <Editer
            productId={currentValue.productId}
            editable={currentValue.editable}
            onClose={() => setValueModalVisible(false)}
            onSave={(newValue: string) => {
              saveBasic(newValue, currentValue);
            }}
            textContent={currentValue.previewValue}
            title={`${currentValue.previewDisplayName}`}
            readonly={!currentValue.editable}
            visible
          />
        )
      }
    </ContentLayout >
    {editSelectorDialogVisible && currentValue && (
      <SimpleSelectorModal
        onClose={closeSimpleSelectorEditor}
        onSave={(newValue: string, /*overwrite: boolean*/) => {
          saveBasic(newValue, currentValue/*, overwrite*/);
        }}
        options={currentValue.options || []}
        title={`${currentValue.previewDisplayName}`}
        value={currentValue.previewValue}
        visible={editSelectorDialogVisible}
        productId={currentValue.productId}
        productType={currentValue.productType}
      />
    )}
    {
      editImageDialogVisible && currentValue && (
        <EditImageDialog
          imageUrl={currentValue.previewValue}
          onClose={closeImageEditor}
          //onSave={saveAttributeByEditor}
          onSave={(newValue: string, /*overwrite: boolean*/) => {
            saveBasic(newValue, currentValue/*, overwrite*/);
          }}
          title={`${currentValue.previewDisplayName}`}
          visible={editImageDialogVisible}
          overwriteEnable={currentValue.productType !== "Product"}
          overwrite={overwriteChild}
        />
      )
    }
  </>) : (
    <ContentLayout>
      <Heading title="Operation Sets" />
      <Spacer />
      <SiteContent flexGrow>
        <Text strong style={{ fontSize: 16, margin: 20 }}>
          There is no operation set,{' '}
          <Link to="/settings-products-attribute-sets">
            <Button type="primary" style={{ marginLeft: 4, marginRight: 4 }}>
              Click Here
            </Button>
          </Link>{' '}
          to add one.
        </Text>
      </SiteContent>
    </ContentLayout>
  );
};

export default PageContent;

import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/ContentLayout";
import Heading from "../../components/common/Heading";
import Spacer from "../../components/common/Spacer";
import { Col, Row, Card, Typography, Space, Spin } from "antd";
import SiteContent from "../../components/SiteContent";
import { useHistory, useLocation } from "react-router-dom";
import menuConfig from './config'


const PagePreMenu = () => {
  const [pageMenuData, setPageMenuData] = useState<any>()
  const hash = useLocation();
  console.log(hash)
  const searchParams = new URLSearchParams(hash.search);
  const q = searchParams.get('q')
  const history = useHistory()

console.log(q)
  useEffect(()=>{
    if(q && menuConfig[q]){
      setPageMenuData(menuConfig[q])
    }
  },[q])

  const handleClick = (key:string) => {
    history.push(hash.pathname === '/pim/entry'? pageMenuData.pimUrl: pageMenuData.ccUrl,  { id: Number(key) })
  };

  const renderItemChild = (item: any) => {
    return (
      <div key={item.key} style={{paddingLeft: 8}}>
        <Row>
            <Typography.Link
              style={{ fontSize: 14 }}
              strong
              onClick={(e) => {
                handleClick(item.key)
                e.preventDefault();
                e.stopPropagation()
              }}
            >
              {item.title}
            </Typography.Link>
          
        </Row>
        {item.description ? (
          <Typography.Text >{item.description}</Typography.Text>
        ) : null}
      </div>
    );
  };

  const renderItem = (item: any) => {
    return (
      
      <Card
        hoverable
        style={{ width: "100%", marginBottom: 16 }}
        bodyStyle={{paddingTop: 8, paddingBottom: 8}}
        key={item.key}
        onClick={() => {
          handleClick(item.key);
        }}
      >
        <Row>
          <Space>
            <Typography.Text style={{ color: "#335FE2", fontSize: 18 }}>
              {item.icon}
            </Typography.Text>
            <Typography.Link style={{color: "#335FE2", fontSize: 18 }} strong>
              {item.title}
            </Typography.Link>
          </Space>
        </Row>
        {item.description ? (
          <Typography.Text >{item.description}</Typography.Text>
        ) : null}
        {item.children && item.children.length > 0
          ? item.children.map((i: any) => renderItemChild(i))
          : null}
      </Card>
    );
  };

  return pageMenuData ?(
    <ContentLayout>
      <Heading title={pageMenuData?.title} />
      <Spacer />
      <SiteContent style={{ padding: "24px" }}>
        <Row gutter={32}>
          <Col span={12} style={{ height: "100%" }}>
            {pageMenuData.leftList.map((i:any) => renderItem(i))}
          </Col>
          <Col span={12}>{pageMenuData.rightList.map((i:any) => renderItem(i))}</Col>
        </Row>
      </SiteContent>
    </ContentLayout>
  ) : <ContentLayout><Spin spinning/></ContentLayout>;
};

export default PagePreMenu;

import { Select, SelectProps } from 'antd';
import React, { useMemo } from 'react';
import { useGetAvailableMappingsByChannelNumQuery } from '../../redux/api/channelIntegration';
import { enumNameToLabel } from '../../util/strings';
import { ChannelAccountTemplateMappingType } from '../../types/enums';

const initialData: Entities.MappingContentProfile[] = [];

interface MappingTemplateSelectProps extends SelectProps<number> {
  channel: number;
  channelAccount: number;
  productMappingNum: number;
  defaultProductMappingNum: number;
  applyTemplate: any;
  defaultSelectMappingType?: number;
  noValue?: boolean
}

const MappingTemplateSelect: React.FC<MappingTemplateSelectProps> = ({ channel, channelAccount, productMappingNum, defaultProductMappingNum, applyTemplate, defaultSelectMappingType, noValue, ...selectProps }) => {
  const { data = initialData, isFetching } = useGetAvailableMappingsByChannelNumQuery({channelNum: channel});
  const filteredMappings = useMemo(
    () => data.filter(m => m.ChannelNum === channel && m.ChannelAccountNum === channelAccount),
    [data, channel, channelAccount],
  );

  if ((selectProps?.value === 0 || selectProps.value === undefined) && filteredMappings.length > 0) {
    if(defaultProductMappingNum && filteredMappings.findIndex(i => i.ProductMappingNum === defaultProductMappingNum) > -1 && productMappingNum === 0){
      applyTemplate(defaultProductMappingNum)
    } else {
      if(defaultSelectMappingType) {
        const temp = filteredMappings.filter(i=> i.MappingTypeNum === defaultSelectMappingType)
        if(temp.length > 0) {
          applyTemplate(temp[0].ProductMappingNum, {children: temp[0].Name || enumNameToLabel(ChannelAccountTemplateMappingType[temp[0].MappingTypeNum])});
        } else {
          applyTemplate(0);
        }
      }
      //console.log('s4', noValue);
      noValue ? applyTemplate(undefined) : applyTemplate(filteredMappings[0].ProductMappingNum, {children: filteredMappings[0].Name || enumNameToLabel(ChannelAccountTemplateMappingType[filteredMappings[0].MappingTypeNum])});
    }
  }

  return (
    <Select
      // style={{ minWidth: '200px' }}
      onChange={applyTemplate}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...selectProps}
      allowClear
      loading={isFetching}
      disabled={defaultProductMappingNum > 0 && filteredMappings.findIndex(i => i.ProductMappingNum === defaultProductMappingNum) > -1 }
    >
      {
        filteredMappings.length === 0 && (
          <Select.Option key={0} value={0}>
            &nbsp;
          </Select.Option>
        )
      }
      {
        filteredMappings
          .map(m => (
            <Select.Option
              key={m.MappingContentID}
              value={m.ProductMappingNum}
            >
              {m.Name || enumNameToLabel(ChannelAccountTemplateMappingType[m.MappingTypeNum])}
            </Select.Option>
          ))
      }
    </Select>
  );
};

export default MappingTemplateSelect;

import { Button, Input, Modal, Row } from 'antd';
import styled from 'styled-components';
import theme from '../../assets/styles/theme.js';

export const CopywritingStepWrapper = styled.div`
  height: 100%;

  & .ant-table-cell {
    padding: 8px;
  }

  & .attr-cell {
    display: inline-block;
    width: 50%;
    padding: 4px;
  }

  & .attr-cell.label {
    width: 200px;
  }

  & .attr-label {
    display: inline-block;
    padding-right: 8px;
    text-align: right;
    width: 50%;
  }

  & .attr-label.full-width {
    text-align: left;
    width: 100%;
  }

  & .attr-label .ant-typography {
    margin-left: 8px;
    max-width: calc(100% - 34px);
  }

  & .attr-editor-wrapper {
    display: inline-block;
    width: 50%;
  }

  & .danger-color {
    color: ${theme['@danger-color']};
  }

  & .dest-step-wrapper {
    height: 100%;
    overflow-y: auto;
  }

  & .form-label {
    font-size: 14px;
    font-weight: 550;
  }

  & .left-bottom-btn-block {
    margin-left: 270px;
  }

  & .primary-text-btn {
    color: ${theme['@primary-color']};
  }

  & .primary-text-btn:disabled {
    color: #666;
  }

  & .section {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    margin-top: 20px;
  }

  & .section.top {
    margin-top: 0;
  }

  & .section-bar {
    padding: 6px 12px;
  }

  & .section-bar .section-title {
    font-size: 16px;
    font-weight: 600;
  }

  & .space-line {
    margin-top: 20px;
  }

  & .space-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  & .step-ctn {
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: calc(100% - 96px);
    margin-bottom: 12px;
  }

  & .step-ctn.bulk {
    height: calc(100% - 46px);
  }

  & .step-ctn .step-board {
    display: inline-block;
    height: 100%;
    padding: 12px;
    width: calc(100% - 272px);
  }

  & .step-ctn .step-sider {
    border-right: 1px solid #D9D9D9;
    display: inline-block;
    height: 100%;
    padding: 36px;
    width: 270px;
  }

  & .step-wrapper {
    display: flex;
    height: 100%;
  }

  & .title-bar {
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-bottom: none;
    border-radius: ${theme['@border-radius-base']};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 12px;
  }

  & .title-item {
    color: #666;
    font-size: 16px;
    font-weight: 550;
  }

  & .title-item-label {
    font-size: 16px;
    font-weight: 600;
  }

  & .title-item-label::after {
    content: ':';
  }
`;

export const CopywritingWrapper = styled(Row)`
  padding-left: 8px;

  & .attr-cell {
    display: inline-block;
    width: 50%;
    padding: 4px;
  }

  & .attr-label {
    display: inline-block;
    padding-right: 8px;
    text-align: right;
    width: 50%;
  }

  & .attr-label .ant-typography {
    max-width: calc(100% - 16px);
  }

  & .attr-editor-wrapper {
    display: inline-block;
    width: 50%;
  }

  & .form-ctn {
    border: 1px solid #D9D9D9;
    /*width: calc(100% - 233px);*/
    width: calc(100% - 200px);
    overflow-y: auto;
    padding: 12px;
  }

  & .form-ctn.shrink {
    width: calc(100% - 401px);
  }

  & .image-ctn {
    border: 1px solid #D9D9D9;
    width: 32px;
  }

  & .image-ctn.expand {
    width: 200px;
  }

  & .image-panel-ctn {
    border-top: 1px solid #D8D8D8;
    height: calc(100% - 30px);
    overflow-y: auto;
  }

  & .left-expand-icon {
    cursor: pointer;
    height: 32px;
    width: 32px;
  }

  & .left-expand-icon .anticon {
    padding: 8px;
  }

  & .left-sider {
    padding-right: 8px;
    width: 200px;
  }

  & .left-sider-bar {
    width: 100%;
  }

  & .view-filter-card.custom-view {
    border-color: ${theme['@info-color']};
  }

  & .view-filter-card .ant-card-body {
    padding: 8px;
  }
`;

export const DetailContainer = styled(Row)`
  & .ant-tabs-nav {
    width: calc(100% - 56px);
  }

  & .detail-tabs {
    /*border: 1px solid #D9D9D9;*/
    overflow-y: auto;
    width: 100%;
    /*padding: 12px;*/
  }

  & .detail-tabs.shrink {
    width: calc(100% - 200px);
  }

  & .detail-tabs.shrink .ant-tabs-nav {
    width: calc(100% - 12px);
  }

  & .image-ctn {
    /*border: 1px solid #D9D9D9;*/
    position: fixed;
    width: 2px;
  }

  & .image-ctn.expand {
    width: 200px;
  }

  & .image-ctn-resizer {
    width: 2px;
  }

  & .image-ctn-resizer:hover {
    background-color: ${theme['@danger-color']};
    cursor: col-resize;
  }

  & .image-ctn-row {
    height: 100%;
  }

  & .image-panel-ctn {
    border-top: 1px solid #D8D8D8;
    height: calc(100% - 40px);
    overflow-y: auto;
  }

  & .image-panel-header {
    background-color: #ECECEC;
    border-top-left-radius: ${theme['@border-radius-base']};
    border-top-right-radius: ${theme['@border-radius-base']};
  }

  & .picture-left,
  & .left-caret {
    padding-right: 0 !important;
  }

  & .picture-right,
  & .right-caret {
    padding-left: 0 !important;
  }

  & .left-caret {
    margin-right: -2px;
    padding-left: 4px !important;
  }

  & .right-caret {
    margin-left: -2px;
  }

  & .left-expand-icon {
    background-color: #ECECEC;
    /*border-radius: ${theme['@border-radius-base']};*/
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 4px 3px 1px rgba(0, 0, 0, 0.2);
    color: ${theme['@primary-color']};
    cursor: pointer;
    display: flex;
    height: 32px;
    /*width: 32px;*/
    margin: 4px 0;
    padding: 2px 1px;
  }

  & .left-expand-icon .anticon {
    padding: 8px;
  }

  & .image-ctn.expand .left-expand-icon {
    box-shadow: none;
  }
`;

export const FormPlaceholder = styled.span`
  display: inline-block;
  min-height: 32px;
`;

export const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 `;

export const ProductTabContainer = styled.div`
  width: 100%;
`;

export const ClearButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  float: right;
`;

export const EditControls = styled.div`
 width: 100%;
 display: flex;
 justify-content: flex-end;
 align-items: flex-end;
`;

export const TopHeaderButtonsWrapper = styled.div`
display: flex;
`;

export const TableButtonWrapper = styled.div`
  cursor: pointer;
  float: left;
`;

export const ProductSKU = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

export const ProductTitle = styled.p`
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-weight: 900;
  color: #2d3f5d;
`;

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TitlePlaceholderWrapper = styled.div`
  font-size: 25px;
  white-space: nowrap;
`;

export const TitleInput = styled(Input)`
  font-size: 25px;
  width: 100%;
  padding: 0 !important;
`;

export const AccListWrapper = styled.div``;

export const MasterCodesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;

  & .ant-card {
    max-height: 60vh;
    overflow-y: auto;
  }
`;

export const ListItem = styled.span`
	letter-spacing: 0.035rem;
`;

export const SortableListItem = styled.li`
  align-items: center;
  color: #2e2e2e;
  cursor: grab;
  display: flex;
  height: 2rem;
  justify-content: center;
  white-space: nowrap;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  padding: 6px;
  margin-right: 10px;
`;

export const SortableListContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  overflow-x: auto;
  padding: 0;
  user-select: none;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`;

export const SKUFormatterTitle = styled.h3`
  margin-right: 40px;
  align-items: center;
  color: #546279;
  cursor: col-resize;
  display: flex;
  height: 2rem;
  justify-content: center;
  white-space: nowrap;
`;

export const StepsActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const StepsContent = styled.div`
  margin-top: 24px;
`;

export const StepContentWrapper = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? '' : 'none')};
`;

export const EditorModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px 24px 24px;
  }

  & .modal-body-wrapper2 {
    margin-top: 24px;
  }
`;

export const EditorWrapper = styled.span`
  display: inline-block;
  width: 100%;

  &:hover .icon-ctn-disabled {
    display: inline-block;
  }

  &:hover .visual-ctn,
  &:hover .visual-readonly-ctn {
    width: calc(100% - 32px);
  }

  &:hover .visual-ctn,
  &:hover .visual-ctn .ant-select-selector {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:hover .visual-ctn2 {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 64px);
  }

  &:hover .visual-readonly-ctn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & textarea {
    white-space: normal;
    /*resize: none;*/
    overflow-y: auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  & .ant-input-group:hover .ant-select-disabled .ant-select-selector {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & .ant-select-disabled .ant-select-arrow {
    display: none;
  }

  & .cell-disabled {
    background-color: #F5F5F5;
  }

  & .cell-value,
    .cell-value2,
    .icon-ctn-disabled,
    .icon-ctn-enabled {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .cell-value,
    .cell-value2 {
    padding: 4px 11px;
  }

  & .cell-value,
    .common-sel-box {
    width: calc(100% - 32px);
  }

  & .common-sel-box .ant-select-selector {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & .common-sel-box .ant-select-disabled .ant-select-selector {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  & .common-sel-editor {
    width: 100%;
  }

  & .cell-value2 {
    width: calc(100% - 64px);
  }

  & textarea.cell-value {
    overflow-y: auto;
    white-space: normal;
  }

  & .icon-ctn-disabled,
    .icon-ctn-enabled {
    border-radius: 0;
    cursor: pointer;
    /*padding: 2px 3px;*/
    padding: 5px 7px;
    width: 32px;
  }

  & .icon-ctn-disabled {
    display: none;
  }

  & .icon-ctn-enabled {
    background-color: #FFF;
  }

  & .icon-ctn-disabled img,
    .icon-ctn-enabled img {
    width: 24px;
    height: 24px;
  }

  & .sale-chn-content-wrap {
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    min-height: 32px;
    width: 100%;
  }

  & .sale-chn-content-wrap.editable,
  & .sale-chn-content-wrap.icon-1 {
    width: calc(100% - 32px);
  }

  & .sale-chn-content-wrap.editable {
    background-color: #FFF;
  }

  & .sale-chn-content-wrap .ant-tag {
    margin: 2px 2px;
  }

  & .selector-adj-cell {
    border: 1px solid #D9D9D9;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding-left: 8px;
  }

  & .selector-column-one {
    width: 100%;
  }

  & .selector-column-one.space-32 {
    width: calc(100% - 32px);
  }

  & .selector-column-three {
    width: 34%;
  }

  & .selector-column-three.space-32 {
    width: calc(34% - 32px);
  }

  & .selector-column-two {
    width: 50%;
  }

  & .selector-column-two.space-32 {
    width: calc(50% - 32px);
  }

  & .selector-row {
    display: flex;
  }

  & .visual-ctn,
  & .visual-ctn0,
  & .visual-ctn2,
  & .visual-readonly-ctn {
    width: 100%;
  }

  & .selector-row > *:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const ExistentStyleMasterItemsWrapper = styled.div`
  width: 100%;
`;

export const FilterSection = styled.div`
  border: 1px solid #D9D9d9;
  border-radius: ${theme['@border-radius-base']}; 
  padding: 16px;
`;

export const ImageEditorWrapper = styled(Row)`
  & .img-col {
    height: 382px;
  }

  & .img-ctn {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    height: 100%;
    overflow-y: auto;
  }

  & .img-outlook {
    max-height: 380px;
    object-fit: cover;
  }

  & .url-col {
    padding: 0 6px;
  }
`;

export const SalesChannelGridWrapper = styled.div`
  & .cell-right-icon {
    margin-right: 10px;
  }
`;

export const SectionTitle = styled.span`
  font-weight: 600;
`;

export const SelectorDropdownWrapper = styled.div`
  & .option-header-ctn {
    border-right: 1px solid #D9D9D9;
    font-size: 16px;
    font-weight: 550;
    padding: 4px 0;
  }
`;

export const TabPanelContainer = styled.div`
  & hr {
    background-color: #80CBC4;
    border: none;
    height: 1px;
  }

  & .cell-box {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    display: inline-block;
    height: 32px;
    width: 100%;
  }

  & .cell-disabled2 {
    background-color: #F5F5F5;
    padding: 8px 12px !important;
  }

  & .cell-value {
    overflow: hidden;
    padding: 4px 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .filter-inputbar .ant-input-group-addon {
    display: none;
  }

  & .filter-inputbar .ant-input-affix-wrapper {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  & .form-field-area-1 {
    width: calc(100% - 312px);
  }

  & .form-label {
    display: inline-block;
    margin-right: 8px;
    text-align: right;
  }

  & .form-label.label-0 {
    text-align: left;
    width: 100%;
  }

  & .form-label.label-1 {
    width: 130px;
  }

  & .form-label.label-2 {
    width: 180px;
  }

  & .form-label.label-3 {
    width: 80px;
  }

  & .form-label.label-4 {
    width: 190px;
  }

  & .form-label.label-5 {
    width: 160px;
  }

  & .form-label.label-6 {
    width: 150px;
  }

  & .form-label.required-1 {
    width: 70px;
  }
  & .form-label.required-1-1 {
    width: 70px;
  }

  & .form-label.required-2 {
    width: 180px;
  }

  & .form-label.required-1::before,
    .form-label.required-2::before {
    color: ${theme['@danger-color']};
    content: '* ';
  }

  & .form-input-wrapper {
    display: inline-block;
    line-height: 1em;
  }

  & .form-input-wrapper.input-0 {
    width: 100%;
  }

  & .form-input-wrapper.input-1 {
    width: calc(100% - 140px);
  }

  & .form-input-wrapper.input-2 {
    width: calc(100% - 80px);
  }

  & .form-input-wrapper.input-3 {
    width: 300px;
  }

  & .form-input-wrapper.input-4 {
    width: calc(100% - 190px);
  }

  & .form-input-wrapper.input-5 {
    width: calc(100% - 90px);
  }

  & .form-input-wrapper.input-6 {
    width: calc(100% - 200px);
  }

  & .form-input-wrapper.input-7 {
    width: calc(100% - 170px);
  }

  & .form-input-wrapper.input-8 {
    width: calc(100% - 160px);
  }

  & .form-item {
    display: inline-block;
    padding: 4px;
  }

  & .form-item.item-1 {
    width: 50%;
  }

  & .form-item.item-2 {
    width: 33%;
  }

  & .form-item.item-3 {
    width: 25%;
  }

  & .form-section {
    /*background-color: #F5F6F8;*/
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']}; 
    padding: 8px;
  }

  & .full-width {
    width: 100%;
  }

  & .full-width.box-ctn-1 {
    max-width: 700px;
  }

  & .full-width.box-ctn-2 {
    /*max-width: 1000px;*/
  }

  & .img-area {
    background-color: #FFF;
    border-radius: ${theme['@border-radius-base']}; 
    height: 100%;
  }

  & .img-area-toolbar {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & .img-area-toolbar .ant-btn {
    padding: 5px 8px;
  }

  & .prd-main-img-wrapper {
    /*border: 1px solid #D9D9D9;*/
    border-radius: ${theme['@border-radius-base']}; 
    height: 318px;
    max-height: 330px;
    width: 300px;
    position: relative;
  }

  & .prd-main-img-wrapper img {
    max-height: 100%;
    object-fit: contain;
  }

  & .prd-main-img-wrapper .basic-search-toolbar {
    margin-bottom: 8px;
    width: 100%;
  }

  & .prd-main-img-wrapper .img-area-toolbar {
    display: none;
  }

  & .prd-main-img-wrapper:hover .img-area-toolbar {
    display: flex;
  }

  & .section-title {
    color: #444;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const TextEditorBody = styled(Row)`
  textarea {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    resize: none;
  }

  & .gen-text-central-col {
    width: 120px;
  }

  & .gen-text-ctn-col {
    width: calc(50% - 60px);
  }

  & .gen-text-section {
    margin: 8px 0;
  }

  & .gen-text-section.card {
    border: 1px solid #D9D9D9;
    //border-bottom-left-radius: 10px !important;
    //border-bottom-right-radius: 10px !important;
    border-radius: 10px;
    margin-bottom: 0;
  }

  & .gen-text-section .header-bar {
    padding: 8px;
  }

  & .gen-text-section .header-bar .anticon {
    margin-right: 4px;
  }

  & .gen-text-section .card-body {
    padding: 0 8px 8px 8px;
  }

  & .gen-text-section .card-body.draft {
    padding: 0;
  }

  & .gen-text-section .card-body.draft textarea {
    min-height: 180px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  & .gen-text-section .card-body button {
    margin: 4px;
  }

  & .gen-text-section.card .text-counter-box {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  & .gen-text-section1 {
    width: calc(100% - 180px);
  }

  & .gen-text-section2 {
    width: 180px;
  }

  & .gen-text-section2 .ant-radio-group {
    padding: 6px 0 6px 10px;
  }

  & .label {
    font-size: 16px;
    font-weight: 550;
  }

  & .label.head:after {
    content: ':'
  }

  & .text-counter-box {
    background-color: #E6F4FF;
    border: 1px solid #D9d9D9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    height: 32px;
    width: 100%;
  }

  & .text-counter-box .text-counter-label,
  & .text-counter-box .text-counter-value {
    color: #666;
    font-size: 14px;
    font-weight: 550;
  }

  & .text-counter-box .text-counter-label::after {
    content: ': ';
  }

  & .text-counter-box .text-counter-value {
    color: #444;
  }

  & .text-counter-box .item-space {
    display: inline-block;
    width: 16px;
  }

  & .text-counter-box .text-count-box {
    margin-right: 12px;
  }
`;

import React from 'react';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import { ClearOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import theme from '../../assets/styles/theme';
import { FormLabel } from '../../components/common/styledComponents';
import MaskOverlay from '../../components/common/MaskOverlay';
/*import {
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
} from '../../constants/config';*/
import LabelsSelector from '../ListProducts/LabelsSelector';
import ChannelControlFlagSelector from '../ListProducts/ChannelControlFlagSelector';
import BrandSelector from '../ListProducts/BrandSelector';
import SortSelector from '../ListProducts/SortSelector';
import { isCodeListType } from './';

type Props = {
  channelFlagVersion: number;
  clearChannelFlags: Function;
  clearExtraFilter?: Function;
  clearLabels: Function;
  clearSearchBrand: Function;
  clearSearchTitle: Function;
  excludeSalesChannel?: JSX.Element;
  extraFilter?: JSX.Element[];
  extraSorter?: (JSX.Element | null)[];
  handleSearch: Function;
  hidePanel: Function;
  labelVersion: number;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  searchBrandVersion: number;
  searchCodeType: number;
  searchMultiCode: string;
  searchTitleVersion: number;
  setBrand: Function;
  setLabels: Function;
  setMultiCode: Function;
  setSalesChannel: Function;
  setSortType: Function;
  setStatus: Function;
  setTitle: Function;
  showPanel: Function;
  status: number[];
  statusOptions: StringKAnyVPair[];
  x: number;
  y: number;
  visible: boolean;
  width: number;
  groupList: any[];
  onGroupChange: Function;
  onTagChange: Function;
  searchTagVersion: number;
  searchGroupsVersion: number;
};

const PanelWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: absolute;

  & textarea.ant-input {
    height: 120px;
  }

  & .ant-divider {
    margin: 8px;
  }

  & .ant-select {
    width: 100%;
  }

  & .bottom-searchbox {
    margin-top: 3px;
  }

  & .bottom-toolbar {
    margin: 8px 20px 6px 12px;
  }

  & .filter-body {
    margin: 12px;
  }

  & .filter-form-cell {
    padding: 8px;
    width: 25%;
  }

  & .filter-form-cell.bg-cell {
    background-color: #EDEDED;
  }

  & .form-text-input {
    width: 100%;
  }

  & .search-icon-wrapper {
    cursor: pointer;
    font-size: 18px;
    padding: 3px 8px 6px 8px;
  }

  & .search-icon-wrapper.active {
    color: ${theme['@primary-color']};
  }

  & .search-input-wrapper {
    margin-left: 4px;
    overflow: hidden;
    padding: 0;
    -webkit-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    transform: scale(0.75);
    transform-origin: left;
    width: 46px;
  }

  & .search-input-wrapper .ant-input {
    font-size: 18px;
    height: 34px;
  }

  & .search-input-wrapper .ant-input-group-addon:first-child {
    border: 1px solid #D9D9D9 !important;
    border-radius: 21px;
    font-size: 22px;
    padding: 0 10px;
    padding-top: 4px;
  }

  & .search-input-wrapper .ant-input-affix-wrapper {
    border: 1px solid transparent;
    border-left: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 23px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 23px;
  }

  & .search-input-wrapper .search-icon-box {
    cursor: pointer;
  }

  & .search-input-wrapper.active {
    width: 317px;
  }

  & .search-input-wrapper.active .ant-input-affix-wrapper {
    border: 1px solid #D9D9D9;
  }

  & .search-input-wrapper.active .ant-input-group-addon:first-child {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: ${theme['@primary-color']};
  }

  & .searchbox-wrapper {
    padding: 0px 2px;
    width: 300px;
  }

  & .searchbox-wrapper .ant-input-affix-wrapper {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  & .textarea-wrapper {
    padding: 8px;
  }
`;

const SearchFilterPanel = (props: Props) => {
  const FORM_SECTION_CLS = 'filter-form-cell';
  const { useState } = React;
  const [displaySearcher, setDisplaySearcher] = useState(false);
  const [searchInputVersion, setSearchInputVersion] = useState(0);
  const [searchTimer, setSearchTimer] = useState<number>();
  const attrSearchInputRef = React.useRef<any>(null);
  const pageRef = React.useRef<any>(null);

  const clearFilter = () => {
    props.clearChannelFlags();
    props.clearLabels();
    props.clearSearchBrand();
    props.clearSearchTitle();
    props.setMultiCode("");
    props.setStatus([]);
    if (typeof props.clearExtraFilter === "function") props.clearExtraFilter();
  };

  const filterAddonBefore = () => {
    return (
      <span
        className="search-icon-box"
        onClick={toggleSearcher}
      >
        <FilterOutlined />
      </span>
    );
  };

  const handleSearch = () => {
    setTimeout(() => {
      props.handleSearch();
    }, 0);
    props.hidePanel();
  };

  const onPanelMouseDown = () => {
    props.showPanel();
  };

  // eslint-disable-next-line
  const onSearchBrandChange = (brands: string[]) => {
    //console.log('v', brands);
    props.setBrand(brands.join(','));
  };

  // eslint-disable-next-line
  const onSearchBrandChange2 = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchBrand(value);
      props.setBrand(value);
    }, 0);
  };

  const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;
    const str = value.replace(/\t/g, "\n");

    //setSearchMultiCode(str);
    props.setMultiCode(str);
  };

  const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (props.searchMultiCode.trim()) {
        props.setMultiCode(props.searchMultiCode.trim() + "\n");
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };

  const onSearchStatusChange = (st: number[]) => {
    //setSearchStatus(st);
    props.setStatus(st);
  };

  const onSearchTitleChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchTitle(value);
      props.setTitle(value);
    }, 0);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (typeof sk === 'string' && pageRef.current) {
      const sections = pageRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      sk = sk.toLowerCase().trim();

      if (sk) {
        setFilterHeight();
      }

      for (let i = 0; i < sections.length; i++) {
        const labelDom = sections[i].querySelector('span');

        if (labelDom) {
          const label = labelDom.textContent.trim();

          if (label && typeof label === 'string') {
            const match = label.toLowerCase().indexOf(sk.toLowerCase()) > -1;

            sections[i].style.display = match ? '' : 'none';
          }
        }
      }
    }
  };

  const onSearchboxKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //setSearchCCFs(values);
    props.setSalesChannel(values);
  };

  const onSelectLabels = (values: any[]) => {
    //setSearchLabels(values);
    props.setLabels(values);
  };

  const onSelectSortType = (value: any) => {
    //setSearchSorter(value);
    props.setSortType(value);
  };

  const searchFieldInputWrapperClass = () => {
    const cls = ['search-input-wrapper'];

    if (displaySearcher) {
      cls.push('active');
    }

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const searchIconWrapperClass = () => {
    const cls = ['search-icon-wrapper'];

    if (displaySearcher) {
      cls.push('active');
    }

    return cls.join(' ');
  };

  const setFilterHeight = () => {
    if (pageRef.current) {
      const body = pageRef.current.querySelector('.filter-body');

      if (body) {
        body.style.height = `${body.offsetHeight}px`;
      }
    }
  };

  const showAllFields = () => {
    if (pageRef.current) {
      const sections = pageRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      for (let i = 0; i < sections.length; i++) {
        sections[i].style.display = '';
      }
    }
  };

  const sorterClass = (sorter: any) => {
    const ret = [ 'filter-form-cell' ];

    if (sorter !== null) {
      ret.push('bg-cell');
    }

    return ret.join(' ');
  };

  const toggleSearcher = () => {
    if (displaySearcher) {
      showAllFields();
      setSearchInputVersion(searchInputVersion + 1);
    } else {
      setTimeout(() => {
        if (attrSearchInputRef.current) {
          //console.log(attrSearchInputRef.current);
          attrSearchInputRef.current.input.focus();
        }
      }, 600);
    }

    setDisplaySearcher(!displaySearcher);
  };

  return (
    <>
      <PanelWrapper
        onMouseDown={onPanelMouseDown}
        onMouseEnter={props.onMouseEnter as any}
        onMouseLeave={props.onMouseLeave as any}
        ref={pageRef}
        style={{
          left: props.x,
          top: props.y,
          width: props.width,
          display: props.visible ? "" : "none",
          zIndex: 1,
        }}
      >
        <Col className="filter-body">
          {isCodeListType(props.searchCodeType) && (
            <div className="textarea-wrapper">
              <Input.TextArea
                allowClear
                onChange={onSearchCodeListChange}
                onKeyDown={onSearchCodeListKeyDown}
                value={props.searchMultiCode}
              />
            </div>
          )}
          <Row justify="start">
            <div className="filter-form-cell">
              <FormLabel>Product Name</FormLabel>
              <Input
                allowClear
                className="form-text-input"
                key={`title-${props.searchTitleVersion}`}
                onChange={onSearchTitleChange}
                //value={searchTitle}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Exclude Status</FormLabel>
              <Select
                mode="multiple"
                onChange={onSearchStatusChange}
                options={props.statusOptions as any[]}
                value={props.status}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Sales Channel</FormLabel>
              <ChannelControlFlagSelector
                channelFlagVersion={props.channelFlagVersion}
                onChange={onSelectChannelControlFlags}
              />
            </div>
            {props.excludeSalesChannel}
            <div className="filter-form-cell">
              <FormLabel>Brand</FormLabel>
              {/*<Input
                allowClear
                className="form-text-input"
                key={`brand-${props.searchBrandVersion}`}
                onChange={onSearchBrandChange2}
              />*/}
              <BrandSelector
                key={`brand-${props.searchBrandVersion}`}
                version={props.searchBrandVersion}
                onBrandChange={onSearchBrandChange}
              />
            </div>
            <div className="filter-form-cell">
              <FormLabel>Tags</FormLabel>
              <LabelsSelector
                labelVersion={props.labelVersion}
                onChange={onSelectLabels}
              />
            </div>
            {/*<div className="filter-form-cell">
              <FormLabel>Tag</FormLabel>
              <Input
                key={`Tag-${props.searchTagVersion}`}
                placeholder="Please input and separate with commas"
                style={{ width: "100%" }}
                onChange={(e) => props.onTagChange(e.target.value || "")}
              />
            </div>*/}
            {(props.extraFilter || []).map((item, index) => (
              <div className="filter-form-cell" key={`extra-item-${index}`}>
                {item}
              </div>
            ))}
            {/*<div className="filter-form-cell">
              <FormLabel>Display By</FormLabel>
              <Select
                key={`Group-${props.searchGroupsVersion}`}
                className="status-selector"
                mode="multiple"
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
                onChange={(value, option) => props.onGroupChange(value, option)}
                options={props.groupList.map((group) => ({
                  label: group.enumName,
                  value: group.enumValue,
                }))}
              />
            </div>*/}
           {(props.extraSorter || []).map((item, index) => (
              <div className={sorterClass(item)} key={`extra-sorter-${index}`}>
                {item === null ? <span>&nbsp;</span> : item}
              </div>
            ))}
            <div className="filter-form-cell bg-cell">
              <FormLabel>Sort By</FormLabel>
              <SortSelector onChange={onSelectSortType} />
            </div>
          </Row>
        </Col>
        <Divider className="bottom-divider" />
        <Row className="bottom-toolbar" align="middle" justify="space-between">
          <Row align="middle" className="bottom-searchbox">
            {/*<span
              className={searchIconWrapperClass()}
              onClick={toggleSearcher}
            >
              <FilterOutlined />
            </span>*/}
            {/*displaySearcher && (
              <span className="searchbox-wrapper">
              <Input.Search
                allowClear
                enterButton={null}
                onKeyUp={onSearchboxKeyUp}
                onSearch={onSearchAttributes}
                ref={attrSearchInputRef}
              />
              </span>
            )*/} 
            <span className={searchFieldInputWrapperClass()}>
              <Input.Search
                enterButton={null}
                addonBefore={filterAddonBefore()}
                allowClear
                key={`field-filter-input${searchInputVersion}`}
                onKeyUp={onSearchboxKeyUp}
                onSearch={onSearchAttributes}
                ref={attrSearchInputRef}
              />
            </span>
          </Row>
          <Space>
            <Button onClick={clearFilter}>
              <ClearOutlined />
              Clear Filter
            </Button>
            <Button onClick={handleSearch} type="primary">
              <SearchOutlined />
              Search
            </Button>
          </Space>
        </Row>
      </PanelWrapper>
      <MaskOverlay
        height={`calc(100vh - ${props.y}px)`}
        onClick={props.hidePanel}
        x={0}
        y={props.y}
        visible={props.visible}
      />
    </>
  );
};

export default SearchFilterPanel;

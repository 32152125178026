import React, { useState } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { DataGrid } from "../../../components/common/datagrid/DataGrid2";
import Loading from "../../../components/common/Loading";
import ScreenMask from "../../../components/common/ScreenMask";
import message from "../../../components/common/message";
//import { HoverBgButton } from '../../../components/common/styledComponents';
import { LOADING_ICON_SIZE1 } from "../../../constants/config";
import Products from "../../../services/products";
import { randomString } from "../../../util";
import { isArray } from "lodash";

const GridWrapper = styled.div`
  height: calc(100vh - 240px);

  & .InovuaReactDataGrid {
    height: 100%;
  }
`;

const columnSrc: any[] = [
  {
    name: "sku",
    header: "SKU",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 260,
    width: 350,
  },
  {
    name: "colorPatternCode",
    header: "Color Pattern",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 130,
    width: 160,
  },
  {
    name: "sizeCode",
    header: "Size",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
  {
    name: "lengthCode",
    header: "Length",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
  {
    name: "widthCode",
    header: "Width",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
  {
    name: "atsQuantity",
    header: "ATS",
    headerAlign: "center",
    defaultFlex: 1,
    minWidth: 80,
    width: 100,
  },
];

type Props = {
  focused: boolean;
  isCreating: boolean;
  loadAttributesChannels: Function;
  loadAttributes: Function;
  setTabTitle?: Function;
  productId?: string;
  productType?: number;
  ats: StringKAnyVPair;
};

const StyleMaster = (props: Props) => {
  const { productId, productType, focused } = props;
  const [currentProductId, setCurrentProductId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [styleList, setStyleList] = useState<StringKAnyVPair[]>([]);
  const [ats, setAts] = useState<StringKAnyVPair>({})
  //const [styleCode, setStyleCode] = useState('');
  React.useEffect(()=>{
    setAts(props.ats)
  },[props.ats])
  // eslint-disable-next-line
  const confirmDeleteStyleRow = (rowData: StringKAnyVPair) => {
    Modal.confirm({
      title: `Are you sure to remove '${rowData.sku}'`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => deleteStyleRow(rowData),
    });
  };

  const deleteStyleRow = async (row: StringKAnyVPair) => {
    setIsLoading(true);

    console.log("--->", row);
    try {
      const pd = {
        productId: row.productNum,
        //productType: row.productType,
        //sku: row.sku,
        //erpCode: row.erpCode,
        editType: 2,
      };
      const p = {
        //ApplyToAllRelevantSubStyleAndSKU: false,
        styleMasterItemList: [pd],
        //styleCode,
      };
      console.log("p ->", p);
      //await Products.deleteStyleMaster(row.data.sku);
      if (productId) {
        await Products.editSimpleStyleMasterChildren(row.parentId, p);
        let nodeBreak = false;
        // rm row and reset style list at here
        for (let i = styleList.length - 1; i >= 0; i--) {
          if (isArray(styleList[i].nodes)) {
            for (let j = 0; j < styleList[i].nodes.length; j++) {
              if (styleList[i].nodes[j].uniqueId === row.uniqueId) {
                styleList[i].nodes.splice(j, 1);
                setStyleList([...styleList]);
                nodeBreak = true;
                break;
              }
            }
          }
          if (styleList[i].uniqueId === row.uniqueId) {
            styleList.splice(i, 1);
            setStyleList([...styleList]);
            break;
          }
          if (nodeBreak) break;
        }
      } else {
        // eslint-disable-next-line
        throw "No product id";
      }
    } catch (e) {
      message.error(`Remove ${row.sku} error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const gridColumns = () => {
    const cols: any[] = [...columnSrc];

    /*cols.push({
        name: 'uniqueId',
        header: 'Action',
        textAlign: 'center',
        width: 100,
        render(row: any) {
          const { data } = row;

          return (
            <HoverBgButton hovertype="danger" onClick={() => confirmDeleteStyleRow(data)}>
              Remove
            </HoverBgButton>
          );
        },
      });*/

    return cols;
  };

  const getStyleItem = (e: StringKAnyVPair) => {
    return {
      ...e,
      parentId: "",
      uniqueId: randomString(),
    };
  };

  // eslint-disable-next-line
  const loadStyleMaster = async (
    productIdParam: string,
    pList: StringKAnyVPair[] = []
  ) => {
    if (productIdParam === productId) setIsLoading(true);

    try {
      const res = await Products.getStyleMasterFamily(productIdParam);

      console.log("res->", res);
      if (Array.isArray(res)) {
        //const list: StringKAnyVPair[] = [...pList];

        for (let i = 0; i < res.length; i++) {
          const item: StringKAnyVPair = getStyleItem(res[i]);

          item.parentId = productIdParam;
          pList.push({ ...item, atsQuantity: ats[item.productNum] || 0 });

          if (productType === 2 && productIdParam === productId) {
            // create nodes here
            pList[pList.length - 1].nodes = null;
            // await loadStyleMaster(res[i].productNum, pList[pList.length - 1].nodes);
          }
        }
        // pList.forEach((item) => { item.nodes = [item]; })
        if (productIdParam === productId) setStyleList([...pList]);
      } else {
        message.error(`No valid style master data: ${res}`);
      }
    } catch (e) {
      message.error(`Fetch style master error: ${e}`);
    } finally {
      if (productIdParam === productId) setIsLoading(false);
    }
  };

  const loadNextLevelProducts = async (id: string) => {
    let ret: any = [];
    try {
      const res = await Products.getStyleMasterFamily(id);

      if (Array.isArray(res)) {
        ret = res.map((i) => {
          return {
            ...i,
            atsQuantity: ats[i.productNum] || 0,
            parentId: "",
            uniqueId: randomString(),
          };
        });
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  React.useEffect(() => {
    if (focused && productId && currentProductId !== productId) {
      loadStyleMaster(productId);
      setCurrentProductId(productId);
    }
  }, [focused, currentProductId, isLoading, loadStyleMaster, productId]);

  return (
    <>
      <GridWrapper>
        <DataGrid
          columns={gridColumns()}
          dataSource={styleList}
          idProperty="uniqueId"
          treeColumn="sku"
          sortable={false}
          loadNodeOnce={async (data: any) => {
            const { node } = data;
            if (node.productNum ) {
              const res = await loadNextLevelProducts(node.productNum);
              if (Array.isArray(res)) {
                node.nodes = res;
              }
              return res;
            } else {
              return [];
            }
          }}
        />
      </GridWrapper>
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default React.memo(StyleMaster);

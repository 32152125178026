import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  SearchOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  // DownOutlined,
  LinkOutlined,
} from '@ant-design/icons';
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  message,
  notification,
} from 'antd';
import styled from 'styled-components';
import ContentLayout from '../../components/ContentLayout';
import Heading from '../../components/common/Heading';
// import LoadingIcon from '../../components/common/LoadingIcon';
import SiteContent from '../../components/SiteContent';
import Spacer from '../../components/common/Spacer';
import { StyleInputWrapper } from "../../components/common/styledComponents";
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from '../../components/common/datagrid/Filter';
import SearchTips from '../../components/common/SearchTips';
import { useListMappedAccountsQuery } from '../../redux/api/channels';
import { fetchContentSourceType, fetchViewList } from '../../services/copywriting';
import Products, {
  fetchAlternateList,
  fetchSimpleProductGroup,
  fetchSimpleProductList2,
  fetchSimpleProductPartialData,
  fetchProductImages,
} from '../../services/products';
import {
  CW_VIEW_ALL,
  CW_VIEW_CUSTOM,
  CW_VIEW_GROUP,
  bundleTypeOptions,
  getBundleTypeLabel,
  renderFilterTag,
} from '../Copywriting';
//import ClassificationSelector from '../ListProducts/ClassificationSelector';
import { loadStyleVariations } from '../DetailProduct/helper';
import GroupDetail from '../ListProducts/GroupDetail';
import ExportMdal from './ExportModal';
import ImportModal from './ImportModal';
//import EditImageDialog from "../DetailProduct/EditImageDialog";
//import SimpleSelectorModal from "../DetailProduct/BasicAttrEditor/SimpleSelectorModal";
import SetImageCell from './SetImageCell';
import ImageModal from './ImageModal';
import ProductDetailDialog from '../ListProducts/ProductDetailDialog';
import {
  getOperationStatusDesc,
  getOperationStatusOptions,
} from '../DetailProduct/BasicAttrEditor';
import { getProfileSettingValue } from '../../util';
import SearchFilterPanel from '../ListProducts/SearchFilterPanel';
import { isCodeListType } from '../ListProducts';
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  CODE_OPTION_COLOR_CONTAINS,
  CODE_OPTION_COLOR_EQUALS,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_CONTAINS,
  CODE_OPTION_SKU_EQUALS,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_CONTAINS,
  CODE_OPTION_STYLE_EQUALS,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_EQUALS,
  CODE_OPTION_UPC_LIST,
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  OPERATION_CONTAIN,
  OPERATION_EQUALS,
  OPERATION_LIST,
  SEARCH_PRODUCT_PARAMS
} from '../../constants/config';
import { FormLabel } from '../../components/common/styledComponents';
import ERPLinkTableCell from  '../../components/common/ERPLinkTableCell'
import TableFilter from '../../components/common/TableFilter'
import { getProfilesState } from '../../redux/utils';
import { fetchProfileSettings } from '../../services/channels';

const CancelToken = axios.CancelToken;
let cancel: any = undefined;

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

const SKULink = (props: SKULinkProps) => {
  const { product, target = '_self' } = props;
  const pids = product.ProductId.split('/');
  const productId = pids[pids.length - 1] || '-';

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const SectionTitle = styled.h2`
//   font-weight: bold;
//   margin-bottom: 0;
// `;

const SearchCol = styled(Col)`
  width: 100%;

  & .ant-input:read-only {
    background-color: #dcdcdc;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #dcdcdc;
  }

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #d9d9d9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ": ";
  }

  & .loading-wrapper {
    display: "inline-block";
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    /*width: 768px;*/
    width: 100%;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 430px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      /*width: 820px;*/
      width: 100%;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      /*width: 868px;*/
      width: 100%;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      /*width: 928px;*/
      width: 100%;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      /*width: 1028px;*/
      width: 100%;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      /*width: 1150px;*/
      width: 100%;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

// const ProductDetailDialogBodyWrapper = styled.div`
//   /*max-height: calc(100vh - 180px);*/
//   height: calc(100vh - 260px);
//   overflow-y: auto;

//   &.fullscreen-mode {
//     height: calc(100vh - 168px);
//   }
// `;

export const columns = [
  {
    name: 'mediaURL',
    header: 'Image',
    defaultFlex: 1,
    defaultLocked: true,
    locked: 'start',
    minWidth: 100,
    maxWidth: 100,
    render({ value, data }: { value: string; data: Entities.ProductProfile }) {
      const src = value || 'https://via.placeholder.com/300';
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: 'VariationParentSKU',
    header: 'Style',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // return data.styleCode || data.VariationParentSKU;
        return <ERPLinkTableCell value={data.styleCode || data.VariationParentSKU} type={1} />
      }

      return '';
    },
  },
  {
    //name: 'ProductId',
    name: 'subStyleCode',
    header: 'Substyle',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 180,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // return data.subStyleCode || '';
        return <ERPLinkTableCell value={data.subStyleCode || ''} type={2} />
      }

      return '';
    },
  },
  {
    name: 'SKU',
    header: 'SKU',
    defaultFlex: 1,
    locked: 'start',
    minWidth: 200,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  {
    name: 'ProductStatus',
    header: 'Status',
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
];

/*const initialProductListing = {
  ProductTotalCount: 0,
  ProductList: [],
};*/

let profileSettings: StringKAnyVPair[] = [];

const PageContent: React.FC = () => {
  const { Option } = Select;
  const DEFAULT_GRID_LIMIT = 20;
  const {defaultSearchCodeType, defaultSearchGroup} = localStorage.getItem(SEARCH_PRODUCT_PARAMS) ? JSON.parse(localStorage.getItem(SEARCH_PRODUCT_PARAMS) ||'') : {
    defaultSearchCodeType: CODE_OPTION_SKU_CONTAINS,
    defaultSearchGroup: GROUP_OPTION_PRODUCT,
  }

  // const [extLoading, setExtLoading] = useState<boolean>(false);
  const [filteredTempData, setFilteredTempData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<Entities.ProductProfile[]>([]);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>({});
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  // const [currentProductId, setCurrentProductId] = useState('');
  const [detailVisible, setDetailVisible] = useState(false);
  const [displayViewInited, setDisplayViewInited] = useState(false);
  // eslint-disable-next-line
  const [displayViewLoading, setDisplayViewLoading] = useState(false);
  const [displayViewOptions, setDisplayViewOptions] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [displayViewSelected, setDisplayViewSelected] = useState<any[]>([]);
  const [displayViewVersion, setDisplayViewVersion] = useState(0);
  const [displayType, setDisplayType] = useState(CW_VIEW_ALL);
  const [extraAgeGroup, setExtraAgeGroup] = useState<string[]>([]);
  const [extraAlternateCode, setExtraAlternateCode] = useState<string[]>([]);
  const [extraAlternateOptions, setExtraAlternateOptions] = useState<StringKAnyVPair[]>([]);
  const [extraBundleType, setExtraBundleType] = useState<number>();
  const [extraCategoryCode, setExtraCategoryCode] = useState<string[]>([]);
  const [extraClassCode, setExtraClassCode] = useState<string[]>([]);
  const [extraCountryOfOrigin, setExtraCountryOfOrigin] = useState<string[]>([]);
  const [extraDepartmentCode, setExtraDepartmentCode] = useState<string[]>([]);
  const [extraDivisionCode, setExtraDivisionCode] = useState<string[]>([]);
  const [extraGender, setExtraGender] = useState<string[]>([]);
  const [extraGroupCode, setExtraGroupCode] = useState<string[]>([]);
  const [extraManufacturer, setExtraManufacturer] = useState<string[]>([]);
  const [extraModel, setExtraModel] = useState<string[]>([]);
  const [extraProductYear, setExtraProductYear] = useState('');
  const [extraRemark, setExtraRemark] = useState('');
  const [extraSubClassCode, setExtraSubClassCode] = useState<string[]>([]);
  const [extraSubGroupCode, setExtraSubGroupCode] = useState<string[]>([]);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [inited, setInited] = useState(false);
  const [importDialogVisible, setImportDialogVisible] = useState(false);
  const [importOptions, setImportOptions] = useState<StringKAnyVPair>({});
  const [isFetching, setIsFetching] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, /*setSearchCFs*/] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [searchCodeType, setSearchCodeType] = useState(defaultSearchCodeType);
  const [searchGroups, setSearchGroups] = useState<number[]>([]);
  const [searchGroupsStr, setSearchGroupsStr] = useState<string[]>([]);
  const [searchGroupsVersion, setSearchGroupsVersion] = useState(0);
  const [searchMultiCode, setSearchMultiCode] = useState('');
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(defaultSearchGroup);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchBrand, setSearchBrand] = useState<string>('');
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchSorter, setSearchSorter] = useState<string>('SKU ASC'); const [searchStatus, setSearchStatus] = useState<number[]>([1]);
  const [searchTagVersion, setSearchTagVersion] = useState(0);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [searched, setSearched] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [stateColumns, setStateColumns] = useState<any>(columns);
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = React.useState<any>({});
  const [isCompactMode, setIsCompactMode] = React.useState<number>(1);
  const [imageModalVisible, setImageModalVisible] = React.useState<boolean>(false);
  const [currentImage, setCurrentImage] = React.useState<any>();
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [labelVersion, setLabelVersion] = useState(0);
  const codeInputRef = React.useRef<any>(null);
  const filterState = React.useRef<any>(null);
  const filterHiddenTimer = React.useRef<any>(0);
  const [productListStyle, setProductListStyle] = useState<string>();
  const [overwriteChild, setOverwriteChild] = useState(false);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } 
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwriteChild(!!allowOverWrite);
    }
  },[]);

  useEffect(() => {
    loadProfileSetting();
  }, [loadProfileSetting]);

  useEffect(()=>{
    localStorage.setItem(SEARCH_PRODUCT_PARAMS, JSON.stringify({
      defaultSearchCodeType: searchCodeType,
      defaultSearchGroup: searchGroup,
    }))
  },[searchCodeType, searchGroup])

  const getProductListStyle = useCallback(async () => {
    const value = await getProfileSettingValue('ProductListStyle', '0');
    setProductListStyle(value);
  }, []);

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    const { skip, top } = options;
    setSelected({});

    setIsFetching(true);

    try {
      console.log('options ->', getSearchOptions());
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList2(
        typeof skip === 'number' && skip >= 0 ? skip : pageSkip,
        typeof top === 'number' && top >= 0 ? top : pageTop,
        getSearchOptions(),
      );
      if (Array.isArray(ProductList)) {
        if (searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR) {
          ProductList.forEach((e) => {
            if (['Product', 'Bundle'].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        // setSearchTreeMode(isSearchGroupMode(searchCodeType));
        setSearchTreeMode(searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR)

        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      message.error(`Fetch products error: ${e}`);
      console.log('Fetch products error:', e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (res && typeof res === 'object' && Array.isArray(res.LabelList) && res.LabelList.length > 0) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  useEffect(() => {
    getProductListStyle();
  }, [getProductListStyle]);

  const searchCodeTypeSelector = () => (
    <Select
      className="select-after"
      defaultValue={searchCodeType}
      //onBlur={onSearchbarBlur}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230, }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style List</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Substyle Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Substyle Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Substyle List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );

  const filterValue = [
    { name: 'SKU', operator: 'contains', type: GRID_FILTER13, value: '' },
    {
      name: 'ProductTitle',
      operator: 'contains',
      type: GRID_FILTER11,
      value: '',
    },
    { name: 'UPC', operator: 'eq', type: GRID_FILTER12, value: '' },
    { name: 'QtyTotal', operator: 'gte', type: GRID_FILTER21, value: null },
  ];
  const gridFilterTypes = Object.assign(
    {
      styleCodeFilter: {
        type: 'string',
        emptyValue: '',
        operators: [
          { name: 'SKU Contains', fn: () => true },
          { name: 'SKU Equals', fn: () => true },
        ],
      },
    },
    filterTypes,
  );

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredTempData,
      count: totalCount,
    };
  };

  const closeImportDialog = () => {
    setImportDialogVisible(false);
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
    // setCurrentProductId('');
  };

  const fetchProductExtData = useCallback(
    async (products: StringKAnyVPair[]) => {
      try {
        if (cancel !== undefined) {
          cancel();
        }
        const pDict: StringKAnyVPair = {};
        const ids = products.map((e) => {
          pDict[e.ProductId] = e;

          return e.ProductId;
        });
        // setExtLoading(true);
        const data = await fetchProductImages(
          ids,
          new CancelToken(function executor(c) {
            cancel = c;
          }),
        );
        if (data.length > 0) {
          setStateColumns([
            ...columns,
            ...data[0].mappedPreviewers.map((i: any, index: number) => {
              return {
                name: `ext${index}`,
                header: i.previewDisplayName,
                defaultWidth: 150,
                render: ({ data }: { data: any }) => {
                  return (
                    <SetImageCell
                      imageUrl={data[`ext${index}`]}
                      compact={!!isCompactMode}
                      onButtonClick={() => {
                        setCurrentImage({
                          imageUrl: data[`ext${index}`],
                          attributeName: i.previewDisplayName,
                          imageAttributeNum: i.previewID,
                          productId: data.ProductId,
                          name: `ext${index}`,
                          productType: data.ProductType,
                        });
                        setImageModalVisible(true);
                      }}
                    />
                  );
                },
              };
            }),
          ]);
          data.map((item: any) => {
            if (pDict[item.productId]) {
              item.mappedPreviewers.map((i: any, index: number) => {
                pDict[item.productId][`ext${index}`] = i.previewValue;
                return true;
              });
            }
            return true;
          });
          // setFilteredData(Object.values(pDict));
        }
        // setExtLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('request canceled..');
        }
      }
    },
    [isCompactMode],
  );

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        // console.log('d->', data, products);
        data.forEach((e) => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      message.error(`Fetch partial error: ${e}`);
      console.error('Fetch partial error:', e);
    } finally {
      // setIsFetching(false);
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode
          .split('\n')
          .map((e) => e.trim())
          .filter((e) => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: 'ProductStatus',
      collectionFilterValues: searchStatus,
      op: 7,
    });

    if ((searchTags || []).length > 0) {
      ret.push({
        filterName: "Tag",
        collectionFilterValues: searchTags,
        op: OPERATION_EQUALS,
      });
    }

    if (extraAgeGroup.length > 0) {
      ret.push({
        filterName: 'AgeGroup',
        collectionFilterValues: extraAgeGroup,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraAlternateCode.length > 0) {
      ret.push({
        filterName: 'AlternateCode',
        collectionFilterValues: extraAlternateCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCategoryCode.length > 0) {
      ret.push({
        filterName: 'CategoryCode',
        collectionFilterValues: extraCategoryCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraClassCode.length > 0) {
      ret.push({
        filterName: 'ClassCode',
        collectionFilterValues: extraClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCountryOfOrigin.length > 0) {
      ret.push({
        filterName: 'CountryOfOrigin',
        collectionFilterValues: extraCountryOfOrigin,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDepartmentCode.length > 0) {
      ret.push({
        filterName: 'DepartmentCode',
        collectionFilterValues: extraDepartmentCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDivisionCode.length > 0) {
      ret.push({
        filterName: 'DivisionCode',
        collectionFilterValues: extraDivisionCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGender.length > 0) {
      ret.push({
        filterName: 'Gender',
        collectionFilterValues: extraGender,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGroupCode.length > 0) {
      ret.push({
        filterName: 'GroupCode',
        collectionFilterValues: extraGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraManufacturer.length > 0) {
      ret.push({
        filterName: 'Manufacturer',
        collectionFilterValues: extraManufacturer,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraModel.length > 0) {
      ret.push({
        filterName: 'Model',
        collectionFilterValues: extraModel,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraProductYear) {
      ret.push({
        filterName: 'ProductYear',
        collectionFilterValues: [extraProductYear],
        op: OPERATION_CONTAIN,
      });
    }

    /*if (extraRemark) {
      ret.push({
        filterName: 'Remark',
        collectionFilterValues: [extraRemark],
        op: OPERATION_CONTAIN,
      });
    }*/

    if (extraSubClassCode.length > 0) {
      ret.push({
        filterName: 'SubClassCode',
        collectionFilterValues: extraSubClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraSubGroupCode.length > 0) {
      ret.push({
        filterName: 'SubGroupCode',
        collectionFilterValues: extraSubGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        return 'ColorPatternCode';

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return 'SKU';

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return 'StyleCode';

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return 'UPC';
    }
  };

  // eslint-disable-next-line
  const getGroupList = async () => {
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = '',
      } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        notification.error({
          message: resMsg || 'No group list found',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  const getSearchGrouper = (/*filters: any[]*/) => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = '';

    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = 'ColorPatternCode';
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = 'Product';
        break;

      case GROUP_OPTION_STYLE:
        groupName = 'StyleCode';
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
  };

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];

    if (
      searchCodeType !== CODE_OPTION_SKU_LIST &&
      searchCodeType !== CODE_OPTION_COLOR_LIST &&
      searchCodeType !== CODE_OPTION_STYLE_LIST &&
      searchCodeType !== CODE_OPTION_UPC_LIST
    ) {
      filters.push({
        filterName: getFilterNameBySearchCodeType(),
        filterValue: searchCode,
        op: getOperateCodeBySearchCodeType(),
      });
    }

    if (searchTitle) {
      filters.push({
        filterName: 'ProductTitle',
        filterValue: searchTitle,
        op: OPERATION_CONTAIN,
      });
    }

    if (searchCCFs.length > 0) {
      filters.push({
        filterName: 'ChannelControlFlag',
        filterValue: searchCCFs.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if (searchCFs.length > 0) {
      filters.push({
        filterName: 'Classification',
        filterValue: searchCFs.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if ((searchGroups || []).length > 0) {
      filters.push({
        filterName: "ContentSourceGroup",
        filterValue: searchGroups.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        filterName: 'Labels',
        filterValue: searchLabels.join('|'),
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      filters.push({
        filterName: 'Brand',
        filterValue: searchBrand,
        op: OPERATION_CONTAIN,
      });
    }

    if (typeof extraBundleType === 'number') {
      filters.push({
        filterName: 'BundleType',
        filterValue: extraBundleType,
        op: OPERATION_EQUALS,
      });
    }

    if (extraRemark) {
      filters.push({
        filterName: 'Remark',
        filterValue: extraRemark,
        op: OPERATION_CONTAIN,
        //op: OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(' ');

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const handleSearchProducts = () => {
    setInited(true);
    fetchProductList();
    setSearched(true);
  };

  // const isColorCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_COLOR_CONTAINS, CODE_OPTION_COLOR_EQUALS, CODE_OPTION_COLOR_LIST].indexOf(code) >
  //     -1
  //   );
  // };

  // const isCommonGroupOption = (code: number) => {
  //   return isColorCode(code) || isStyleCode(code);
  // };

  // const isSearchGroupMode = (code: number) => {
  //   return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  // };

  // const isSKUCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_SKU_CONTAINS, CODE_OPTION_SKU_EQUALS, CODE_OPTION_SKU_LIST].indexOf(code) > -1
  //   );
  // };

  // const isStyleCode = (code: number) => {
  //   return (
  //     [CODE_OPTION_STYLE_CONTAINS, CODE_OPTION_STYLE_EQUALS, CODE_OPTION_STYLE_LIST].indexOf(code) >
  //     -1
  //   );
  // };

  const openImportDialog = (evt: any) => {
    const opts: StringKAnyVPair = {};

    if (evt.key !== 'importCommerceCentral') {
      const ids = evt.key.split('_');

      if (ids.length === 2) {
        opts['ChannelAccountNum'] = ids[0];
        opts['ChannelNum'] = ids[1];
      }
    }

    setImportOptions(opts);
    setImportDialogVisible(true);
    //console.log(evt, '<--');
  };

  const openProductDetailDialog = (productId: string, product: StringKAnyVPair) => {
    setDetailVisible(true);
    console.log('pid->', productId);
    setCurrentProduct(product);
    // setCurrentProductId(productId);
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef = [...stateColumns];
    const tempColumns: any[] = [];

    for (let i = 0; i < colDef.length; i++) {
      if (productListStyle === '2') {
        if (['Style', 'Substyle'].includes(colDef[i].header)) {
          continue;
        }
      }
      if (colDef[i].header === 'Image') {
        colDef[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || 'https://via.placeholder.com/300';
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
              <Image width={28} height={28} src={src} />
            </ImageContainer>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      } else if (colDef[i].header === 'SKU') {
        colDef[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split('/');
          const productId = pids[pids.length - 1] || '-';

          return (
            <ERPLinkTableCell value={data.SKU} type={3} onClick={()=>  openProductDetailDialog(`${productId}`, data)} />
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      }
      tempColumns.push(colDef[i]);
    }

    return tempColumns;
  };

  const fetchChildrenPartialData = async (
    node: StringKAnyVPair,
    ProductList: Entities.ProductProfile[],
  ) => {
    await fetchProductParticalData(ProductList);
    await fetchProductExtData(ProductList);
    node.nodes = ProductList;
    //console.log('fd', filteredData);
    updateGridRow(node as Entities.ProductProfile);
  };

  const importMenu = () => {
    let chnList: StringKAnyVPair[] = [];
    //console.log('iii-->', mappedAccounts);

    const extrackMenuData = (dict: StringKAnyVPair, rt: 'nonRetail' | 'retail') => {
      if (mappedAccounts && typeof mappedAccounts === 'object') {
        if (mappedAccounts[rt] && typeof mappedAccounts[rt] === 'object') {
          for (let k in mappedAccounts[rt]) {
            if (Array.isArray(mappedAccounts[rt][k]) && mappedAccounts[rt][k].length > 0) {
              const item = mappedAccounts[rt][k][0];
              const chnName = item.platform ? item.platform['channelName'] : item.ChannelName;
              chnList.push({
                name: chnName,
                channels: mappedAccounts[rt][k],
              });
            }
          }
        }
      }
    };

    extrackMenuData(chnList, 'nonRetail');
    extrackMenuData(chnList, 'retail');
    //console.log('-->', chnList);

    return (
      <Menu onClick={openImportDialog} selectable={false} triggerSubMenuAction="click">
        <Menu.Item key="importCommerceCentral">Commerce Central</Menu.Item>
        {chnList.map((e) => (
          <Menu.SubMenu key={`importMenuChannel_${e.name}`} title={e.name}>
            {e.channels.map((c: StringKAnyVPair) => (
              <Menu.Item key={`${c.ChannelAccountNum}_${c.ChannelNum}`}>
                {c.ChannelAccountName || c.ChannelAccountNum}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ))}
      </Menu>
    );
  };

  const loadAlternateList = async () => {
    const res = await fetchAlternateList();

    //console.log('res ->', res);
    if (Array.isArray(res)) {
      setExtraAlternateOptions(res.map(e => ({
        value: e.code,
        label: e.code,
      })));
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    const sv = await loadStyleVariations();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    loadAlternateList();
  };

  const loadNextLevelProducts = async (data: any) => {
    //console.log('--->', data);
    const { node } = data;
    let ret: any = null;

    //setIsFetching(true);
    try {
      const { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        if (ProductList.length > 0) {
          await fetchChildrenPartialData(node, ProductList);
        }

        ret = ProductList;
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const loadDisplayViewOptions = async () => {
    setDisplayViewLoading(true);

    try {
      const opts = await fetchViewList();
      //console.log('opts', opts);
      if (opts) {
        setDisplayViewOptions(
          opts.map((e: StringKAnyVPair) => ({
            label: e.name,
            value: e.contentResourceViewNum,
          }))
        );
      }
    } catch(e) {
      notification.error({
        message: `Fetch content resource view list error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setDisplayViewLoading(false);
    }
  };

  // eslint-disable-next-line
  const onDisplayTypeChange = (val: number) => {
    setDisplayType(val);
    setDisplayViewSelected([]);
    setDisplayViewVersion(displayViewVersion + 1);

    if (val === CW_VIEW_CUSTOM && !displayViewInited) {
      loadDisplayViewOptions();
      setDisplayViewInited(true);
    }
  };

  // eslint-disable-next-line
  const onDisplayValueChange = (val: any) => {
    if (Array.isArray(val)) setDisplayViewSelected(val);
    else setDisplayViewSelected([val]);
  };

  const onExtraFilterFieldChange = (
    field: string,
    val: number | string | string[],
  ) => {
    switch(field) {
      case 'agegroup':
        setExtraAgeGroup(val as string[]);
        break;

      case 'alternate':
        setExtraAlternateCode(val as string[]);
        break;

      case 'bundletype':
        setExtraBundleType(val as number);
        break;

      case 'categorycode':
        setExtraCategoryCode(val as string[]);
        break;

      case 'classcode':
        setExtraClassCode(val as string[]);
        break;

      case 'countryoforigin':
        setExtraCountryOfOrigin(val as string[]);
        break;

      case 'departmentcode':
        setExtraDepartmentCode(val as string[]);
        break;

      case 'divisioncode':
        setExtraDivisionCode(val as string[]);
        break;

      case 'gender':
        setExtraGender(val as string[]);
        break;

      case 'groupcode':
        setExtraGroupCode(val as string[]);
        break;

      case 'manufacturer':
        setExtraManufacturer(val as string[]);
        break;

      case 'model':
        setExtraModel(val as string[]);
        break;

      case 'productyear':
        setExtraProductYear(val as string);
        break;

      case 'remark':
        setExtraRemark(val as string);
        break;

      case 'subclasscode':
        setExtraSubClassCode(val as string[]);
        break;

      case 'subgroupcode':
        setExtraSubGroupCode(val as string[]);
        break;

      default:
        break;
    }
  };

  const onFilterValueChange = (val: any) => {
    // console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    // console.log('limit -->', limit);
    fetchProductList({ top: limit });
    setPageTop(limit);
  };

  const onSearchCodeChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSelectGroups = (groups: number[], option: any) => {
    setSearchGroups(groups);
    setSearchGroupsStr(option ? option.map((i: any) => i.label) : []);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
        //case CODE_OPTION_STYLE_EQUALS:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (
      value === CODE_OPTION_SKU_LIST ||
      value === CODE_OPTION_COLOR_LIST ||
      value === CODE_OPTION_STYLE_LIST ||
      value === CODE_OPTION_UPC_LIST
    ) {
      setSearchMultiCode('');
    }
    codeInputRef?.current?.select();
    codeInputRef?.current?.focus();
  };

  const onSelectTags = (value: string) => {
    let str: string = value
      .trim()
      .replace(/[\r\n]/g, ",")
      .replace(/,+/g, ",")
      .replace("/,$/gi", "");
    const tags = str.split(",");
    setSearchTags(tags.filter((item) => item));
  };

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;
    setSelected(selected);
    if (selected === true && !unselected) {
      setSelectedRows(data as any);
    } else {
      if (!unselected) {
        setSelectedRows(Object.values(selected as any));
      } else {
        setSelectedRows((prev) => {
          return prev.filter((item) => item.ProductId !== (data as any).id);
        });
      }
    }
  }, []);

  const onSkipChange = (skip: number) => {
    // console.log('skip -->', skip);
    fetchProductList({ skip });
    setPageSkip(skip);
  };

  const onSortChange = async (info: any) => {
    // console.log('-->', info);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    //console.log('p ->', data, param);
    return <GroupDetail row={data} />;
  };

  // eslint-disable-next-line
  const searchDisplayCol1Options = () => {
    return [
      {label: 'All', value: CW_VIEW_ALL},
      {label: 'Custom view', value: CW_VIEW_CUSTOM},
      {label: 'Group', value: CW_VIEW_GROUP},
    ];
  };

  // eslint-disable-next-line
  const searchDisplayCol2Options = () => {
    if (displayType === CW_VIEW_CUSTOM) return displayViewOptions;

    if (displayType === CW_VIEW_GROUP) {
      return groupList.map(g => ({
        label: g.enumName,
        value: g.enumValue,
      })) as any;
    }

    return [];
  };

  const searchStyleVariationOptions = (k: string) => {
    let ret: any[] = [];

    if (Array.isArray(styleVariation[k])) {
      ret = styleVariation[k].map((c: StringKAnyVPair) => ({
        //label: c.description || c.code,
        label: c.code,
        value: c.code,
      }));
    }

    return ret;
  };

  // eslint-disable-next-line
  const searchPanelFilters = () => {
    return [
      <>
        <FormLabel>Bundle Type</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('bundletype', val as number)}
          options={bundleTypeOptions}
          value={extraBundleType}
        />
      </>,
      <>
        <FormLabel>Category</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('categorycode', val)}
          options={searchStyleVariationOptions('categoryCode')}
          mode="multiple"
          value={extraCategoryCode}
        />
      </>,
      <>
        <FormLabel>Subcategory</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subclasscode', val)}
          options={searchStyleVariationOptions('subclassCode')}
          mode="multiple"
          value={extraSubClassCode}
        />
      </>,
      <>
        <FormLabel>Class</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('classcode', val)}
          options={searchStyleVariationOptions('classCode')}
          mode="multiple"
          value={extraClassCode}
        />
      </>,
      <>
        <FormLabel>Group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('groupcode', val)}
          options={searchStyleVariationOptions('groupCode')}
          mode="multiple"
          value={extraGroupCode}
        />
      </>,
      <>
        <FormLabel>Subgroup</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subgroupcode', val)}
          options={searchStyleVariationOptions('subgroupCode')}
          mode="multiple"
          value={extraSubGroupCode}
        />
      </>,
      <>
        <FormLabel>Division</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('divisioncode', val)}
          options={searchStyleVariationOptions('divisionCode')}
          mode="multiple"
          value={extraDivisionCode}
        />
      </>,
      <>
        <FormLabel>Department</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('departmentcode', val)}
          options={searchStyleVariationOptions('departmentCode')}
          mode="multiple"
          value={extraDepartmentCode}
        />
      </>,
      <>
        <FormLabel>Manufacturer</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('manufacturer', val)}
          options={searchStyleVariationOptions('manufacturer')}
          mode="multiple"
          value={extraManufacturer}
        />
      </>,
      <>
        <FormLabel>Model</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('model', val)}
          options={searchStyleVariationOptions('model')}
          mode="multiple"
          value={extraModel}
        />
      </>,
      <>
        <FormLabel>Alternate</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('alternate', val)}
          options={extraAlternateOptions as any[]}
          mode="multiple"
          value={extraAlternateCode}
        />
      </>,
      <>
        <FormLabel>Remark</FormLabel>
        <Input
          allowClear
          onChange={(evt) => onExtraFilterFieldChange('remark', evt.target.value)}
          value={extraRemark}
        />
      </>,
      <>
        <FormLabel>Product Year</FormLabel>
        <Input
          allowClear
          //onChange={(val) => onExtraFilterFieldChange('productyear', val)}
          onChange={(evt) => onExtraFilterFieldChange('productyear', evt.target.value)}
          //options={[{value: '2022'}, {value: '2023'}, {value: '2024'}]}
          value={extraProductYear}
        />
      </>,
      <>
        <FormLabel>Gender</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('gender', val)}
          options={searchStyleVariationOptions('gender')}
          mode="multiple"
          value={extraGender}
        />
      </>,
      <>
        <FormLabel>Age group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('agegroup', val)}
          options={searchStyleVariationOptions('ageGroup')}
          mode="multiple"
          value={extraAgeGroup}
        />
      </>,
      <>
        <FormLabel>Country of Origin</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('countryoforigin', val)}
          options={searchStyleVariationOptions('countryOfOrigin')}
          mode="multiple"
          value={extraCountryOfOrigin}
        />
      </>,
    ];
  };

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  // eslint-disable-next-line
  const {
    data: mappedAccounts,
    isFetching: isFetchingMappedAccounts,
  } = useListMappedAccountsQuery();

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: (new Date()).getTime(),
    };
    setFilterPanelVisible(true);
  };

  const onSearchbarFocus = () => {
    let ctn = getCodeInputWrapperDom();

    if (ctn) {
      ctn = ctn.parentNode.parentNode.parentNode.parentNode.parentNode;
      const paddingLeft = 14;
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      //setFilterPanelOutline({ x: rect.x, y: rect.y + rect.height, width: rect.width });
      setFilterPanelOutline({
        x: rect.x - paddingLeft,
        y: rect.y + rect.height,
        width: rect.width + paddingLeft * 2,
      });

      if (!filterPanelVisible) {
        showSearchFilterPanel();
      }
    }
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearExtraFilters = () => {
    setExtraAgeGroup([]);
    setExtraAlternateCode([]);
    setExtraBundleType(undefined);
    setExtraCategoryCode([]);
    setExtraClassCode([]);
    setExtraCountryOfOrigin([]);
    setExtraDepartmentCode([]);
    setExtraDivisionCode([]);
    setExtraGender([]);
    setExtraGroupCode([]);
    setExtraManufacturer([]);
    setExtraModel([]);
    setExtraProductYear('');
    setExtraRemark('');
    setExtraSubClassCode([]);
    setExtraSubGroupCode([]);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  const clearSearchBrand = () => {
    setSearchBrand('');
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle('');
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const showSearchConditionTags = () => {
    const ccfLabels: string[] = ccfList
      .filter(c => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map(c => c.CHNLCtrlFlag);
    const labels: string[] = labelList.filter(l => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map(l => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter(e => searchStatus.indexOf(e.code) > -1)
      .map(e => e.description);

    return (
      <Space>
        <TableFilter columns={stateColumns} dataSource={filteredData} setFilteredData={setFilteredTempData}   />
        {searchTitle.trim() && (
          <Tag
            closable
            onClose={clearSearchTitle}
          >
            <span className="field-label">Product Name</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag
            closable
            onClose={() => setSearchStatus([])}
          >
            <span className="field-label">Exclude Status</span>
            {statusLabels.join(', ')}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(', ')}
          </Tag>
        )}
        {searchBrand.trim() && (
          <Tag
            closable
            onClose={clearSearchBrand}
          >
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Tags</span>
            {labels.join(', ')}
          </Tag>
        )}
        {searchGroupsStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchGroups([]);
              setSearchGroupsStr([]);
              setSearchGroupsVersion(searchGroupsVersion + 1);
            }}
          >
            <span className="field-label">Group</span>
            {searchGroupsStr.join(", ")}
          </Tag>
        )}
        {searchTags.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchTags([]);
              setSearchTagVersion(searchTagVersion + 1);
              // setSearchTagsStr('')
            }}
          >
            <span className="field-label">Tag</span>
            {searchTags.join(", ")}
          </Tag>
        )}
        {typeof extraBundleType === 'number' && renderFilterTag(
          'Bundle Type',
          getBundleTypeLabel(extraBundleType),
          () => setExtraBundleType(undefined),
        )}
        {extraCategoryCode.length > 0 && renderFilterTag(
          'Category',
          extraCategoryCode,
          () => setExtraCategoryCode([]),
        )}
        {extraSubClassCode.length > 0 && renderFilterTag(
          'Subcategory',
          extraSubClassCode,
          () => setExtraSubClassCode([]),
        )}
        {extraClassCode.length > 0 && renderFilterTag(
          'Class',
          extraClassCode,
          () => setExtraClassCode([]),
        )}
        {extraGroupCode.length > 0 && renderFilterTag(
          'Group',
          extraGroupCode,
          () => setExtraGroupCode([]),
        )}
        {extraSubGroupCode.length > 0 && renderFilterTag(
          'Subgroup',
          extraSubGroupCode,
          () => setExtraSubGroupCode([]),
        )}
        {extraDivisionCode.length > 0 && renderFilterTag(
          'Division',
          extraDivisionCode,
          () => setExtraDivisionCode([]),
        )}
        {extraDepartmentCode.length > 0 && renderFilterTag(
          'Department',
          extraDepartmentCode,
          () => setExtraDepartmentCode([]),
        )}
        {extraManufacturer.length > 0 && renderFilterTag(
          'Manufacturer',
          extraManufacturer,
          () => setExtraManufacturer([]),
        )}
        {extraModel.length > 0 && renderFilterTag(
          'Model',
          extraModel,
          () => setExtraModel([]),
        )}
        {extraAlternateCode.length > 0 && renderFilterTag(
          'Alternate',
          extraAlternateCode,
          () => setExtraAlternateCode([]),
        )}
        {extraRemark.trim() && renderFilterTag(
          'Remark',
          extraRemark,
          () => setExtraRemark(''),
        )}
        {extraProductYear.trim() && renderFilterTag(
          'Product Year',
          extraProductYear,
          () => setExtraProductYear(''),
        )}
        {extraGender.length > 0 && renderFilterTag(
          'Gender',
          extraGender,
          () => setExtraGender([]),
        )}
        {extraAgeGroup.length > 0 && renderFilterTag(
          'Age Group',
          extraAgeGroup,
          () => setExtraAgeGroup([]),
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
        <Tag>
          <span className="field-label">Mode</span>
          {isCompactMode === 1 ? 'Compact Mode' : 'Image Mode'}
        </Tag>
      </Space>
    );
  };

  useEffect(() => {
    if (!inited) {
      //fetchProductList();
      fetchChannelControlFlags();
      fetchLabels();
      getGroupList();
      loadInitialData();
      setInited(true);
    }
  }, [fetchProductList, inited, loadInitialData]);

  const ModeSelector = () => {
    return (
      <>
        <FormLabel>Mode</FormLabel>
        <Select
          style={{ width: '100%' }}
          onChange={(value) => setIsCompactMode(value)}
          value={isCompactMode}
        >
          <Select.Option value={1}>Compact Mode</Select.Option>
          <Select.Option value={0}>Image Mode</Select.Option>
        </Select>
      </>
    )
  }

  return (
    <>
      <ContentLayout>
        <Heading title="Image Master List" />
        <Spacer />
        <SiteContent noPadding flexGrow transparent>
          <Row className="content-section" justify="space-between">
            <SearchCol>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row className="search-element-area">
                  <Row>
                    {productListStyle !== "2" && (
                      <Row align="middle" className="display-category-field">
                        <span className="field-label" style={{ paddingRight: 0 }}>Display</span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style
                          </Radio>
                          {
                            productListStyle !== '1' && (
                              <Radio
                                // disabled={!isCommonGroupOption(searchCodeType)}
                                value={GROUP_OPTION_COLOR}
                              >
                                Substyle
                              </Radio>
                            )
                          }
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>
                        </Radio.Group>
                      </Row>
                    )
                    }
                    {isCodeListType(searchCodeType) && (
                      <StyleInputWrapper className="img-field">
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={filterPanelVisible ? 'code-selector-active' : ''}
                          //disabled
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          readOnly
                          ref={codeInputRef}
                          value={searchMultiCode.replace(/\n/g, ', ')}
                        />
                      </StyleInputWrapper>
                    )}
                    {!isCodeListType(searchCodeType) && (
                      <StyleInputWrapper className="img-field">
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={filterPanelVisible ? 'code-selector-active' : ''}
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          ref={codeInputRef}
                        />
                      </StyleInputWrapper>
                    )}
                  </Row>
                  <div className="action-btn-wrap">
                    <Space>
                      <Dropdown overlay={importMenu()} placement="bottomLeft">
                        <Button
                          disabled={isFetchingMappedAccounts}
                          loading={isFetchingMappedAccounts}
                        >
                          <CloudUploadOutlined />
                          Import
                        </Button>
                      </Dropdown>
                      {filteredData.length > 0 && (
                        <Button disabled={isFetching} onClick={() => setExportModalVisible(true)}>
                          <CloudDownloadOutlined />
                          Export
                        </Button>
                      )}
                    </Space>
                  </div>
                </Row>
                <Row align="middle" className="search-btn-row" justify="space-between" style={{ marginTop: 6, overflowX: 'auto' }}>
                  {searchBegun && showSearchConditionTags()}
                </Row>
              </Space>
            </SearchCol>
          </Row>
          {searched ? (
            <Spacer height={14} />
          ) : (
            SearchTips(600, 300, "Input your search criteria", 130)
          )}
          {searched && <DataGrid
            idProperty="ProductId"
            rowHeight={35}
            columns={gridColumns(searchTreeMode)}
            dataSource={dataSource}
            defaultFilterValue={filterValue}
            defaultLimit={DEFAULT_GRID_LIMIT}
            disableLoadingIcon={filterPanelVisible}
            enableFiltering={false}
            filterTypes={gridFilterTypes}
            limit={pageTop}
            loadNode={loadNextLevelProducts}
            onNodeExpand={(arg:any) => {
              const { index } = arg
              const temp = [...filteredTempData]
              temp[index] = {...temp[index], nodes: null}
              setFilteredTempData([...temp])
            }}
            loading={isFetching || filterPanelVisible}
            onFilterValueChange={onFilterValueChange}
            onLimitChange={onLimitChange}
            onSelectionChange={onSelectionChange}
            onSkipChange={onSkipChange}
            onSortInfoChange={onSortChange}
            pageSizes={[20, 30, 50, 100, 200]}
            pagination
            renderRowDetails={renderRowDetails}
            // style={{ height: '100%' }}
            checkboxOnlyRowSelect
            rowExpandColumn={searchGroupMode}
            rowExpandHeight={300}
            scrollProps={{ autoHide: false, }}
            // selected={selectedRows}
            skip={pageSkip}
            sortable={false}
            treeColumn={searchTreeMode ? 'mediaURL' : undefined}
            checkboxColumn
            selected={selected}
          />}
        </SiteContent>
        {/* {detailVisible && (
        <Modal
          bodyStyle={{ backgroundColor: '#F0F2F5', padding: 12 }}
          centered
          className="fullscreen-modal"
          closable={false}
          footer={
            detailDialogIsFullscreen ? (
              <Row justify="end">
                <Button onClick={closeProductDetailDialog}>Cancel</Button>
              </Row>
            ) : null
          }
          onCancel={closeProductDetailDialog}
          style={{ paddingBottom: 0 }}
          //title={currentProduct.SKU}
          title={
            <Row align="middle" justify="space-between">
              <Link to={`/product-detail/${currentProductId}`}>
                <span>Product - {currentProduct.ProductTitle || currentProduct.SKU}</span>
              </Link>
              <Space>
                <Button
                  type="link"
                  href={`/product-detail/${currentProductId}`}
                  target="_blank"
                  style={{ border: '1px solid #D9D9d9' }}
                >
                  Open As a New Tab
                  <LinkOutlined />
                </Button>
                <Button onClick={toggleDetailDialogFullscreen}>
                  {detailDialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
                  {detailDialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                </Button>
                <Button onClick={closeProductDetailDialog}>
                  Close
                  <CloseOutlined />
                </Button>
              </Space>
            </Row>
          }
          visible={detailVisible}
          width={detailDialogWidth()}
        >
          <ProductDetailDialogBodyWrapper
            className={detailDialogIsFullscreen ? 'fullscreen-mode' : ''}
          >
            <ProductDetail
              isCreating={false}
              isFullscreen={detailDialogIsFullscreen}
              productId={currentProductId}
            />
          </ProductDetailDialogBodyWrapper>
        </Modal>
      )} */}
        <SearchFilterPanel
          channelFlagVersion={channelFlagVersion}
          clearChannelFlags={clearChannelFlags}
          clearExtraFilter={() => {
            clearExtraFilters();
            setSearchGroups([]);
            setSearchGroupsStr([]);
            setSearchTags([]);
            setSearchTagVersion(searchTagVersion + 1);
          }}
          clearLabels={clearLabels}
          clearSearchBrand={clearSearchBrand}
          clearSearchTitle={clearSearchTitle}
          extraFilter={searchPanelFilters()}
          extraSorter={[ <ModeSelector /> ]}
          groupList={groupList}
          handleSearch={handleSearchProducts}
          hidePanel={hideSearchFilterPanel}
          labelVersion={labelVersion}
          onGroupChange={onSelectGroups}
          onMouseEnter={onMouseEnterFilter}
          onMouseLeave={onMouseLeaveFilter}
          onTagChange={(value: string) => onSelectTags(value || '')}
          searchBrandVersion={searchBrandVersion}
          searchCodeType={searchCodeType}
          searchGroupsVersion={searchGroupsVersion}
          searchMultiCode={searchMultiCode}
          searchTagVersion={searchTagVersion}
          searchTitleVersion={searchTitleVersion}
          setBrand={setSearchBrand}
          setLabels={setSearchLabels}
          setMultiCode={setSearchMultiCode}
          setSalesChannel={setSearchCCFs}
          setSortType={setSearchSorter}
          setStatus={setSearchStatus}
          setTitle={setSearchTitle}
          showPanel={showSearchFilterPanel}
          status={searchStatus}
          statusOptions={getOperationStatusOptions().map(e => ({ label: e.description, value: e.code }))}
          x={filterPanelOutline.x || 0}
          y={filterPanelOutline.y || 0}
          visible={filterPanelVisible}
          width={filterPanelOutline.width || 0}
          //ExtraFilter={[<ModeSelector />]}
        />
        {detailVisible && (
          <ProductDetailDialog
            onClose={closeProductDetailDialog}
            product={currentProduct}
            visible={detailVisible}
          />
        )}
        {exportModalVisible && (
          <ExportMdal
            templateInfo={[]}
            onClose={() => setExportModalVisible(false)}
            data={filteredData.filter((i) => i.ProductId.indexOf('/') < 0)}
            fileName="Image"
            getSearchOptions={getSearchOptions}
            filterArr={
              searchGroup === 2
                ? ['Image', 'Substyle', 'SKU', 'Status']
                : searchGroup === 1
                  ? ['Image', 'SKU', 'Status']
                  : searchGroup === 4
                    ? ['Image', 'Style', 'Substyle', 'Status']
                    : ['Image', 'Status']
            }
            selectedRows={selectedRows.filter((i) => i.ProductId.indexOf('/') < 0)}
            columns={gridColumns(searchTreeMode)}
          />
        )}

        {imageModalVisible && currentImage && (
          <ImageModal
            attributeName={currentImage.attributeName}
            imageUrl={currentImage.imageUrl}
            productId={currentImage.productId}
            imageAttributeNum={currentImage.imageAttributeNum}
            productType={currentImage.productType}
            defaultOverwrite={overwriteChild}
            onClose={() => {
              setImageModalVisible(false);
              setCurrentImage(undefined);
            }}
            onSuccess={(url) => {
              const temp: any[] = [...filteredData];
              const index = temp.findIndex((i) => i.ProductId === currentImage.productId);
              if (index >= 0) {
                temp[index][`${currentImage.name}`] = url;
                setFilteredData([...temp]);
              }
              setImageModalVisible(false);
              setCurrentImage(undefined);
            }}
          />
        )}
      </ContentLayout>
      {importDialogVisible && <ImportModal close={closeImportDialog} options={importOptions} />}
    </>
  );
};

export default PageContent;

import { Form, Modal, Spin, message, Row, Button, notification } from "antd";
import { InboxOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import { Permissions } from "../../constants/enums/permissions";
import FormButtons from "../../components/common/FormButtons";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/es/upload";
import { uploadContentSource2, getContentResourceTemplate } from "../../services/copywriting";
import {  HTTP_STATUS_OK } from '../../constants/config';
import { downloadFile } from '../../util/files';


interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  visible: boolean;
}

function ModalForm({ visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();

  const  downloadTemplate = async ()=> {
    let template;
    let fileName = "content-resource-mapping-template.xlsx";
    try {
      setLoadingTemplate(true)
      const { data, status }  = await getContentResourceTemplate()
      setLoadingTemplate(false)
      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = data;
        // fileName = getFileNameFromHeader(headers);
      }

    } catch (error) {
      setLoadingTemplate(false)
    }
    if (!template) {
      notification.error({ message: 'No template information' });
      return;
    }

    downloadFile(
      fileName,
      template,
    );
  }

  const handleBeforeSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (files.length === 0 || !files[0].originFileObj) {
      return;
    }
    try {
      setProcessing(true);
      const res = await uploadContentSource2(files[0].originFileObj);
      if (res.isSuccess) {
        message.success('Save successfully');
        onSuccess();
      } else {
        message.error(res.message || res.Message);
      }
      form.resetFields();
    } catch (error) {
      // TODO: something
      message.error(
        (error as any).response.data.message ||
          (error as any).response.data.Message
      );
    } finally {
      setProcessing(false);
    }
  };

  const onFilesChange = useCallback(
    (info: UploadChangeParam) => {
      form.resetFields();
      setFiles(info.fileList.slice(-1));
      form.setFieldsValue({
        fileCount: info.fileList.length === 0 ? undefined : 1,
      });
    },
    [form]
  );

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={
      <Row justify="space-between">
        <h3>Import Content Resource Mapping</h3>
        <Button onClick={()=>{downloadTemplate()}} loading={loadingTemplate} type="primary" ghost>
          Download Template
          <CloudDownloadOutlined />
        </Button>
      </Row>}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={600}
      footer={
        <FormButtons
          permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
          onSave={form.submit}
          onCancel={onClose}
          editingMode
          style={{ justifyContent: "flex-end" }}
        />
      }
    >
      <Spin spinning={processing}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          initialValues={{ size: "middle" }}
          onFinish={handleBeforeSubmit}
        >
          <Form.Item
            name="fileCount"
            rules={[{ required: true, message: "file is required" }]}
          >
            <Dragger
              fileList={files}
              onChange={onFilesChange}
              beforeUpload={() => false}
              accept=".xls, .xlsx"
              name="file"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag files to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ModalForm;

import { ProfilesState } from '../types';
import { ProfilesAction, ProfilesActionType } from '../types/profiles';

const initialState: ProfilesState = {
  profiles: [
    {
      Email: '',
      ProfileNum: 0,
      MasterAccountNum: 0,
      DisplayName: '',
    },
  ],
  selectedIndex: 0,
  permissions: new Map(),
  isProfileSelected: false,
};

const profiles = (state = initialState, action: ProfilesActionType) => {
  switch (action.type) {
    case ProfilesAction.SET_PROFILES:
      return { ...state, profiles: action.profiles.map((i, idx)=>{return {...i, index: idx}}) };
    case ProfilesAction.SET_PERMISSIONS:
      return { ...state, permissions: action.permissions };
    case ProfilesAction.SET_SELECTED_INDEX:
      return { ...state, selectedIndex: action.selectedIndex };
    case ProfilesAction.SET_IS_PROFILE_SELECTED:
      return { ...state, isProfileSelected: action.isProfileSelected };
    default:
      return state;
  }
};

export default profiles;

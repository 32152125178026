import React, { ReactNode, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
//import { Link } from 'react-router-dom';
import { Badge, Dropdown, Layout, Menu, Popover, Button, Row, Select, Typography } from 'antd';
//import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import '../../assets/styles/layout.less';
// import DesignService from '../../assets/icons/design_service';
import AvatarSVG from '../../assets/icons/avatar';
//import CCSVG from '../../assets/icons/CC_bg';
//import DSCSVG from '../../assets/icons/DSC_bg';
//import ERPSVG from '../../assets/icons/ERP_bg';
//import PIMSVG from '../../assets/icons/PIM_bg';
//import WMSSVG from '../../assets/icons/WMS_bg';
import _profileActions from '../../redux/actions/profiles';
import _routesActions from '../../redux/actions/routes';
import _sidebar from '../../redux/actions/sidebar';
import { fetchUserPermissions } from '../../services/userManagement';
import MenuIcon from '../../assets/icons/menu';
import Settings from '../../assets/icons/settings';
import Shipping from '../../assets/icons/shipping';
import {
  ENTRANCE_PORTAL,
  getPortalProfiles,
  getEntrance,
  getSelectedProfile,
  isPortalActive,
  requestPortalOpenNewTab,
  switchProfile,
} from '../../util/authenticate';
import linkERP from '../../util/linkERP';
import { logout } from '../../util';
import { clearCachedData } from '../../screens/DetailProduct/helper';
import VersionModal from '../common/VersionModal';
import { appLinkCfg } from '../../constants/AppLinkConfig';
import { LS_THEME_CODE } from '../../constants/config';
import EoorMessageBox from '../ErrorMessageBox';

const { Option } = Select;
const { Header, Content } = Layout;

const sortProfileString = (a: any, b: any) => {
  const displayNameA = a?.DisplayName || a?.displayName || '';
  const displayNameB = b?.DisplayName || b?.displayName || '';
  const strA = displayNameA.toUpperCase() || ''; // ignore upper and lowercase
  const strB = displayNameB.toUpperCase() || ''; // ignore upper and lowercase

  if (strA < strB) {
    return -1;
  }

  if (strA > strB) {
    return 1;
  }

  return 0;
};

const HeaderContainer = styled(Header)`
  width: 100%;
  height: 55px;
  box-shadow: 0 -1px 8px 0 rgba(125, 140, 166, 0.08);
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 20px;
`;

// const VerticalDivider = styled.div`
//   position: relative;
//   display: inline-block;
//   height: 2em;
//   margin-right: 16px;
//   vertical-align: middle;
//   width: 3pt;
//   border-radius: 3pt;
//   background-color: #414141;
//   opacity: 85%;
//   font-size: 14px;
//   font-variant: tabular-nums;
//   line-height: 1.5715;
//   list-style: none;
//   font-feature-settings: 'tnum';
// `;

const HeaderActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ContentContainer = styled(Content)`
  overflow-y: auto;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
`;

const LinkPanel = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  padding: 12px 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  a {
    color: #1F1F1FD9;
  }

  & .app-abbr-name-ctn {
    font-size: 13px;
    font-weight: 600;
  }

  & .app-full-name-ctn {
    font-size: 12px;
  }

  & .app-item-ctn {
    padding: 10px;
  }

  & .app-title-bar {
    font-size: 16px;
    font-weight: 500;
  }

  & .item-linker {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 10px;
  }

  & .item-linker:hover {
    background-color: rgba(0, 0, 0, .15);
    cursor: pointer;
  }

  & .link-grid-ctn {
    display: grid;
    gap: 15px;
    grid-template-columns: 280px 280px;
    row-gap: 15px !important;
  }
`;

const ProfilesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & .ant-select-arrow{
    display: none;
  }

  & .menu-trigger-icon {
    margin-right: 8px;
    padding: 4px 0;
  }

  & .menu-trigger-icon:hover {
    color: #1677FF;
  }
`;

const ProfileSelect = styled(Select)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  // line-height: 1.57;
  letter-spacing: normal;
  // color: #606060 !important;
`;

// const IconSpacer = styled.div`
//   margin-right: 16px;
//   display: flex;
// `;

const ButtonSpacer = styled(Button)`
  margin-right: 16px;
  display: flex;
  padding: 0px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  & .header-btn0 .ant-btn-icon-only {
    padding-top: 0;
  }

  & .header-btn1 {
    padding-top: 1px !important;
  }

  & .header-btn2 {
    padding-top: 2px !important;
  }
`;

const ActionItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: black;
  font-size: 14px;
`;

const ButtonNoBorder = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover{
    color:#006dff;
    text-decoration: underline;
  }
`;

interface Props {
  children: ReactNode,
  profilesEntity: {
    profiles: any,
    selectedIndex: number
  },
  profileActions: any,
  routesActions: any,
  sidebarActions: any,
  isModal?: any,
  collapse: boolean,
}

const LayoutContainer = styled(Layout) <{ $isModal: boolean, $collapse: boolean }>`
  margin-left: ${(props) => props.$isModal || props.$collapse ? '80px' : '230px'};
  height: 100vh;
`;

const ProfileDropdownMenu = styled(Menu)`
    width: 300px;
    padding: 4px;
  & .ant-dropdown-menu-item:hover,
  & .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
  & .ant-dropdown-menu-title-content:not(:has(${ActionItemWrapper})),
  & .profile-menu-label {
    color: #606060;
    font-size: 13px;
    &:hover {
      color:#006dff;
      text-decoration: underline;
    }
  }
`;
// const languages = (
//   <Menu>
//     <Menu.Item key="USA" icon={<USAIcon style={{ marginRight: '5px' }} />}>
//       US - English
//     </Menu.Item>
//   </Menu>
// );

function ContentLayout(
  {
    children,
    profilesEntity,
    profileActions,
    routesActions,
    sidebarActions,
    isModal,
    collapse,
  }: Props,
) {
  const { Text } = Typography;
  const envMode = process.env.MODE || '';
  const [appLinks, setAppLinks] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isEntrancePortal, setIsEntrancePortal] = useState(false);
  const [pimType, setPimType] = useState(false);
  const [versionModalVisible, setVersionModalVisible] = useState<boolean>(false);
  const profile = useSelector((state: any) => state.profiles.profiles[0]);
  const appList = React.useMemo(() => {
    return envMode === 'admin' ?  appLinks.filter(e => e.id === 'ADMIN-DB' ? false : true) :  appLinks.filter(e => (pimType && e.id === 'PIM-DB') || (!pimType && e.id === 'CC-DB') ? false : true)
  }, [appLinks, pimType, envMode]);

  // eslint-disable-next-line
  const getAppLinks = async () => {
    const pfs = await getPortalProfiles();
    const p = getSelectedProfile();

    console.log('ps', pfs, p);
    if (p) {
      const ps = pfs.filter(e => p.profileNum === e.profileNum && p.masterAccountNum === e.masterAccountNum);

      if (ps.length > 0) {
        const pe = ps[0];

        if (Array.isArray(pe.appPermissions)) {
          const apps = pe.appPermissions.filter((e: StringKAnyVPair) => e.appEnable === 1 && appLinkCfg[e.appID])
            .map((e: StringKAnyVPair) => appLinkCfg[e.appID]);

          console.log('apps', apps);
          setAppLinks(apps);
        }
      }
    }
  };

  const otherLinkItem = (app: StringKAnyVPair) => {
    return isEntrancePortal ? (
      <Row
        className="item-linker"
        key={app.id}
        onClick={() => requestPortalOpenNewTab(app.id, app.url)}
      >
        <div className="app-">{app.icon}</div>
        <div>
          <div className="app-abbr-name-ctn">{app.name}</div>
          <div className="app-full-name-ctn">{app.summary}</div>
        </div>
      </Row>
    ) : (
      <a key={app.id} href={app.url} target="_blank" rel="noreferrer">
        <Row className="item-linker">
          <div className="app-">{app.icon}</div>
          <div>
            <div className="app-abbr-name-ctn">{app.name}</div>
            <div className="app-full-name-ctn">{app.summary}</div>
          </div>
        </Row>
      </a>
    );
  };

  const otherLinkMenu = () => {
    const yaGrps = appList.filter(e => e.group === 'yourApps');
    const cpGrps = appList.filter(e => e.group === 'customerPortal');
    const vpGrps = appList.filter(e => e.group === 'venderPortal');

    return (
      <LinkPanel>
        {yaGrps.length > 0 && <>
        <div className="app-title-bar">Your apps</div>
        <div className="app-item-ctn">
          <Row className="link-grid-ctn" gutter={[15, 15]}>
            {yaGrps.map(app => otherLinkItem(app))}
          </Row>
        </div>
        </>}
        {cpGrps.length > 0 && <>
        <div className="app-title-bar">Your Customers' Apps</div>
        <div className="app-item-ctn">
          <Row className="link-grid-ctn" gutter={[15, 15]}>
            {cpGrps.map(app => otherLinkItem(app))}
          </Row>
        </div>
        </>}
        {vpGrps.length > 0 && <>
        <div className="app-title-bar">Your Venders' Apps</div>
        <div className="app-item-ctn">
          <Row className="link-grid-ctn" gutter={[15, 15]}>
            {vpGrps.map(app => otherLinkItem(app))}
          </Row>
        </div>
        </>}
      </LinkPanel>
    );
  };

  const onOtherLinkPanelVisibleChange = (visible: boolean) => {
    if (visible) {
      //setIsEntrancePortal(getEntrance() === ENTRANCE_PORTAL);
      setIsEntrancePortal(isPortalActive());
    }
  };

  const selectTheme = async (theme: string) => {
    localStorage.setItem(LS_THEME_CODE, theme);
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  const profileUnionNumString = (p: StringKAnyVPair) => {
    return `${p.masterAccountNum || p.MasterAccountNum}_${p.profileNum || p.ProfileNum}`;
  };

  // eslint-disable-next-line
  const profileMenuItem = (p: StringKAnyVPair, index: number) => {
    return (
      <Menu.Item onClick={() => handleChange(index)} key={`p2-${profileUnionNumString(p)}`}>
        {p.displayName || p.DisplayName}
      </Menu.Item>
    );
  };

  const profileSelector = React.useCallback(() => {
    const { selectedIndex, profiles } = profilesEntity;
    const fs = profiles[selectedIndex];
    let title = '';
    if (fs && fs.DisplayName) {
      title = fs.DisplayName;
    }

    if (profiles.length <= 1) return null;

    return (
      <Menu.SubMenu
        className="ant-typography-ellipsis"
        key="profileMenus"
        popupOffset={[-3, -3]}
        title={<Text className="profile-menu-label" ellipsis={{ tooltip: title }}>Switch Profile</Text>}
      >
        {profilesEntity.profiles.map((e: any, index: number) => profileMenuItem(e, index))}
      </Menu.SubMenu>
    );
  }, [Text, profileMenuItem, profilesEntity]);

  // eslint-disable-next-line
  const themeMenuItem = (theme: StringKAnyVPair) => {
    return (
      <Menu.Item onClick={() => selectTheme(theme.code)} key={theme.code}>
        {theme.name}
      </Menu.Item>
    );
  };

  const themeSelector = React.useCallback(() => {
    // const fs = themeList.filter(e => e.code === theme);
    // let title = DEFAULT_THEME;

    // if (fs.length > 0) {
    //   title = fs[0].name;
    // }

    const themeList = [
      { code: 'old', name: 'Classic' },
      { code: 'new', name: 'New' }
    ]

    return (
      <Menu.SubMenu
        key="themeMenus"
        popupOffset={[-3, -3]}
        title="Theme"
      >
        {themeList.map(e => themeMenuItem(e))}
      </Menu.SubMenu>
    );
  }, [themeMenuItem]);

  const userActions = (
    <ProfileDropdownMenu>
      <Menu.Item key="userEmail">
        {/* {profile.Email} */}
        <ActionItemWrapper>
          {profile.Email}
          <ButtonNoBorder type='button' onClick={logout}>Logout</ButtonNoBorder>
        </ActionItemWrapper>
      </Menu.Item>
      {profileSelector()}
      {themeSelector()}
      {/* <Menu.Item key="logout" onClick={logout}>
        Logout
      </Menu.Item> */}
      <Menu.Item key="about" onClick={() => setVersionModalVisible(true)}  >
        About
      </Menu.Item>
    </ProfileDropdownMenu>
  );

  const handleChange = async (index: any) => {
    const entrance = getEntrance();

    try {
      if (entrance === ENTRANCE_PORTAL) {
        switchProfile(index);
      }

      profileActions.setSelectedIndex(index);
      routesActions.setCurrentKey([]);
      const permissions = await fetchUserPermissions(profilesEntity.profiles[0].Email);
      profileActions.setPermissions(permissions);
      clearCachedData();
    } catch (e) {
      profileActions.setPermissions([]);
    }
    const getUrl = window.location;
    window.location.href = `${getUrl.protocol}//${getUrl.host}/${getUrl.pathname.split('/')[1]}`;
  };

  React.useEffect(() => {
    if (window.location.pathname.includes('/pim')) {
      setPimType(true)
    }

    if (!inited) {
      getAppLinks();
      setInited(true);
    }
  }, [getAppLinks, inited]);

  return (
    <LayoutContainer $isModal={isModal} $collapse={collapse}>
      <HeaderContainer>
        {/*React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          style: {
            marginRight: '15px',
          },
          onClick: () => sidebarActions.setCollapse(),
        })*/}
        <HeaderActionsWrapper>
          <ProfilesWrapper>
            {/* <DesignService
              height={32}
              width={32}
              color="#006dff"
              style={{
                borderRadius: '4px',
                border: '1px solid #d8dde6',
                padding: 5,
              }}
            /> */}
            {appList.length > 0 && <Dropdown
              onVisibleChange={onOtherLinkPanelVisibleChange}
              overlay={otherLinkMenu()}
              //overlayStyle={{backgroundColor: '#FFF'}}
              placement="bottomLeft"
            >
              <Button
                className="menu-trigger-icon"
                type="text"
              >
                <MenuIcon fill="currentColor" height="18px" width="18px" />
              </Button>
            </Dropdown>}
            {appList.length === 0 && <Button
                className="menu-trigger-icon"
                type="text"
              >
                <MenuIcon fill="currentColor" height="18px" width="18px" />
            </Button>}
            <ProfileSelect
              style={{minWidth:200}}
              onChange={handleChange}
              value={profilesEntity.selectedIndex}
              bordered={false}
              // filterSort={(optionA, optionB) => sortProfileString(optionA,optionB)}
              showSearch
              filterOption={(input: string, option: any): boolean => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                [...profilesEntity.profiles].sort(sortProfileString).map((p: any) => (
                  <Option key={`p1-${profileUnionNumString(p)}`} value={p.index}>{p.DisplayName || p.displayName}</Option>
                ))
              }
            </ProfileSelect>
          </ProfilesWrapper>
          <ActionsWrapper>
            {localStorage.getItem('PopUpExceptionMessage') === '0' && <EoorMessageBox />}
            <Popover content='Enterprise Resource Planning' title="ERP">
              <ButtonSpacer
                className="header-btn1"
                ghost
                onClick={(e) => {
                  e.preventDefault();
                  linkERP.open();
                }}
              >
                <Badge
                  count={0}
                  size="small"
                  style={{ backgroundColor: '#006dff' }}
                  offset={[0, 4]}
                >
                  <Settings
                    width={28}
                    height={28}
                    style={{
                      fill: '#414141',
                    }}
                  />
                </Badge>
              </ButtonSpacer>
            </Popover>
            <Popover content='Warehouse Management System' title="WMS">
              <ButtonSpacer
                className="header-btn2"
                ghost
                href={process.env.WMS_LINK}
                target="_blank"
              >
                <Shipping
                  width={28}
                  height={28}
                  style={{
                    fill: '#414141',
                  }}
                />
              </ButtonSpacer>
            </Popover>
            {/*<VerticalDivider />*/}
            {/*<IconSpacer>*/}
            {/*  <Badge*/}
            {/*    count={0}*/}
            {/*    size="small"*/}
            {/*    style={{ backgroundColor: '#006dff' }}*/}
            {/*    offset={[0, 4]}*/}
            {/*  >*/}
            {/*    <BubbleMessage*/}
            {/*      width={28}*/}
            {/*      height={28}*/}
            {/*      style={{*/}
            {/*        fill: '#414141',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Badge>*/}
            {/*</IconSpacer>*/}
            {/*<IconSpacer>*/}
            {/*  <Badge*/}
            {/*    count={0}*/}
            {/*    size="small"*/}
            {/*    style={{ backgroundColor: '#006dff' }}*/}
            {/*    offset={[-4, 4]}*/}
            {/*  >*/}
            {/*    <NotificationsIcon*/}
            {/*      width={28}*/}
            {/*      height={28}*/}
            {/*      style={{*/}
            {/*        fill: '#414141',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </Badge>*/}
            {/*</IconSpacer>*/}
            {/*<Dropdown.Button*/}
            {/*  className="headerIconDropdown"*/}
            {/*  overlay={languages}*/}
            {/*  placement="bottomCenter"*/}
            {/*  icon={(*/}
            {/*    <USAIcon width={28} height={28} />*/}
            {/*  )}*/}
            {/*  style={{ border: 'none', marginRight: '15px' }}*/}
            {/*/>*/}
            <Dropdown.Button
              className="headerIconDropdown header-btn0"
              overlay={userActions}
              placement="bottomCenter"
              icon={(
                // <Avatar src="https://cdn.onlinewebfonts.com/svg/img_569205.png" />
                <AvatarSVG />
              )}
            />
          </ActionsWrapper>
        </HeaderActionsWrapper>
      </HeaderContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
      <VersionModal show={versionModalVisible} onClose={() => setVersionModalVisible(false)} />
    </LayoutContainer>
  );
}

ContentLayout.defaultProps = {
  isModal: false,
};

const mapStateToProps = (state: any) => ({
  profilesEntity: state.profiles,
  collapse: state.sidebar.collapse,
});

const mapDispatchToProps = (dispatch: any) => ({
  profileActions: bindActionCreators(_profileActions, dispatch),
  routesActions: bindActionCreators(_routesActions, dispatch),
  sidebarActions: bindActionCreators(_sidebar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentLayout);

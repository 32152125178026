import React from 'react';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import { ClearOutlined, CloseOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import theme from '../../assets/styles/theme';
import { FormLabel, Label, WarningButton } from '../../components/common/styledComponents';
//import MaskOverlay from '../../components/common/MaskOverlay';
/*import {
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
} from '../../constants/config';*/
import { getContentResourceList } from '../../services/copywriting';
import LabelsSelector from '../ListProducts/LabelsSelector';
import ChannelControlFlagSelector from '../ListProducts/ChannelControlFlagSelector';
import BrandSelector from '../ListProducts/BrandSelector';
import SortSelector from '../ListProducts/SortSelector';
import {
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  isCodeListType,
} from './';

type Props = {
  channelFlagVersion: number;
  clearChannelFlags: Function;
  clearExtraFilter?: Function;
  clearLabels: Function;
  clearSearchBrand: Function;
  clearSearchTitle: Function;
  contentResourceFilter: StringKAnyVPair[];
  excludeSalesChannel?: JSX.Element;
  extraFilter?: JSX.Element[];
  extraSorter?: (JSX.Element | null)[];
  handleSearch: Function;
  hidePanel: Function;
  labelVersion: number;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  productListStyle?: string;
  searchBrandVersion: number;
  searchCode: string;
  searchCodeType: number;
  searchCodeTypeSelector: Function;
  searchGroup: number;
  searchMultiCode: string;
  searchTitleVersion: number;
  setBrand: Function;
  setContentResourceFilter: Function;
  setLabels: Function;
  setMultiCode: Function;
  setSalesChannel: Function;
  setSearchCode: Function;
  setSearchGroup: Function;
  setSortType: Function;
  setStatus: Function;
  setTitle: Function;
  showPanel: Function;
  status: number[];
  statusOptions: StringKAnyVPair[];
  x: number;
  y: number;
  visible: boolean;
  width: number;
  groupList: any[];
  onGroupChange: Function;
  onTagChange: Function;
  searchTagVersion: number;
  searchGroupsVersion: number;
};

const PanelWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: absolute;

  & textarea.ant-input {
    height: 120px;
  }

  & .ant-divider {
    margin: 8px;
  }

  & .ant-select {
    width: 100%;
  }

  & .bottom-divider {
    margin-left: 0;
  }

  & .bottom-searchbox {
    margin-top: 3px;
  }

  & .bottom-toolbar {
    margin: 6px 4px;
  }

  & .filter-body {
    margin: 12px;
  }

  & .filter-body.filter-ctn1 {
    height: calc(100vh - 310px);
    overflow-y: auto;
  }

  & .filter-body.filter-ctn2 {
    height: calc(100vh - 398px);
    overflow-y: auto;
  }

  & .filter-form-cell {
    padding: 8px;
    width: 100%;
  }

  & .filter-form-cell.bg-cell {
    background-color: #EDEDED;
  }

  & .filter-form-group {
    width: 100%;
  }

  & .filter-form-group-label {
    color: ${theme['@primary-color']};
    font-size: 16px;
    font-weight: 500;
  }

  & .form-text-input {
    width: 100%;
  }

  & .search-icon-wrapper {
    cursor: pointer;
    font-size: 18px;
    padding: 3px 8px 6px 8px;
  }

  & .search-icon-wrapper.active {
    color: ${theme['@primary-color']};
  }

  & .search-input-wrapper {
    //margin-left: 4px;
    overflow: hidden;
    padding: 0;
    -webkit-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    transform: scale(0.75);
    transform-origin: left;
    width: 46px;
  }

  & .search-input-wrapper .ant-input {
    font-size: 18px;
    height: 34px;
  }

  & .search-input-wrapper .ant-input-group-addon:first-child {
    border: 1px solid #D9D9D9 !important;
    border-radius: 21px;
    font-size: 22px;
    padding: 0 10px;
    padding-top: 4px;
  }

  & .search-input-wrapper .ant-input-affix-wrapper {
    border: 1px solid transparent;
    border-left: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 23px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 23px;
  }

  & .search-input-wrapper .ant-input-clear-icon {
    font-size: 26px;
  }

  & .search-input-wrapper .search-icon-box {
    cursor: pointer;
  }

  & .search-input-wrapper.active {
    width: 390px;
  }

  & .search-input-wrapper.active .ant-input-affix-wrapper {
    border: 1px solid #D9D9D9;
  }

  & .search-input-wrapper.active .ant-input-group-addon:first-child {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: ${theme['@primary-color']};
  }

  & .searchbox-wrapper {
    padding: 0px 2px;
    width: 300px;
  }

  & .searchbox-wrapper .ant-input-affix-wrapper {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  & .textarea-wrapper {
    padding: 8px;
  }

  & .textarea-wrapper .ant-input-affix-wrapper {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  & .textarea-wrapper .ant-input-affix-wrapper-textarea-with-clear-btn {
    //width: calc(100% - 32px);
  }

  & .textarea-wrapper .ant-input-affix-wrapper-textarea-with-clear-btn > textarea {
    //border-top-right-radius: 0;
  }

  & .textarea-wrapper .ant-input-clear-icon {
    font-size: 18px;
  }

  & .textarea-wrapper .ant-input-group-addon {
    display: none;
  }

  & .textarea-wrapper .filter-search-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 18px;
    padding: 2px 6px;
  }
`;

const SearchFilterPanel = (props: Props) => {
  const FORM_SECTION_CLS = 'filter-form-cell';
  const FORM_GROUP_CLS = 'filter-form-group';
  const { useState } = React;
  const [contentResourceAtts, setContentResourceAttrs] = useState<StringKAnyVPair[]>([]);
  //const [contentResourceFilter, setContentResourceFilter] = useState<StringKAnyVPair[]>([]);
  const [contentResourceFilterVersion, setContentResourceFilterVersion] = useState(0);
  const [displaySearcher, setDisplaySearcher] = useState(true);
  const [inited, setInited] = useState(false);
  const [searchInputVersion, setSearchInputVersion] = useState(0);
  const [searchTimer, setSearchTimer] = useState<number>();
  const attrSearchInputRef = React.useRef<any>(null);
  const attrCtnRef = React.useRef<any>(null);

  const clearContentResourceFilters = () => {
    props.setContentResourceFilter([]);
    setContentResourceFilterVersion(contentResourceFilterVersion + 1);
  };

  const clearFilter = () => {
    props.clearChannelFlags();
    props.clearLabels();
    props.clearSearchBrand();
    props.clearSearchTitle();
    props.setMultiCode("");
    props.setStatus([]);

    clearContentResourceFilters();

    if (typeof props.clearExtraFilter === "function") props.clearExtraFilter();
  };

  const contentResourceField = (attr: StringKAnyVPair) => {
    return (
      <Input
        allowClear
        key={`${attr.copywritingElementNum}-${contentResourceFilterVersion}`}
        onChange={evt => onContentResourceTextValueChange(evt, attr)}
      />
    );
  };

  const extraToolbarButtons = () => {
    return (
      <Row className="bottom-toolbar" align="middle" justify="space-between">
        <Space>
          <Button
            type="text"
            onClick={() => props.hidePanel()}
            style={{ fontSize: 16, padding: 0}}
          >
            <CloseOutlined />
          </Button>
          <Label className="label-bold">Filter</Label>
        </Space>
        <Space>
          <Button onClick={clearFilter}>
            <ClearOutlined />
            Clear
          </Button>
          <WarningButton onClick={handleSearch} type="primary">
            <SearchOutlined />
            Apply
          </WarningButton>
        </Space>
      </Row>
    );
  };

  // eslint-disable-next-line
  const fetchContentResourceFilters = async () => {
    try {
      const crs = await getContentResourceList();

      if (crs.length > 0) {
        const dict: StringKAnyVPair = {};
        const filters: StringKAnyVPair[] = [];
        //console.log('res', crs);
        crs.forEach((e: StringKAnyVPair) => {
          if (!dict[e.group]) dict[e.group] = {groupId: e.contentSourceGroup, groupName: e.group, children: []};

          dict[e.group].children.push(e);
        });
        for (let g in dict) filters.push(dict[g]);
        //console.log('dict', dict, filters);
        setContentResourceAttrs(filters);
      }
    } catch(e) {
    } finally {
    }
  };

  const filterAddonBefore = () => {
    return (
      <span
        className="search-icon-box"
        onClick={toggleSearcher}
      >
        <FilterOutlined />
      </span>
    );
  };

  const filterContainerClass = () => {
    const cls = ['filter-body'];

    if (isCodeListType(props.searchCodeType)) {
      cls.push('filter-ctn2');
    } else {
      cls.push('filter-ctn1');
    }

    return cls.join(' ');
  };

  const handleSearch = () => {
    setTimeout(() => {
      props.handleSearch();
    }, 500);
    props.hidePanel();
  };

  const onContentResourceTextValueChange = (
    evt: any,
    attr: StringKAnyVPair,
  ) => {
    const { contentResourceFilter } = props;
    const val = evt.target.value.trim();
    const fs = contentResourceFilter.filter(e => e.FilterNum === attr.copywritingElementNum);
    const filter = fs.length === 1 ? fs[0] : {};

    if (val) {
      filter.FilterName = attr.elementName;
      filter.FilterNum = attr.copywritingElementNum;
      filter.FilterOps = 2;
      filter.Type = 1;
      filter.FilterValue = val;

      if (fs.length < 1) {
        contentResourceFilter.push(filter);
      }
    } else {
      if (fs.length > 0) {
        for (let i = contentResourceFilter.length - 1; i >= 0; i--) {
          if (contentResourceFilter[i].FilterNum === attr.copywritingElementNum) contentResourceFilter.splice(i, 1);
        }
      }
    }

    //console.log(evt.target.value, filter, attr);
    //console.log('cf: ', contentResourceFilter);
    props.setContentResourceFilter([...contentResourceFilter]);
  };

  const onGroupModeChange = (evt: any) => {
    props.setSearchGroup(evt.target.value);
  };

  // eslint-disable-next-line
  const onPanelMouseDown = () => {
    props.showPanel();
  };

  // eslint-disable-next-line
  const onSearchBrandChange = (brands: string[]) => {
    //console.log('v', brands);
    props.setBrand(brands.join(','));
  };

  // eslint-disable-next-line
  const onSearchBrandChange2 = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchBrand(value);
      props.setBrand(value);
    }, 0);
  };

  const onSearchCodeChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    //setTimeout(() => setSearchCode(value), 0);
    props.setSearchCode(value);
  };

  const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;
    const str = value.replace(/\t/g, "\n");

    //setSearchMultiCode(str);
    props.setMultiCode(str);
  };

  const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (props.searchMultiCode.trim()) {
        props.setMultiCode(props.searchMultiCode.trim() + "\n");
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };

  const onSearchStatusChange = (st: number[]) => {
    //setSearchStatus(st);
    props.setStatus(st);
  };

  const onSearchTitleChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => {
      //setSearchTitle(value);
      props.setTitle(value);
    }, 0);
  };

  const onSearchAttributes = () => {
    let sk = attrSearchInputRef.current?.input.value;

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(0);
    }

    if (typeof sk === 'string' && attrCtnRef.current) {
      const mgd: StringKAnyVPair = {};
      const grps = attrCtnRef.current.querySelectorAll(`.${FORM_GROUP_CLS}`);
      const sections = attrCtnRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      sk = sk.toLowerCase().trim();

      if (sk) {
        setFilterHeight();
      }

      for (let i = 0; i < sections.length; i++) {
        const labelDom = sections[i].querySelector('span');

        if (labelDom) {
          const label = labelDom.textContent.trim();

          if (label && typeof label === 'string') {
            const match = label.toLowerCase().indexOf(sk.toLowerCase()) > -1;

            sections[i].style.display = match ? '' : 'none';

            if (match) {
              // record group info
              const grp = sections[i].parentNode.getAttribute('data-grp');
              if (typeof grp === 'string' && grp.trim()) {
                const gid = Number(grp);

                //if (!isNaN(gid)) console.log(gid, sections[i].parentNode);
                if (!isNaN(gid)) mgd[gid] = true;
              }
            }
          }
        }
      }

      //console.log('mgd', mgd);
      for (let i = 0; i < grps.length; i++) {
        const grp = grps[i].getAttribute('data-grp');

        if (typeof grp === 'string' && grp.trim()) {
          const gid = Number(grp);

          grps[i].style.display = mgd[gid] ? '' : 'none';
          //console.log('grp', grps[i]);
        }
      }
    }
  };

  const onSearchboxKeyUp = () => {
    if (searchTimer) clearTimeout(searchTimer);

    const timer = setTimeout(onSearchAttributes, 1000);
    setSearchTimer(timer);
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //setSearchCCFs(values);
    props.setSalesChannel(values);
  };

  const onSelectLabels = (values: any[]) => {
    //setSearchLabels(values);
    props.setLabels(values);
  };

  const onSelectSortType = (value: any) => {
    //setSearchSorter(value);
    props.setSortType(value);
  };

  const renderContentResourceFilter = (gs: StringKAnyVPair[]) => {
    return gs.map((g, i) => {
      return (<div className="filter-form-group" data-grp={g.groupId} key={`${g.groupName}${i}`}>
        <div className="filter-form-cell filter-form-group-label">{g.groupName}</div>
        <Divider className="bottom-divider" />
        {g.children.map((f: StringKAnyVPair) => (
          <div className="filter-form-cell" key={`crf-${f.copywritingElementNum}`}>
            <FormLabel>{f.elementName}</FormLabel>
            {contentResourceField(f)}
          </div>
        ))}
      </div>);
    });
  };

  const searchFieldInputWrapperClass = () => {
    const cls = ['search-input-wrapper'];

    if (displaySearcher) {
      cls.push('active');
    }

    return cls.join(' ');
  };

  // eslint-disable-next-line
  const searchIconWrapperClass = () => {
    const cls = ['search-icon-wrapper'];

    if (displaySearcher) {
      cls.push('active');
    }

    return cls.join(' ');
  };

  const setFilterHeight = () => {
    if (attrCtnRef.current) {
      const body = attrCtnRef.current.querySelector('.filter-body');

      if (body) {
        body.style.height = `${body.offsetHeight}px`;
      }
    }
  };

  const showAllFields = () => {
    if (attrCtnRef.current) {
      const sections = attrCtnRef.current.querySelectorAll(`.${FORM_SECTION_CLS}`);

      for (let i = 0; i < sections.length; i++) {
        sections[i].style.display = '';
      }
    }
  };

  const sorterClass = (sorter: any) => {
    const ret = [ 'filter-form-cell' ];

    if (sorter !== null) {
      ret.push('bg-cell');
    }

    return ret.join(' ');
  };

  const toggleSearcher = () => {
    if (displaySearcher) {
      showAllFields();
      setSearchInputVersion(searchInputVersion + 1);
    } else {
      setTimeout(() => {
        if (attrSearchInputRef.current) {
          //console.log(attrSearchInputRef.current);
          attrSearchInputRef.current.input.focus();
        }
      }, 600);
    }

    setDisplaySearcher(!displaySearcher);
  };

  React.useEffect(() => {
    if (!inited) {
      fetchContentResourceFilters();
      setInited(true);
    }
  }, [fetchContentResourceFilters, inited]);

  return (
    <>
      <Drawer
        closable={false}
        onClose={() => props.hidePanel()}
        //mask={false}
        //onMouseDown={onPanelMouseDown}
        //onMouseEnter={props.onMouseEnter as any}
        //onMouseLeave={props.onMouseLeave as any}
        //ref={attrCtnRef}
        bodyStyle={{padding: 0}}
        title={extraToolbarButtons()}
        visible={props.visible}
        width={432}
      >
        <PanelWrapper>
          <Col className="filter-body">
            <Row align="middle" className="filter-form-cell">
              <FormLabel>Display</FormLabel>
    &nbsp;&nbsp;
              <Radio.Group
                onChange={onGroupModeChange}
                //style={{ width: 430 }}
                value={props.searchGroup}
              >
                <Radio
                  // disabled={!isStyleCode(searchCodeType)}
                  value={GROUP_OPTION_STYLE}
                >
                  Style
                </Radio>
                {props.productListStyle !== "1" && (
                  <Radio
                    // disabled={!isCommonGroupOption(searchCodeType)}
                    value={GROUP_OPTION_COLOR}
                  >
                    Substyle
                  </Radio>
                )}
                <Radio
                // disabled={!isCommonGroupOption(searchCodeType)}
                value={GROUP_OPTION_PRODUCT}
                >
                  Product
                </Radio>
                <Radio
                  // disabled={!isCommonGroupOption(searchCodeType)}
                  value={GROUP_OPTION_NO_GROUP}
                >
                  All
                </Radio>
              </Radio.Group>
            </Row>
            <Row align="middle" className="filter-form-cell">
              {props.searchCodeTypeSelector()}
            </Row>
            {!isCodeListType(props.searchCodeType) && (
              <div className="textarea-wrapper">
                <Input.Search
                  allowClear
                  enterButton={null}
                  onChange={onSearchCodeChange}
                  //onKeyDown={onSearchCodeListKeyDown}
                  onSearch={handleSearch}
                  placeholder="Input search content here"
                  value={props.searchCode}
                />
              </div>
            )}
            {isCodeListType(props.searchCodeType) && (
              <Row className="textarea-wrapper">
                <Input.TextArea
                  allowClear
                  onChange={onSearchCodeListChange}
                  onKeyDown={onSearchCodeListKeyDown}
                  placeholder="Input search content here"
                  value={props.searchMultiCode}
                />
                {/*<Button
                  className="filter-search-btn"
                  onClick={handleSearch}
                >
                  <SearchOutlined />
                </Button>*/}
              </Row>
            )}
            <Row className="filter-form-cell" align="middle">
              <span className={searchFieldInputWrapperClass()}>
                <Input.Search
                  enterButton={null}
                  addonBefore={filterAddonBefore()}
                  allowClear
                  key={`field-filter-input${searchInputVersion}`}
                  onKeyUp={onSearchboxKeyUp}
                  onSearch={onSearchAttributes}
                  ref={attrSearchInputRef}
                />
              </span>
            </Row>
          </Col>
          <Divider className="bottom-divider" />
          <Col className={filterContainerClass()} ref={attrCtnRef}>
            <Row justify="start">
              {/*<div className="filter-form-cell filter-form-group-label">ID</div>
              <Divider className="bottom-divider" />*/}
              <div className="filter-form-cell">
                <FormLabel>Product Name</FormLabel>
                <Input
                  allowClear
                  className="form-text-input"
                  key={`title-${props.searchTitleVersion}`}
                  onChange={onSearchTitleChange}
                  //value={searchTitle}
                />
              </div>
              <div className="filter-form-cell">
                <FormLabel>Exclude Status</FormLabel>
                <Select
                  mode="multiple"
                  onChange={onSearchStatusChange}
                  options={props.statusOptions as any[]}
                  value={props.status}
                />
              </div>
              <div className="filter-form-cell">
                <FormLabel>Sales Channel</FormLabel>
                <ChannelControlFlagSelector
                  channelFlagVersion={props.channelFlagVersion}
                  onChange={onSelectChannelControlFlags}
                />
              </div>
              {props.excludeSalesChannel}
              {/*<div className="filter-form-cell filter-form-group-label">Style</div>
              <Divider className="bottom-divider" />*/}
              <div className="filter-form-cell">
                <FormLabel>Brand</FormLabel>
                {/*<Input
                  allowClear
                  className="form-text-input"
                  key={`brand-${props.searchBrandVersion}`}
                  onChange={onSearchBrandChange2}
                />*/}
                <BrandSelector
                  key={`brand-${props.searchBrandVersion}`}
                  version={props.searchBrandVersion}
                  onBrandChange={onSearchBrandChange}
                />
              </div>
              <div className="filter-form-cell">
                <FormLabel>Tags</FormLabel>
                <LabelsSelector
                  labelVersion={props.labelVersion}
                  onChange={onSelectLabels}
                />
              </div>
              {/*<div className="filter-form-cell">
                <FormLabel>Tag</FormLabel>
                <Input
                    key={`Tag-${props.searchTagVersion}`}
                    placeholder="Please input and separate with commas"
                    style={{ width: "100%" }}
                    onChange={(e) => props.onTagChange(e.target.value || "")}
                />
              </div>*/}
              {(props.extraFilter || []).map((item, index) => (
                <div className="filter-form-cell" key={`extra-item-${index}`}>
                  {item}
                </div>
              ))}
              {renderContentResourceFilter(contentResourceAtts)}
              {/*<div className="filter-form-cell">
                <FormLabel>Display By</FormLabel>
                <Select
                  key={`Group-${props.searchGroupsVersion}`}
                  className="status-selector"
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  style={{ width: "100%" }}
                  onChange={(value, option) => props.onGroupChange(value, option)}
                  options={props.groupList.map((group) => ({
                    label: group.enumName,
                    value: group.enumValue,
                  }))}
                />
              </div>*/}
              {(props.extraSorter || []).map((item, index) => (
                <div className={sorterClass(item)} key={`extra-sorter-${index}`}>
                  {item === null ? <span>&nbsp;</span> : item}
                </div>
              ))}
              <div className="filter-form-cell bg-cell">
                <FormLabel>Sort By</FormLabel>
                <SortSelector onChange={onSelectSortType} />
              </div>
            </Row>
          </Col>
        </PanelWrapper>
      </Drawer>
      {/*<MaskOverlay
        height={`calc(100vh - ${props.y}px)`}
        onClick={props.hidePanel}
        x={0}
        y={props.y}
        visible={props.visible}
      />*/}
    </>
  );
};

export default SearchFilterPanel;

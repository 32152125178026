import React from 'react';
import {
  Button,
  Image,
  Row,
} from 'antd';
import {
  PrinterOutlined,
  SettingOutlined,
} from '@ant-design/icons';
//import printJS from 'print-js';
import styled from 'styled-components';

import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import ModalDialog from '../../components/common/ModalDialog';
import printJS from '../../components/print-js';
import { cm2px, toFixed } from '../../util';
import PrintSettingDialog from './PrintSettingDialog';
import {
  //GROUP_OPTION_COLOR,
  GROUP_OPTION_STYLE,
  GROUP_OPTION_PRODUCT,
} from '.';

type Props = {
  columns: StringKAnyVPair[];
  dataType: number;
  dataSource: StringKAnyVPair[];
  onClose: Function;
  visible: boolean;
};

type PrintAreaContainerProps = {
  readonly $column: number; // custom property must start with a dollar sign($)
};

const commonFontSize = (col: number) => {
  let ret = 8;

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
      ret = 12;
      break;

    case 4:
    case 6:
    case 8:
      break;

    case 12:
      ret = 6;
      break;

    case 16:
      ret = 4;
      break;
  }

  return ret;
};

const commonFontWrapSpace = (col: number) => {
  let ret = 'nowrap';

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
    case 3:
      ret = 'normal';
      break;
  }

  return ret;
};

const commonLineHeight = (col: number) => {
  let ret = 1;

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
    case 3:
      ret = 1.3;
      break;
  }

  return ret;
};

const imageAreaHeight = (col: number) => {
  let ret = 180;

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
      ret = 316;
      break;

    case 3:
      ret = 240;
      break;

    case 4:
      ret = 220;
      break;

    case 6:
      ret = 210;
      break;

    case 8:
      //ret = 180;
      ret = 190;
      break;

    case 12:
      ret = 160;
      break;
  }

  return ret;
};

const imageElementWidth = (col: number) => {
  let ret = '100%';

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
    case 3:
      ret = '50%';
      break;
  }

  return ret;
};

const page4ColsFirstRowMarginTopByField = () => {
  let ret = 0.6;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 2.2;
      break;

    case 1:
      ret = 2;
      break;

    case 2:
      ret = 1.8;
      break;

    case 3:
      ret = 1.6;
      break;

    case 4:
      ret = 1.4;
      break;

    case 5:
      ret = 1.2;
      break;

    case 6:
      ret = 0.9;
      break;

    case 7:
      ret = 0.65;
      break;

    default:
      break;
  }

  return ret;
};

const page4ColsRowMarginTopByField = () => {
  let ret = 6;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 18;
      break;

    case 1:
      ret = 16;
      break;

    case 2:
      ret = 12;
      break;

    case 3:
      ret = 8;
      break;

    case 4:
      ret = 6;
      break;

    case 5:
      ret = 4;
      break;

    case 6:
      ret = 2;
      break;

    case 7:
      ret = 0;
      break;

    default:
      break;
  }

  return ret;
};

const page6ColsFirstRowMarginTopByField = () => {
  let ret = 0.8;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 2.4;
      break;

    case 1:
      ret = 2.2;
      break;

    case 2:
      ret = 2;
      break;

    case 3:
      ret = 1.8;
      break;

    case 4:
      ret = 1.6;
      break;

    case 5:
      ret = 1.4;
      break;

    case 6:
      ret = 1.1;
      break;

    case 7:
      ret = 1;
      break;

    default:
      break;
  }

  return ret;
};

const page6ColsRowMarginTopByField = () => {
  let ret = 4;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 20;
      break;

    case 1:
      ret = 18;
      break;

    case 2:
      ret = 14;
      break;

    case 3:
      ret = 11;
      break;

    case 4:
      ret = 8;
      break;

    case 5:
      ret = 6;
      break;

    case 6:
      ret = 4;
      break;

    case 7:
      ret = 0;
      break;

    default:
      break;
  }

  return ret;
};

const page8ColsFirstRowMarginTopByField = () => {
  let ret = 1.5;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 2.7;
      break;

    case 1:
      ret = 2.6;
      break;

    case 2:
      ret = 2.4;
      break;

    case 3:
      ret = 2.1;
      break;

    case 4:
      ret = 2.0;
      break;

    case 5:
      ret = 1.8;
      break;

    case 6:
      ret = 1.5;
      break;

    case 7:
      ret = 1.3;
      break;

    default:
      break;
  }

  return ret;
};

const page8ColsRowMarginTopByField = () => {
  let ret = 6;
  let fieldNum = 8;

  try {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);

    if (item) {
      // console.log('item', item);
      const fs = JSON.parse(item);

      if (Array.isArray(fs)) {
        fieldNum = fs.length;
      }
    }
  } catch(e) {
  } finally {
  }

  switch(fieldNum) {
    case 0:
      ret = 22;
      break;

    case 1:
      ret = 20;
      break;

    case 2:
      ret = 18;
      break;

    case 3:
      ret = 16;
      break;

    case 4:
      ret = 12;
      break;

    case 5:
      ret = 10;
      break;

    case 6:
      ret = 8;
      break;

    case 7:
      ret = 4;
      break;

    default:
      break;
  }

  return ret;
};

const pageFirstRowMarginTop = (col: number) => {
  let ret = 1.5;

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
      ret = 0.8;
      break;

    case 3:
      ret = 2.0;
      break;

    case 4:
      //ret = 0.6;
      ret = page4ColsFirstRowMarginTopByField();
      break;

    case 6:
      //ret = 0.8;
      ret = page6ColsFirstRowMarginTopByField();
      break;

    case 8:
      ret = page8ColsFirstRowMarginTopByField();
      break;
  }

  return ret;
};

const pageRowMarginTop = (col: number) => {
  let ret = 6;

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
      ret = 4;
      break;

    case 3:
      ret = 18;
      break;

    case 4:
      //ret = 2;
      ret = page4ColsRowMarginTopByField();
      break;

    case 6:
      //ret = 4;
      ret = page6ColsRowMarginTopByField();
      break;

    case 8:
      ret = page8ColsRowMarginTopByField();
      break;
  }

  return ret;
};

const paperLineHeight = (col: number) => {
  let ret = '1.2em';

  //console.log('col', col, typeof col);
  switch(col) {
    case 2:
    case 3:
      ret = 'auto';
      break;
  }

  return ret;
};


const rowPerPage = (col: number) => {
  let ret = 2;

  //console.log('col', col, typeof col);
  switch(col) {
    default:
      break;
  }

  return ret;
};

const DEFAULT_COLUMN_PER_PAGE = 4;
const PrintAreaContainer = styled.div<PrintAreaContainerProps>`
  height: calc(100vh - 180px);
  overflow-y: auto;

  footer, header {
    //display: none;

    hr {
      margin: 0;
    }
  }

  & .bold-label {
    font-weight: 600;
  }

  .disperse-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  & .ele-block {
    //border: 1px solid #D9D9D9;
  }

  & .ele-block.row {
    //align-items: center;
    display: flex;
    flex-flow: row wrap;
  }

  & .ele-block .ant-image {
    //width: 100%;
    width: ${props => imageElementWidth(props.$column)};
  }

  & .ele-block .ant-image-error {
    background-color: #DCDCDC;
  }

  & .ele-block .ant-image-img {
    height: ${props => imageAreaHeight(props.$column)}px;
    object-fit: contain;
    width: 100%;
  }

  & .ele-block.row .text-area {
    padding-left: 4px;
    width: 50%;
  }

  & .ele-row {
    display: flex;
    flex-flow: row wrap;
  }

  & .page-cover-ctn {
    //border: 1px solid #2111A6;
    height: 16.59cm !important;
    margin-bottom: 0.5cm;

    .ant-image {
      height: 100%;
      width: 100%;
    }

    .ant-image-img {
      //border-radius: 20px;
      height: 100%;
      object-fit: contain;
      //object-fit: cover;
    }
  }

  & .text-row {
    //font-family: Arial, Verdana, Tahoma;
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    //font-size: 8pt;
    font-size: ${props => commonFontSize(props.$column)}pt;
    line-height: 1.3em;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${props => commonFontWrapSpace(props.$column)};
  }
`;

// letter paper size is: 21.59 x 27.94

const defaultFieldsValue = ['styleCode', 'season', 'color', 'size', 'msrp', 'wholesale'];

const PrintRowsDialog = (props: Props) => {
  const { useState } = React;
  const [columnPerPage, setColumnPerPage] = useState(DEFAULT_COLUMN_PER_PAGE);
  const [coverImageSrc, setCoverImageSrc] = useState('');
  const [footerText, setFooterText] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [inited, setInited] = useState(false);
  const [printFields, setPrintFields] = useState<string[]>([]);
  const [printSettingDialogVisible, setPrintSettingDialogVisible] = useState(false);
  const ctnRef = React.useRef<any>(null);

  const blockWrapperClassName = () => {
    const cls = ['ele-block'];

    if ([2, 3].indexOf(columnPerPage) > -1) {
      cls.push('row');
    }

    return cls.join(' ');
  };

  const closePrintSettingDialog = () => {
    setPrintSettingDialogVisible(false);
  };

  const dialogFooter = () => {
    return (
      <Row justify="end">
        <Button
          onClick={printRows}
          type="primary"
        >
          <PrinterOutlined />
          Print
        </Button>
      </Row>
    );
  };

  const isFieldPrintable = (field: string) => {
    return printFields.indexOf(field) > -1;
  };

  const onCancelDialog = () => {
    props.onClose();
  };

  const openPrintSettingDialog = () => {
    setPrintSettingDialogVisible(true);
  };

  const pageFooter = (opt: StringKAnyVPair) => {
    return (<>
      <footer data-type="raw">
        <hr />
        <div className="disperse-row">
          <span>Page: {opt.current} of {opt.total}</span>
          {footerText && <span>{footerText}</span>}
          <span data-type="raw">POWERED BY DigitBridge</span>
        </div>
      </footer>
    </>);
  };

  const pageHeader = () => {
    return (<>
      <header data-type="raw">
        <div data-type="raw">
          {headerText && <span>{headerText}</span>}
          <hr />
        </div>
      </header>
    </>);
  };

  const pageRowClass = (idx: number, n: number) => {
    const cls = ['ele-row'];

    if (idx % n === 0) {
      cls.push('page-first-row');
    }

    return cls.join(' ');
  };

  const printStyle = () => {
    return `
      @font-face {
        font-family: Lato;
        src: url('/fonts/Lato/Lato-Regular.ttf') format("truetype");
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
      }

      @font-face {
        font-family: Lato;
        src: url('/fonts/Lato/Lato-Bold.ttf') format("truetype");
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
      }

      @font-face {
        font-family: Lato;
        src: url('/fonts/Lato/Lato-Black.ttf') format("truetype");
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
      }

      @media print {
        footer, header {
          color: #D1D1D1;
          display: block !important;
          //font-family: Arial, Verdana, Tahoma;
          font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-size: 8pt !important;
          left: 0;
          right: 0;
          width: 100vw;

          hr {
            border: none;
            //border-bottom: 1px solid #D9D9D9;
            height: 0;
            margin: 0;
          }
        }

        body, html {
          margin: 0;
          padding: 0;
          //height: 100vh !important;
        }

        footer, header {
          div {
            width: 100%;
          }
        }

        footer {
          //position: absolute;
          //top: 26.94cm !important;

          hr {
            margin-bottom: 2pt !important;
          }
        }

        header {
          align-items: end !important;
          display: flex !important;
          margin: 0;
          width: 100%;

          hr {
            margin-top: 2pt !important;
          }
        }

        @page {
          //font-family: Arial, Verdana, Tahoma;
          font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          margin-bottom: 0;
          margin-left: 1cm;
          margin-right: 1cm;
          margin-top: 0;
          size: letter landscape;
          //size: 279.4mm 215.9mm;
        }

        & .bold-label {
          //font-family: Arial, Verdana, Tahoma;
          font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          font-weight: 600;
        }

        .disperse-row {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin: 0;
          padding: 0;
        }

        & .ele-block {
          //border: 1px solid red;
        }

        & .ele-block .ant-image {
          width: 100%;
        }

        & .ele-block .ant-image-error {
          background-color: #ECECEC;
        }

        & .ele-block .ant-image-img {
          //height: 200px;
          object-fit: contain;
        }

        & .ele-block.row .text-area {
          padding-left: 4px;
          width: 50%;
        }

        & .ele-row {
          break-inside: avoid;
          margin-top: ${pageRowMarginTop(columnPerPage)}mm;
        }

        & .page-body {
          border: 1px solid #D9D9D9;
          border-left: none;
          border-right: none;
          height: 18.99cm !important; // 21.59 - 1.3 * 2
        }

        & .page-body-ctn {
          //border: 1px solid #D9D9D9;
          //break-before: page;
          //break-after: avoid;
          height: 21.59cm !important;
          margin: 0 !important;
        }

        & .page-cover-ctn {
          //border: 1px solid #2111A6;
          height: 18.99cm !important;
          //padding: 6px;

          .ant-image {
            height: calc(100% - 2cm) !important;
            padding-bottom: 1cm !important;
            padding-top: 1cm !important;
            width: 100%;
          }

          .ant-image-img {
            height: 100% !important;
            object-fit: contain;
            //object-fit: cover;
          }
        }

        & .page-first-row {
          margin-top: ${pageFirstRowMarginTop(columnPerPage)}cm;
        }

        & .page-header {
          //border: 1px solid #81D1D1;
          height: 1.3cm !important;
          display: flex !important;
          align-items: end !important;
        }

        & .text-row {
          //font-family: Arial, Verdana, Tahoma;
          font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
          //font-size: 8pt !important;
          font-size: ${commonFontSize(columnPerPage)}pt !important;
          //height: 1.2em !important;
          height: ${paperLineHeight(columnPerPage)} !important;
          //line-height: 1em !important;
          line-height: ${commonLineHeight(columnPerPage)}em !important;
          margin: 0 !important;
          padding: 0 !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: ${commonFontWrapSpace(columnPerPage)};
        }
      }
    `;
  };

  const printRows = () => {
    if (ctnRef.current) {
      printJS({
        documentTitle: ' ',
        honorMarginPadding: false,
        maxWidth: 'auto',
        printable: ctnRef.current,
        type: 'html',
        //type: 'pdf',
        style: printStyle(),
      });
    }
  };

  // eslint-disable-next-line
  const setDefaultFieldsValue = () => {
    const item = window.localStorage.getItem(LS_PRINT_FIELDS);
    let ret = defaultFieldsValue;

    try {
      if (item) {
        // console.log('item', item);
        const fs = JSON.parse(item);

        if (Array.isArray(fs)) {
          ret = fs;
        }
      }
    } catch(e) {
    } finally {
      setPrintFields([...ret]);
    }

    return ret;
  };

  const showCover = (pageOpt: StringKAnyVPair) => {
    return (
      <div
        className="page-body-ctn"
      >
        <div className="page-header"/>
        <div className="page-body">
          <div className="page-cover-ctn" data-type="raw">
            <Image
              fallback={ImagePlaceholder}
              preview={false}
              src={coverImageSrc}
            />
          </div>
          {pageFooter({current: 1, total: pageOpt.pageCount})}
        </div>
      </div>
    );
  };

  const showData = (rows: StringKAnyVPair) => {
    const rd: StringKAnyVPair[][] = [];
    const pd: StringKAnyVPair[][][] = [];
    const opt: StringKAnyVPair = {};

    rows.forEach((e: StringKAnyVPair, i: number) => {
      const rn = Math.floor(i / columnPerPage);

      if (!rd[rn]) rd.push([]);

      rd[rn].push(e);
    });
    rd.forEach((r: StringKAnyVPair[], i: number) => {
      const rn = Math.floor(i / rowPerPage(columnPerPage)); // rows number per page

      if (!pd[rn]) pd.push([]);

      pd[rn].push(r);
    });
    opt.pageCount = coverImageSrc ? pd.length + 1 : pd.length;
    opt.pageOffset = coverImageSrc ? 2 : 1;

    //console.log('rr', pd);
    //return rows.map((e: StringKAnyVPair, i: number) => (
    return (<>
      {coverImageSrc && showCover(opt)}
      {showPageList(pd, opt)}
    </>);
  };

  const showPageList = (
    pageData: StringKAnyVPair[][][],
    pageOpt: StringKAnyVPair,
  ) => {
    const tileGap = columnPerPage > 8 ? 4 : 6;

    return pageData.map((prd: StringKAnyVPair[][], p: number) => (
      <div
        className="page-body-ctn"
        key={`prt-page${p}`}
      >
        <div className="page-header">
          {pageHeader()}
        </div>
        <div className="page-body">
        {prd.map((r: StringKAnyVPair[], i: number) => (
          <div
            className={pageRowClass(i, rowPerPage(columnPerPage))}
            key={`printable-row-${i}`}
          >
          {r.map((e: StringKAnyVPair, j: number) => (
            <div
              className={blockWrapperClassName()}
              key={`printable-entity-${i}${j}`}
              style={{
                margin: tileGap,
                width: `calc(${100 / columnPerPage}% - ${tileGap * 2}px)`,
              }}
            >
              <Image
                fallback={ImagePlaceholder}
                preview={false}
                src={e.mediaURL}
              />
              <div className="text-area">
                <div className="text-row">
                  <span className="bold-label">{getLabel(e, 'productName', props.dataType, props.columns)}</span>
                </div>
                {isFieldPrintable('styleCode') && (
                  <div className="text-row">
                    <span className="">Style #: {e.styleCode}</span>
                  </div>
                )}
                {isFieldPrintable('season') && (
                  <div className="text-row">
                    <span className="">Season: {getLabel(e, 'season', props.dataType, props.columns)}</span>
                  </div>
                )}
                {isFieldPrintable('color') && (
                  <div className="text-row">
                    <span className="">Color: {getLabel(e, 'color', props.dataType, props.columns)}</span>
                  </div>
                )}
                {isFieldPrintable('size') && (
                  <div className="text-row">
                    <span className="">Size: {getLabel(e, 'size', props.dataType, props.columns)}</span>
                  </div>
                )}
                {isFieldPrintable('msrp') && (
                  <div className="text-row">
                    <span className="">M.S.R.P: {toFixed(getLabel(e, 'msrp', props.dataType, props.columns))}</span>
                  </div>
                )}
                {isFieldPrintable('wholesale') && (
                  <div className="text-row">
                    <span className="">Wholesale: {toFixed(getLabel(e, 'wholesale', props.dataType, props.columns))}</span>
                  </div>
                )}
                {isFieldPrintable('ats') && (
                  <div className="text-row">
                    <span className="">ATS: {getLabel(e, 'atsQuantity', props.dataType, props.columns)}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
          </div>
        ))}
        </div>
        {pageFooter({current: p + pageOpt.pageOffset, total: pageOpt.pageCount})}
      </div>
    ));
  };

  React.useEffect(() => {
    if (!inited) {
      setDefaultFieldsValue();
      setInited(true);
    }
  }, [inited, setDefaultFieldsValue]);

  return (<>
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton
      footer={dialogFooter()}
      //fullscreen
      //okText="Save"
      onClose={onCancelDialog}
      //onFullscreen={onFullscreen}
      //onOk={onSaveImage}
      style={{ paddingBottom: 0 }}
      title={'Print'}
      titleRightCrumb={<>
        <Button onClick={openPrintSettingDialog}>
          <SettingOutlined />
          Setting
        </Button>
      </>}
      visible={props.visible}
      width={'auto'}
    >
      <PrintAreaContainer
        $column={columnPerPage}
        ref={ctnRef}
        style={{width: cm2px(27.94 - 1 * 2)}}
      >
        {showData(props.dataSource)}
      </PrintAreaContainer>
    </ModalDialog>
    {printSettingDialogVisible && (
      <PrintSettingDialog
        columnPerPage={columnPerPage}
        coverImageSrc={coverImageSrc}
        footerText={footerText}
        headerText={headerText}
        onClose={closePrintSettingDialog}
        printFields={printFields}
        setColumnPerPage={setColumnPerPage}
        setCoverImageSrc={setCoverImageSrc}
        setFooterText={setFooterText}
        setHeaderText={setHeaderText}
        setPrintFields={setPrintFields}
        visible={printSettingDialogVisible}
      />
    )}
  </>);
};

const getColNameByHeader = (columns: StringKAnyVPair[], header: string) => {
  let fs = columns.filter(col => col.header === header);

  if (fs[0]) return fs[0].name;
};

export const LS_PRINT_FIELDS = 'ls_prt_fs';

export const getLabel = (
  d: StringKAnyVPair,
  k: string,
  dataType: number,
  columns: StringKAnyVPair[],
) => {
  let key = '';

  switch(k) {
    case 'atsQuantity':
      key = k;
    break;

    case 'color':
      if (dataType === GROUP_OPTION_STYLE) {
        key = getColNameByHeader(columns, 'Line Sheet Color Selection');

        if (!d[key] || !key) key = getColNameByHeader(columns, 'Color Selection');
      } else {
        key = getColNameByHeader(columns, 'Color Code');
      }
    break;

    case 'msrp':
      key = getColNameByHeader(columns, 'MSRP');
    break;

    case 'productName':
      key = getColNameByHeader(columns, 'Line Sheet Product Name');

      if (!d[key] || !key) key = getColNameByHeader(columns, 'ERP Product Name');
      if (!d[key] || !key) key = getColNameByHeader(columns, 'Product Name');
      if (!d[key] || !key) key = 'SKU';
    break;

    case 'season':
      key = getColNameByHeader(columns, 'Line Sheet Season');

      if (!d[key] || !key) key = getColNameByHeader(columns, 'Season');
    break;

    case 'size':
      if (dataType === GROUP_OPTION_PRODUCT) {
        key = getColNameByHeader(columns, 'Size Code');
      } else {
        key = getColNameByHeader(columns, 'Line Sheet Size Range');

        if (!d[key] || !key) key = getColNameByHeader(columns, 'Size Range');
      }
    break;

    case 'wholesale':
      key = getColNameByHeader(columns, 'Line Sheet Price');

      if (!d[key] || !key) key = getColNameByHeader(columns, 'Wholesale Price');
    break;
  }

  return !d[key] && typeof d[key] !== 'number' ? <span>&nbsp;</span> : d[key];
};

export default PrintRowsDialog;

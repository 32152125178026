import React from 'react';
import { Checkbox, Image, Row, Space } from 'antd';
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from 'styled-components';

import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import theme from '../../assets/styles/theme';
import { toFixed } from '../../util';
import { getLabel } from '../Copywriting/PrintRowsDialog';

type Props = {
  columnNum?: number;
  columns: StringKAnyVPair[];
  data: StringKAnyVPair[];
  dataType: number;
  isInDrawer?: boolean;
  mouseMoveEvent?: StringKAnyVPair;
  onItemClick?: Function;
  onSelectChange?: Function;
  selectable?: boolean;
  selectedIdList?: string[];
  setData?: Function;
  sortDraggable?: boolean;
  sortDroppable?: boolean;
  width?: number;
};

type GridWrapperProps = {
  colNum?: number;
};

const gridColumnWidth = (colNum?: number) => {
  let ret = 20;

  if (typeof colNum === 'number' && colNum > 0) {
    ret = parseFloat((100 / colNum).toFixed(6));
  }

  return ret;
};

const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${props => gridColumnWidth(props.colNum)}%);
  transition: all .3s ease-in;

  & .ant-image {
    aspect-ratio: 183/275;
    background-color: #ECECEC;
    width: 100%;
  }

  & .ant-image-img {
    height: 100%;
    object-fit: contain;
  }

  & .bottom-toolbar {
    height: auto !important;
    padding: 2px 6px;
  }

  & .danger-btn {
    color: ${theme['@danger-color']};
  }

  & .danger-btn,
  & .toolbar-btn {
    border-radius: 4px;
  }

  & .danger-btn .anticon,
  & .toolbar-btn .anticon {
    margin: 0 2px;
  }

  & .danger-btn:hover,
  & .toolbar-btn:hover {
    background-color: #ECECEC;
    cursor: pointer;
  }

  & .product-drop-ctn,
  & .product-item-ctn {
    border: 1px solid #D9D9D9;
    margin: 2px;
  }

  & .product-item-ctn:hover {
    border-color: ${theme['@secondary-btn-bg']};
  }

  & .product-drop-ctn.blinking-border-bottom {
    border-bottom: 4px solid transparent;
    animation: blink-danger-bottom 1s linear infinite;
  }

  & .product-drop-ctn.blinking-border-left {
    border-left: 4px solid transparent;
    animation: blink-danger-left 1s linear infinite;
  }

  & .product-drop-ctn.blinking-border-right {
    border-right: 4px solid transparent;
    animation: blink-danger-right 1s linear infinite;
  }

  & .product-drop-ctn.blinking-border-top {
    border-top: 4px solid transparent;
    animation: blink-danger-top 1s linear infinite;
  }

  & .product-item-wrapper {
    border: 1px solid transparent;
    padding: 2px;
  }

  & .text-row {
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    //font-size: 8pt;
    line-height: 1.3em;
    margin: 0;
    overflow: hidden;
    padding: 0 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @keyframes blink-danger-bottom {
    0% {
      border-bottom-color: transparent;
    }
    50% {
      border-bottom-color: ${theme['@danger-color']};
    }
    100% {
      border-bottom-color: transparent;
    }
  }

  @keyframes blink-danger-left {
    0% {
      border-left-color: transparent;
    }
    50% {
      border-left-color: ${theme['@danger-color']};
    }
    100% {
      border-left-color: transparent;
    }
  }

  @keyframes blink-danger-right {
    0% {
      border-right-color: transparent;
    }
    50% {
      border-right-color: ${theme['@danger-color']};
    }
    100% {
      border-right-color: transparent;
    }
  }

  @keyframes blink-danger-top {
    0% {
      border-top-color: transparent;
    }
    50% {
      border-top-color: ${theme['@danger-color']};
    }
    100% {
      border-top-color: transparent;
    }
  }
`;

const ProductGridView = (props: Props) => {
  const BLINK_BORDER_BOTTOM = 'blinking-border-bottom';
  const BLINK_BORDER_LEFT = 'blinking-border-left';
  const BLINK_BORDER_RIGHT = 'blinking-border-right';
  const BLINK_BORDER_TOP = 'blinking-border-top';
  const DROP_CTN_CLS = 'product-drop-ctn';
  const ITEM_CLS = 'product-item-ctn';
  const SCROLL_TIME_INTERVAL = 1000;
  const WRAPPER_CLS = 'product-item-wrapper';
  const { useState } = React;
  const [dragItem, setDragItem] = useState<StringKAnyVPair>({});
  const [dropItem, setDropItem] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const wrapperRef = React.useRef<any>(null);

  const getItemWrapper = (ele: any) => {
    let dom = ele.parentNode;
    let ret = null;

    while (dom.tagName.toLowerCase() !== 'body') {
      if (isDomHasClass(dom, WRAPPER_CLS)) {
        ret = dom
        break;
      }

      dom = dom.parentNode;
    }

    return ret;
  };

  const gridColumnNumber = () => {
    let ret: any = props.columnNum;

    if (props.columnNum) {
      if (props.width) {
        ret = Math.floor(props.width / 100);
      }
    }

    return ret;
  };

  const gridItemContainerClass = (item: StringKAnyVPair) => {
    const cls = [ dragItem.item ? DROP_CTN_CLS : ITEM_CLS ];

    if (dropItem.item && dropItem.item.ProductId === item.ProductId) {
      const colNum = gridColumnNumber();

      if (colNum === 1) {
        if (dropItem.cursor.pageY < dropItem.vMidline) {
          cls.push(BLINK_BORDER_TOP);
        } else if (dropItem.cursor.pageY > dropItem.vMidline) {
          cls.push(BLINK_BORDER_BOTTOM);
        }
      } else if (colNum > 1) {
        if (dropItem.cursor.pageX < dropItem.hMidline) {
          cls.push(BLINK_BORDER_LEFT);
        } else if (dropItem.cursor.pageX > dropItem.hMidline) {
          cls.push(BLINK_BORDER_RIGHT);
        }
      }
    }

    return cls.join(' ');
  };

  const isDomHasClass = (dom: any, className: string) => {
    const cls = dom.getAttribute('class')
    let ret = false;

    if (typeof cls === 'string') {
      const fs = cls.split(' ').map(e => e.trim());

      ret = fs.indexOf(className) > -1;
    }

    return ret;
  };

  const isItemChecked = (item: StringKAnyVPair) => {
    let ret = false;

    if (props.selectedIdList) {
      ret = props.selectedIdList.indexOf(item.ProductId) > -1;
    }

    return ret;
  };

  const onClickItemPicture = (item: StringKAnyVPair) => {
    if (props.onItemClick) props.onItemClick(item);
  };

  const onDragSortItemEnd = (evt: any, item: StringKAnyVPair) => {
    if (dragItem.item) setDragItem({});
  };

  const onDragSortItemLeave = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
    }
  };

  const onDragSortItemOver = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
      if (dragItem.item && dragItem.item.ProductId !== item.ProductId) {
        const dom = getItemWrapper(evt.target);

        if (dom) {
          const cursor = {pageX: evt.pageX, pageY: evt.pageY};
          const rect = dom.getBoundingClientRect();
          const hMidline = rect.left + rect.width / 2;
          const vMidline = rect.top + rect.height / 2;
          const timestamp = (new Date()).getTime();

          if (dropItem.item && dropItem.item.ProductId === item.ProductId) {
            if (wrapperRef.current && timestamp - dropItem.timestamp > SCROLL_TIME_INTERVAL) {
              const box = wrapperRef.current.parentNode;
              const bRect = box.getBoundingClientRect();

              if (evt.pageY > bRect.top) {
                if (bRect.top + rect.height / 2 > evt.pageY) {
                  //console.log('drop over', item.ProductId, dropItem.timestamp, timestamp);
                  box.scrollBy({behavior: 'smooth', top: (0 - rect.height)});
                } else if (bRect.top + bRect.height - rect.height / 2 < evt.pageY) {
                  box.scrollBy({behavior: 'smooth', top: rect.height});
                }
              }

              setDropItem({...dropItem, cursor, timestamp});
            }
          } else {
            // clear previous drop item class
            setDropItem({ cursor, hMidline, item, vMidline, timestamp });
          }

          evt.preventDefault();
        }
      }
    }
  };

  const onDragSortItemStart = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDraggable) {
      const timestamp = (new Date()).getTime();
      const img = new window.Image();

      img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
      evt.dataTransfer.setDragImage(img, 0, 0);
      setDragItem({ item, timestamp });
      //console.log('drag start');
    }
  };

  const onDraggingSortItem = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
      if (dragItem.item && wrapperRef.current) {
        const box = wrapperRef.current.parentNode;
        const bRect = box.getBoundingClientRect();
        //const dom = getItemWrapper(evt.target);

        if (box.scrollHeight > 0 && evt.pageY < bRect.top) {
          //console.log('dragging', evt.target);
          box.scrollBy({behavior: 'smooth', top: -50});
        }
      }
    }
  }

  const onDropOnItem = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
      if (dropItem.item && dragItem.item) {
        const colNum = gridColumnNumber();
        let offset = 0;

        //console.log('drop --', colNum, evt.pageY, dropItem.vMidline);
        if (colNum > 1) {
          offset = dropItem.hMidline < evt.pageX ? 1 : 0;
        } else {
          offset = dropItem.vMidline < evt.pageY ? 1 : 0;
        }

        moveItem(dragItem.item, dropItem.item, offset);
        // maybe need a clearDropItem func.
        setDropItem({});
        evt.preventDefault();
      }
    }
  };

  const onSelectionChange = (item: StringKAnyVPair, checked: boolean) => {
    //console.log('on ch', item, checked);
    if (props.onSelectChange) props.onSelectChange(item, checked);
  };

  const moveItem = (
    src: StringKAnyVPair,
    target: StringKAnyVPair,
    offset: number,
  ) => {
    const data = [ ...props.data ];

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === src.ProductId) {
        data.splice(i, 1);
        break;
      }
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === target.ProductId) {
        const idx = i + offset;

        data.splice(idx, 0, src);
        break;
      }
    }

    if (props.setData) props.setData(data);
  };

  const renderItems = (items: StringKAnyVPair[]) => {
    const colNum = gridColumnNumber();
    //console.log(items, '<--');

    return items.map((item, i) => (
      <div
        className={WRAPPER_CLS}
        key={`p-${item.ProductId}-${i}`}
      >
        <div
          className={gridItemContainerClass(item)}
          onDragLeave={evt => onDragSortItemLeave(evt, item)}
          onDragOver={evt => onDragSortItemOver(evt, item)}
          onDrop={evt => onDropOnItem(evt, item)}
        >
          <div
            draggable={props.sortDraggable}
            onDrag={evt => onDraggingSortItem(evt, item)}
            onDragEnd={evt => onDragSortItemEnd(evt, item)}
            onDragStart={evt => onDragSortItemStart(evt, item)}
          >
            <Image
              fallback={ImagePlaceholder}
              onClick={() => onClickItemPicture(item)}
              preview={false}
              src={item.mediaURL}
              style={{ cursor: props.onItemClick ? 'pointer' : undefined }}
            />
            {renderLabel(item)}
          </div>
          {props.isInDrawer && <Row className="bottom-toolbar" justify="space-between">
            <Space>
              <span className="toolbar-btn" onClick={() => sortItem(item, -1)}>
                {colNum === 1 ? <ArrowUpOutlined /> : <ArrowLeftOutlined />}
              </span>
              <span className="toolbar-btn" onClick={() => sortItem(item, 1)}>
                {colNum === 1 ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
              </span>
            </Space>
            <span className="danger-btn" onClick={() => onSelectionChange(item, false)}>
              <DeleteOutlined />
            </span>
          </Row>}
          {props.selectable && <div className="bottom-toolbar">
            <Checkbox
              checked={isItemChecked(item)}
              onChange={evt => onSelectionChange(item, evt.target.checked)}
            />
          </div>}
        </div>
      </div>
    ));
  };

  const renderLabel = (e: StringKAnyVPair) => {
    return (
      <div>
        <div className="text-row">
          <span className="bold-label">{getLabel(e, 'productName', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Style #: {e.styleCode}</span>
        </div>
        {!props.isInDrawer && <>
        <div className="text-row">
          <span className="">Season: {getLabel(e, 'season', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Color: {getLabel(e, 'color', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Size: {getLabel(e, 'size', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">M.S.R.P: {toFixed(getLabel(e, 'msrp', props.dataType, props.columns))}</span>
        </div>
        <div className="text-row">
          <span className="">Wholesale: {toFixed(getLabel(e, 'wholesale', props.dataType, props.columns))}</span>
        </div>
        <div className="text-row">
          <span className="">ATS: {getLabel(e, 'atsQuantity', props.dataType, props.columns)}</span>
        </div>
        </>}
      </div>
    );
  };

  const sortItem = (item: StringKAnyVPair, step: number) => {
    const data = [ ...props.data ];
    let idx = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === item.ProductId) {
        idx = i;
        break;
      }
    }

    if (idx > -1 && idx + step > -1 && idx + step < data.length) {
      if (props.setData) {
        data.splice(idx, 1);
        data.splice(idx + step, 0, item);

        if (props.setData) props.setData(data);
      }
    }
  };

  // eslint-disable-next-line
  const tryScrollGridView = () => {
    if (dragItem) {
      //console.log('dr', dragItem);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }

    if (props.mouseMoveEvent) {
      tryScrollGridView();
    }
  }, [inited, props, tryScrollGridView]);

  return (
    <GridWrapper colNum={gridColumnNumber()} ref={wrapperRef}>
      {renderItems(props.data)}
    </GridWrapper>
  );
};

export default ProductGridView;
